"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileField = void 0;
var lodash_1 = require("lodash");
var FileField = /** @class */ (function () {
    function FileField(jsonBlob) {
        this.fieldType = 'FileField';
        this.jsonBlob = jsonBlob;
        this.files = jsonBlob;
    }
    FileField.prototype.validate = function () {
        return null;
    };
    FileField.prototype.isEqual = function (field) {
        return field instanceof FileField && this.jsonBlob === field.jsonBlob;
    };
    FileField.prototype.toFieldJson = function () {
        return this.jsonBlob;
    };
    Object.defineProperty(FileField.prototype, "isEmpty", {
        get: function () {
            return (0, lodash_1.isNil)(this.jsonBlob);
        },
        enumerable: false,
        configurable: true
    });
    return FileField;
}());
exports.FileField = FileField;
