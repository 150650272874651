"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.endEditingMessage = exports.startEditingMessage = exports.throttledUpdateIsTyping = exports.removeDraft = exports.removeAllAttachmentsFromDraft = exports.removeAttachmentFromDraft = exports.addAttachmentToDraft = exports.updateDraftEditing = exports.updateDraftUrgent = exports.updateDraftContent = exports.REMOVE_DRAFT = exports.REMOVE_ALL_ATTACHMENTS_FROM_DRAFT = exports.REMOVE_ATTACHMENT_FROM_DRAFT = exports.ADD_ATTACHMENT_TO_DRAFT = exports.UPDATE_EDITING_MESSAGE = exports.UPDATE_DRAFT_EDITING = exports.UPDATE_DRAFT_URGENT = exports.UPDATE_DRAFT_CONTENT = void 0;
var _ = __importStar(require("lodash"));
var config_1 = require("@shared/config");
var api_1 = require("@shared/api");
var client_1 = require("@shared/api/client");
var channelType_1 = require("../../util/channelType");
exports.UPDATE_DRAFT_CONTENT = 'CHAT:UPDATE_DRAFT_CONTENT';
exports.UPDATE_DRAFT_URGENT = 'CHAT:UPDATE_DRAFT_URGENT';
// TODO(fant): remove. Connected to old chat input
exports.UPDATE_DRAFT_EDITING = 'CHAT:UPDATE_DRAFT_EDITING';
exports.UPDATE_EDITING_MESSAGE = 'CHAT:UPDATE_EDITING_MESSAGE';
exports.ADD_ATTACHMENT_TO_DRAFT = 'CHAT:ADD_ATTACHMENT_TO_DRAFT';
exports.REMOVE_ATTACHMENT_FROM_DRAFT = 'CHAT:REMOVE_ATTACHMENT_FROM_DRAFT';
exports.REMOVE_ALL_ATTACHMENTS_FROM_DRAFT = 'CHAT:REMOVE_ALL_ATTACHMENTS_FROM_DRAFT';
exports.REMOVE_DRAFT = 'CHAT:REMOVE_DRAFT';
// TODO(fant): remove this after shared keyboard rewrite.
var updateDraftContent = function (content, channelSlug, isTyping) {
    if (isTyping === void 0) { isTyping = true; }
    return function (dispatch) {
        dispatch({ type: exports.UPDATE_DRAFT_CONTENT, value: { content: content, channelSlug: channelSlug } });
        if (!!content && isTyping) {
            // If we have content, we tell the server that we're typing
            (0, exports.throttledUpdateIsTyping)(channelSlug);
        }
        else {
            // Otherwise, we cancel the update throttle so that we can
            // send the API mutation asap if we start typing (so we don't
            // need to wait for the throttle rate limit)
            exports.throttledUpdateIsTyping.cancel();
        }
    };
};
exports.updateDraftContent = updateDraftContent;
var updateDraftUrgent = function (urgent, channelSlug) { return function (dispatch) {
    return dispatch({ type: exports.UPDATE_DRAFT_URGENT, value: { urgent: urgent, channelSlug: channelSlug } });
}; };
exports.updateDraftUrgent = updateDraftUrgent;
// TODO(fant): remove. Connected to old chat input
var updateDraftEditing = function (editing, channelSlug) { return function (dispatch) {
    return dispatch({ type: exports.UPDATE_DRAFT_EDITING, value: { channelSlug: channelSlug, editing: editing } });
}; };
exports.updateDraftEditing = updateDraftEditing;
var addAttachmentToDraft = function (attachment, channelSlug) { return function (dispatch) {
    return dispatch({ type: exports.ADD_ATTACHMENT_TO_DRAFT, value: { attachment: attachment, channelSlug: channelSlug } });
}; };
exports.addAttachmentToDraft = addAttachmentToDraft;
var removeAttachmentFromDraft = function (attachment, channelSlug) { return function (dispatch) {
    return dispatch({ type: exports.REMOVE_ATTACHMENT_FROM_DRAFT, value: { attachment: attachment, channelSlug: channelSlug } });
}; };
exports.removeAttachmentFromDraft = removeAttachmentFromDraft;
var removeAllAttachmentsFromDraft = function (channelSlug) { return function (dispatch) {
    return dispatch({ type: exports.REMOVE_ALL_ATTACHMENTS_FROM_DRAFT, value: channelSlug });
}; };
exports.removeAllAttachmentsFromDraft = removeAllAttachmentsFromDraft;
var removeDraft = function (channelSlug) { return function (dispatch) {
    dispatch({ type: exports.REMOVE_DRAFT, value: channelSlug });
    // Cancel is typing updates when we remove a draft (similar to content being emptied)
    exports.throttledUpdateIsTyping.cancel();
}; };
exports.removeDraft = removeDraft;
var updateIsTyping = function (channelSlug) { return __awaiter(void 0, void 0, void 0, function () {
    var isThread;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                isThread = channelType_1.MBChannelTypeUtil.isThread(channelSlug);
                if (!!isThread) return [3 /*break*/, 2];
                return [4 /*yield*/, client_1.apolloClient.mutate({
                        mutation: api_1.Mutations.chat.updateIsTyping,
                        variables: { channelSlug: channelSlug },
                    })];
            case 1:
                _a.sent();
                _a.label = 2;
            case 2: return [2 /*return*/];
        }
    });
}); };
exports.throttledUpdateIsTyping = _.throttle(updateIsTyping, config_1.Config.IS_TYPING_UPDATE_THROTTLE_DURATION);
var startEditingMessage = function (message, draftId) { return function (dispatch) {
    return dispatch({ type: exports.UPDATE_EDITING_MESSAGE, value: { draftId: draftId, message: message } });
}; };
exports.startEditingMessage = startEditingMessage;
var endEditingMessage = function (draftId) { return function (dispatch) {
    return dispatch({ type: exports.UPDATE_EDITING_MESSAGE, value: { draftId: draftId, message: undefined } });
}; };
exports.endEditingMessage = endEditingMessage;
