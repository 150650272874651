"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useReferencedInOptions = void 0;
var hooks_1 = require("@shared/scenes/workflows/hooks");
var hooks_2 = require("../../../hooks");
var useReferenceableWorkflows_1 = require("./useReferenceableWorkflows");
function useReferencedInOptions(workflowTemplateId, workspaceDetails, component) {
    var _a, _b, _c, _d;
    var _e = (0, hooks_2.useSetComponentField)(component, 'referencedFromWorkflowTemplateId'), selectedWorkflowId = _e[0], setSelectedWorkflow = _e[1];
    var _f = (0, hooks_2.useSetComponentField)(component, 'relatedCardComponentId'), selectedComponentId = _f[0], setSelectedComponent = _f[1];
    var _g = (0, hooks_2.useSetComponentField)(component, 'referencingViewTemplateId'), selectedViewTemplateId = _g[0], setSelectedViewTemplate = _g[1];
    var referenceableWorkflows = (0, useReferenceableWorkflows_1.useReferenceableWorkflows)(workflowTemplateId, workspaceDetails);
    var viewTemplates = (0, hooks_1.useAllViewTemplates)(selectedWorkflowId, 'COLLECTION', 'LIST');
    var selectedWorkflowTemplate = (_a = referenceableWorkflows.workflows) === null || _a === void 0 ? void 0 : _a.find(function (workflow) { return workflow.workflowTemplateId === selectedWorkflowId; });
    var selectableComponents = (_b = selectedWorkflowTemplate === null || selectedWorkflowTemplate === void 0 ? void 0 : selectedWorkflowTemplate.components.filter(hooks_1.isRelatedCardComponent).filter(function (item) { return item.workflowTemplateId === workflowTemplateId; })) !== null && _b !== void 0 ? _b : [];
    var selectedComponent = selectableComponents.find(function (item) { return item.id === selectedComponentId; });
    var selectableWorkflowItems = referenceableWorkflows.workflows.map(function (workflow) {
        var _a, _b;
        return {
            id: workflow.workflowTemplateId,
            label: (_b = (_a = workspaceDetails.get(workflow.workflowTemplateId)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '',
            imageUrl: workflow.imageUrl,
        };
    });
    var selectableComponentItems = selectableComponents === null || selectableComponents === void 0 ? void 0 : selectableComponents.map(function (item) {
        return { id: item.id, label: item.label, icon: item.icon };
    });
    var selectableViewTemplates = (_d = (_c = viewTemplates === null || viewTemplates === void 0 ? void 0 : viewTemplates.viewTemplates) === null || _c === void 0 ? void 0 : _c.filter(function (viewTemplate) { return viewTemplate.subtype === 'LIST'; })) === null || _d === void 0 ? void 0 : _d.map(function (viewTemplate) {
        return { id: viewTemplate.id, label: viewTemplate.name };
    });
    var selectedViewTemplate = selectableViewTemplates.find(function (vt) { return vt.id === selectedViewTemplateId; });
    return {
        selectedWorkflowTemplate: selectedWorkflowTemplate,
        setSelectedWorkflowId: setSelectedWorkflow,
        selectedComponent: selectedComponent,
        setSelectedComponentId: setSelectedComponent,
        selectedViewTemplate: selectedViewTemplate,
        setSelectedViewTemplate: setSelectedViewTemplate,
        selectableWorkflowItems: selectableWorkflowItems,
        selectableComponentItems: selectableComponentItems,
        selectableViewTemplates: selectableViewTemplates,
        workflowLoading: referenceableWorkflows.loading,
        viewTemplatesLoading: viewTemplates.loading,
    };
}
exports.useReferencedInOptions = useReferencedInOptions;
