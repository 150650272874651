"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSearch = void 0;
var react_1 = require("react");
var ControlsProvider_1 = require("./ControlsProvider");
var useSearch = function () {
    var _a = (0, react_1.useContext)(ControlsProvider_1.ControlsContext), search = _a.search, setSearch = _a.setSearch;
    return {
        search: search,
        setSearch: setSearch,
    };
};
exports.useSearch = useSearch;
