"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.restore = exports.persist = exports.STORAGE_PERSISTED_CHAT_MESSAGES_KEY = void 0;
// @ts-strict-ignore
var _ = __importStar(require("lodash"));
var storage_1 = require("@shared/util/storage");
exports.STORAGE_PERSISTED_CHAT_MESSAGES_KEY = 'STORAGE:PERSISTED_CHAT_MESSAGES_KEY';
/*
    Persist the current chat message content into store.
*/
var persist = function (state) { return __awaiter(void 0, void 0, void 0, function () {
    var messages, uuidsFromChannel, statusFromUuid, dataToPersist;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                messages = state.chatMessages.cachedMessages;
                uuidsFromChannel = state.chatMessages.cachedMessageUuidsFromChannelSlug;
                statusFromUuid = state.chatMessages.deliveryStatusFromMessageUuid;
                dataToPersist = _.default(uuidsFromChannel)
                    // filter object to get rid of channels without messages
                    .mapValues(function (uuids) {
                    return uuids
                        .filter(function (uuid) {
                        return _.includes(['waiting', 'failed', 'uploadingAttachments'], statusFromUuid[uuid]);
                    })
                        .map(function (uuid) { return messages[uuid]; })
                        .filter(function (message) { return !!message; });
                })
                    .pickBy(function (chatMessages) { return !!chatMessages.length; })
                    .value();
                return [4 /*yield*/, storage_1.MBStorage.setJSON(exports.STORAGE_PERSISTED_CHAT_MESSAGES_KEY, dataToPersist)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
exports.persist = persist;
/*
    Restores the data saved in setupListeningToChatMessagesToPersist
    from AsyncStorage. Note: this does not store it in redux.

    Note: we don't restore message attachments and if they've been uploaded.
    The reason is that we know which attachments have been uploaded by
    looking if their url is public (http) or local.
*/
var restore = function () { return __awaiter(void 0, void 0, void 0, function () {
    var persistedData, cachedMessages, cachedMessageUuidsFromChannelSlug, deliveryStatusFromMessageUuid;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, storage_1.MBStorage.getJSON(exports.STORAGE_PERSISTED_CHAT_MESSAGES_KEY)];
            case 1:
                persistedData = _a.sent();
                if (!persistedData) {
                    return [2 /*return*/, undefined];
                }
                cachedMessages = _.default(persistedData).values().flatten()
                    .keyBy(function (message) { return message.meta.uuid; })
                    .value();
                cachedMessageUuidsFromChannelSlug = _.mapValues(persistedData, function (messages) { return messages.map(function (message) { return message.meta.uuid; }); });
                deliveryStatusFromMessageUuid = _.default(cachedMessages)
                    .keyBy(function (message) { return message.meta.uuid; })
                    .mapValues(function () { return 'waiting'; })
                    .value();
                return [2 /*return*/, {
                        cachedMessages: cachedMessages,
                        cachedMessageUuidsFromChannelSlug: cachedMessageUuidsFromChannelSlug,
                        deliveryStatusFromMessageUuid: deliveryStatusFromMessageUuid,
                    }];
        }
    });
}); };
exports.restore = restore;
