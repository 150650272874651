"use strict";
//#region Time Tracker Component
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTimeTrackerComponentData = exports.isTimeTrackerComponentDataValue = exports.isTimeTrackerEntry = exports.isTimeTrackerGeolocation = exports.isTimeTrackerGeolocationCoordinates = void 0;
function isTimeTrackerGeolocationCoordinates(obj) {
    return (typeof obj === 'object' &&
        obj !== null &&
        typeof obj.lat === 'number' &&
        typeof obj.lon === 'number');
}
exports.isTimeTrackerGeolocationCoordinates = isTimeTrackerGeolocationCoordinates;
function isTimeTrackerGeolocation(obj) {
    return (typeof obj === 'object' &&
        obj !== null &&
        (typeof obj.accuracy === 'undefined' || typeof obj.accuracy === 'number') &&
        isTimeTrackerGeolocationCoordinates(obj.coords) &&
        typeof obj.userId === 'number');
}
exports.isTimeTrackerGeolocation = isTimeTrackerGeolocation;
function isTimeTrackerEntry(obj) {
    return (typeof obj === 'object' &&
        obj !== null &&
        (typeof obj.geolocation === 'undefined' || isTimeTrackerGeolocation(obj.geolocation)) &&
        typeof obj.timestamp === 'string');
}
exports.isTimeTrackerEntry = isTimeTrackerEntry;
function isTimeTrackerComponentDataValue(obj) {
    return (typeof obj === 'object' &&
        obj !== null &&
        (typeof obj.end === 'undefined' || isTimeTrackerEntry(obj.end)) &&
        isTimeTrackerEntry(obj.start));
}
exports.isTimeTrackerComponentDataValue = isTimeTrackerComponentDataValue;
function isTimeTrackerComponentData(obj) {
    return Array.isArray(obj) && obj.every(isTimeTrackerComponentDataValue);
}
exports.isTimeTrackerComponentData = isTimeTrackerComponentData;
//#endregion
