"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateEntityWidget = void 0;
// @ts-strict-ignore
var client_1 = require("@apollo/client");
var api_1 = require("@shared/api");
var lodash_1 = __importStar(require("lodash"));
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var uuid_1 = require("@shared/util/uuid");
var hooks_1 = require("@shared/scenes/workflows/hooks");
function useCreateEntityWidget() {
    var _a;
    var businessId = (0, react_redux_1.useSelector)(function (store) { return store.appState.currentBusinessId; });
    var _b = (0, hooks_1.useListWorkflowTemplates)(false), workflowTemplates = _b.workflowTemplates, loadingWorkflowTemplates = _b.loading;
    var dashboards = (0, client_1.useQuery)(api_1.Queries.dashboard.listDashboards, {
        fetchPolicy: 'network-only',
    });
    var updateDashboard = (0, client_1.useMutation)(api_1.Mutations.dashboard.updateDashboard)[0];
    var createDashboard = (0, client_1.useMutation)(api_1.Mutations.dashboard.createDashboard)[0];
    var createEntityWidget = (0, react_1.useCallback)(function (viewTemplateId, workflowTemplateId, name, channelId) {
        var _a, _b;
        if (loadingWorkflowTemplates) {
            createEntityWidget(viewTemplateId, workflowTemplateId, name, channelId);
        }
        if (!lodash_1.default.isEmpty((_a = dashboards.data) === null || _a === void 0 ? void 0 : _a.listDashboards)) {
            var dashboard = lodash_1.default.first((_b = dashboards.data) === null || _b === void 0 ? void 0 : _b.listDashboards);
            var entityWidgets = __spreadArray([], dashboard.entityWidgets.filter(function (ew) { return !(0, lodash_1.isNil)(workflowTemplates.find(function (wft) { return wft.id === ew.workflowTemplateId; })); }), true);
            entityWidgets.push({
                id: (0, uuid_1.v4)(),
                workflowTemplateId: workflowTemplateId,
                viewTemplateId: viewTemplateId,
                name: name,
                channelId: channelId,
            });
            return updateDashboard({
                variables: {
                    input: {
                        businessId: dashboard.businessId,
                        id: dashboard.id,
                        entityWidgets: entityWidgets,
                    },
                },
                refetchQueries: [{ query: api_1.Queries.dashboard.listDashboards }],
            });
        }
        else {
            return createDashboard({
                variables: {
                    input: {
                        businessId: businessId,
                        entityWidgets: [
                            {
                                workflowTemplateId: workflowTemplateId,
                                viewTemplateId: viewTemplateId,
                                name: name,
                                channelId: channelId,
                            },
                        ],
                    },
                },
                refetchQueries: [{ query: api_1.Queries.dashboard.listDashboards }],
            });
        }
    }, [
        businessId,
        createDashboard,
        (_a = dashboards.data) === null || _a === void 0 ? void 0 : _a.listDashboards,
        updateDashboard,
        loadingWorkflowTemplates,
        workflowTemplates,
    ]);
    return {
        loading: dashboards.loading,
        createEntityWidget: createEntityWidget,
    };
}
exports.useCreateEntityWidget = useCreateEntityWidget;
