exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-2!../../../../node_modules/postcss-loader/src/index.js??postcss!../../composes.css"), "");
// Module
exports.push([module.id, "._2UDXZf2qda8EYsWauds9e4 {\n  background-color: #F8F8F8;\n  padding: 1px;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n\n.d7r94AgmWjCgJTdWod-zV {\n  margin: 0;\n  color: #656565;\n}\n\n.cnkehmSN6N5_WpQe3jDR9 {\n  padding: 8px 0;\n  min-width: 240px;\n  text-align: center;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "_2UDXZf2qda8EYsWauds9e4 " + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-2!../../../../node_modules/postcss-loader/src/index.js??postcss!../../composes.css").locals["flexColumnCenter"] + "",
	"title": "d7r94AgmWjCgJTdWod-zV",
	"form": "cnkehmSN6N5_WpQe3jDR9"
};