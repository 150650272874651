"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatedCardFieldOption = void 0;
var hooks_1 = require("../../hooks");
var RelatedCardFieldOption = /** @class */ (function () {
    function RelatedCardFieldOption(title, icon, component) {
        this.title = title;
        this.icon = icon;
        this.component = component;
    }
    RelatedCardFieldOption.prototype.matchesComponent = function (component, _reservedIds) {
        return (0, hooks_1.isRelatedCardComponent)(component);
    };
    return RelatedCardFieldOption;
}());
exports.RelatedCardFieldOption = RelatedCardFieldOption;
