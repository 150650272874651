"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagField = void 0;
var lodash_1 = require("lodash");
var TagField = /** @class */ (function () {
    function TagField(jsonBlob, componentId, tagOptions) {
        this.fieldType = 'TagField';
        this.jsonBlob = jsonBlob;
        this.fieldName = componentId;
        this.tagOptions = tagOptions;
    }
    TagField.prototype.validate = function () {
        return null;
    };
    TagField.prototype.isEqual = function (field) {
        return (field instanceof TagField &&
            this.jsonBlob === field.jsonBlob &&
            this.fieldName === field.fieldName);
    };
    TagField.prototype.toFieldJson = function () {
        return this.jsonBlob;
    };
    Object.defineProperty(TagField.prototype, "isEmpty", {
        get: function () {
            return (0, lodash_1.isNil)(this.jsonBlob);
        },
        enumerable: false,
        configurable: true
    });
    return TagField;
}());
exports.TagField = TagField;
