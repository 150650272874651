"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSlide = exports.useFade = void 0;
var react_1 = require("react");
var react_native_1 = require("react-native");
var useFade = function (_a) {
    var duration = _a.duration, _b = _a.value, value = _b === void 0 ? 1 : _b;
    var opacity = (0, react_1.useRef)(new react_native_1.Animated.Value(0)).current;
    var fadeIn = (0, react_1.useRef)(react_native_1.Animated.timing(opacity, {
        duration: duration,
        toValue: value,
        useNativeDriver: false,
    })).current;
    var fadeOut = (0, react_1.useRef)(react_native_1.Animated.timing(opacity, {
        duration: duration,
        toValue: 0.0,
        useNativeDriver: false,
    })).current;
    return { opacity: opacity, fadeIn: fadeIn, fadeOut: fadeOut };
};
exports.useFade = useFade;
var useSlide = function (_a) {
    var duration = _a.duration, value = _a.value;
    var translateY = (0, react_1.useRef)(new react_native_1.Animated.Value(value)).current;
    var slideUp = (0, react_1.useRef)(react_native_1.Animated.spring(translateY, {
        toValue: 0,
        useNativeDriver: false,
    })).current;
    var slideDown = (0, react_1.useRef)(react_native_1.Animated.timing(translateY, {
        duration: duration,
        toValue: value,
        useNativeDriver: false,
    })).current;
    return { translateY: translateY, slideUp: slideUp, slideDown: slideDown };
};
exports.useSlide = useSlide;
