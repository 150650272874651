exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-2!../../../../node_modules/postcss-loader/src/index.js??postcss!../../composes.css"), "");
// Module
exports.push([module.id, "._14UgBsU8i0fM-5E2C11APu {\n  text-align: center;\n}\n\n.GREEcDBANYD5dp93AtsR9 {\n  margin: auto;\n  margin-bottom: 16px;\n  position: relative;\n  padding-bottom: 56.25%; /*16:9*/\n  height: 0;\n  overflow: hidden;\n  max-width: 560px;\n}\n\n._36TDMZJNdn5Ht74T9ZD82F {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  max-height: 315px;\n  padding-left: 8px;\n  padding-right: 8px;\n}\n\n.O-EdLitfocPvsKq8zgRGd {\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  -webkit-transform: translate(-50%, -50%);\n      -ms-transform: translate(-50%, -50%);\n          transform: translate(-50%, -50%);\n  padding: 16px;\n  width: 100%;\n  overflow: scroll;\n  max-height: 450px;\n  max-width: 500px;\n  background-color: #FFF;\n  border: 1px solid #E9E9E9;\n  outline: none;\n  border-radius: 4px;\n  overflow: auto;\n}\n\n._2zUBoZTNzG0EQ7qXIgaH1L {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n}\n\n._2w7ZT93Cd1-Rnd4AJ2Ox9E {\n}\n\n._3toD7JFlVttBSTR78Ovt5A {\n  -ms-flex-item-align: end;\n      align-self: flex-end;\n  border: 0;\n  background: none;\n  margin-left: 16px;\n  padding-bottom: 16px;\n  stroke: #242424;\n}\n\n._2df4N5JUpbExtkBpQ2WF2G {\n  margin-bottom: 16px;\n}\n\n._2Zp8tqerCGU6gGGbOKx583 {\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "_14UgBsU8i0fM-5E2C11APu",
	"videoRow": "GREEcDBANYD5dp93AtsR9",
	"video": "_36TDMZJNdn5Ht74T9ZD82F",
	"modal": "O-EdLitfocPvsKq8zgRGd",
	"overlay": "_2zUBoZTNzG0EQ7qXIgaH1L",
	"buttonWrapper": "_2w7ZT93Cd1-Rnd4AJ2Ox9E " + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-2!../../../../node_modules/postcss-loader/src/index.js??postcss!../../composes.css").locals["flexColumn"] + "",
	"closeButton": "_3toD7JFlVttBSTR78Ovt5A",
	"text": "_2df4N5JUpbExtkBpQ2WF2G",
	"content": "_2Zp8tqerCGU6gGGbOKx583 " + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-2!../../../../node_modules/postcss-loader/src/index.js??postcss!../../composes.css").locals["flexColumnCenter"] + ""
};