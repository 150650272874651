"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowDateComponent = void 0;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var hooks_1 = require("../../hooks");
var WorkflowTextComponent_1 = require("./WorkflowTextComponent");
var date_1 = require("@shared/util/date");
/*
  TODO
  This component is only used as a readOnly lookup component.
  To use in forms, will need a date picker.
  See inputDate.
*/
var WorkflowDateComponent = function (_a) {
    var initialData = _a.initialData, component = _a.component, isReadOnly = _a.isReadOnly, showLabel = _a.showLabel, paddingHorizontal = _a.paddingHorizontal, iconPad = _a.iconPad;
    var _b = (0, react_1.useState)(), displayString = _b[0], setDisplayString = _b[1];
    var readOnly = isReadOnly || component.readonly;
    (0, react_1.useEffect)(function () {
        setDisplayString(!(0, lodash_1.isNil)(initialData) ? (0, date_1.formatDateTimeWithYear)(initialData) : undefined);
    }, [initialData, component, setDisplayString]);
    if (!(0, hooks_1.isInputComponent)(component)) {
        return null;
    }
    if (readOnly) {
        return (react_1.default.createElement(WorkflowTextComponent_1.WorkflowTextComponent, { initialData: displayString, component: component, isReadOnly: true, showLabel: showLabel, paddingHorizontal: paddingHorizontal, iconPad: iconPad }));
    }
    return null;
};
exports.WorkflowDateComponent = WorkflowDateComponent;
