"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBBusinessSwitcher = void 0;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var i18n_1 = require("@shared/util/i18n");
var hooks_1 = require("@shared/util/hooks");
var components_1 = require("@shared/components");
var row_1 = require("./row");
var keyExtractor = function (business) { return String(business.id); };
/*
    Lets the user switch between businesses. The search bar is used to filter
    businesses by name. When the create button is pressed, the user is taken
    to the "create new business" flow.
*/
var MBBusinessSwitcher = function (_a) {
    var selectedBusinessId = _a.selectedBusinessId, onCreateBusiness = _a.onCreateBusiness, onSelectBusiness = _a.onSelectBusiness, allBusinesses = _a.businesses;
    var _b = (0, react_1.useState)(''), query = _b[0], setQuery = _b[1];
    var lowercaseQuery = query.toLowerCase();
    var businesses = (0, hooks_1.useFilter)(allBusinesses, function (business) { return !lowercaseQuery || business.name.toLowerCase().includes(lowercaseQuery); }, [lowercaseQuery]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.MBView, { padding: "small" },
            react_1.default.createElement(components_1.MBSearchBar, { value: query, onChangeText: setQuery, placeholder: (0, i18n_1.i18n)('business.switcher.jumpToBusiness') })),
        react_1.default.createElement(react_native_1.FlatList, { data: businesses, keyExtractor: keyExtractor, keyboardDismissMode: "on-drag", keyboardShouldPersistTaps: "always", testID: "business-switcher", renderItem: function (_a) {
                var business = _a.item;
                return (react_1.default.createElement(row_1.MBBusinessSwitcherRow, { key: business.id, business: business, onPress: onSelectBusiness, isSelected: !!selectedBusinessId && selectedBusinessId === business.id }));
            }, ListFooterComponent: react_1.default.createElement(components_1.MBAddRow, { onPress: onCreateBusiness, inverted: true, isHidingPressableIndicator: true, title: (0, i18n_1.i18n)('business.switcher.createNewBusiness') }) }),
        react_1.default.createElement(components_1.MBNativeKeyboardSpacer, null)));
};
exports.MBBusinessSwitcher = MBBusinessSwitcher;
