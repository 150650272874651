"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagCollectionViewField = void 0;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var CardCollectionViewFieldContainer_1 = require("./CardCollectionViewFieldContainer");
var react_native_1 = require("react-native");
var tag_1 = require("@shared/components/blocks/lib/tags/tag");
var render_1 = require("@shared/components/blocks/contexts/render");
var MAX_TAGS_TO_SHOW = 3;
var styles = react_native_1.StyleSheet.create({
    tagList: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginLeft: -(0, components_1.grid)(0.5),
        paddingVertical: (0, components_1.grid)(0.5),
        alignItems: 'center',
    },
    tagItem: {
        paddingVertical: (0, components_1.grid)(0.5),
    },
    // once we remove renderContext we can define default tag text style here
    tagText: {},
});
function TagCollectionViewField(_a) {
    var data = _a.data, style = _a.style, tagItemStyle = _a.tagItemStyle, tagTextStyle = _a.tagTextStyle;
    if (data.isEmpty) {
        return null;
    }
    var visibleTagValues = data.jsonBlob.slice(0, MAX_TAGS_TO_SHOW);
    var remainingTagCount = data.jsonBlob.length - visibleTagValues.length;
    var visibleTags = data.tagOptions.filter(function (tag) { return visibleTagValues.includes(tag.value); });
    return (react_1.default.createElement(CardCollectionViewFieldContainer_1.CardCollectionViewFieldContainer, { style: [styles.tagList, style] },
        visibleTags.map(function (tag) { return (react_1.default.createElement(TagItem, { key: tag.value, tag: tag, tagItemStyle: tagItemStyle, tagTextStyle: tagTextStyle })); }),
        remainingTagCount > 0 && (react_1.default.createElement(components_1.MBView, { style: [styles.tagItem, tagItemStyle] },
            react_1.default.createElement(components_1.MBTextBody2, { style: [styles.tagText, tagTextStyle] },
                "+",
                remainingTagCount)))));
}
exports.TagCollectionViewField = TagCollectionViewField;
function TagItem(_a) {
    var tag = _a.tag, tagItemStyle = _a.tagItemStyle, tagTextStyle = _a.tagTextStyle;
    var renderContext = (0, react_1.useContext)(render_1.CardRenderContext);
    var isCalendar = renderContext === 'calendar';
    var TypeComponent = isCalendar ? components_1.MBTextBody2 : components_1.MBTextBody1;
    var numberOfLines = isCalendar ? 1 : undefined;
    return (react_1.default.createElement(tag_1.Tag, { color: tag.backgroundColor, style: [styles.tagItem, tagItemStyle] },
        react_1.default.createElement(TypeComponent, { numberOfLines: numberOfLines, style: [styles.tagText, tagTextStyle] }, tag.label)));
}
