"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBFormattedText = void 0;
var React = __importStar(require("react"));
var _ = __importStar(require("lodash"));
var styles_1 = require("@shared/styles");
var components_1 = require("@shared/components");
/*
    Formats a token with a given substring
*/
var MBFormattedTextSegment = /** @class */ (function (_super) {
    __extends(MBFormattedTextSegment, _super);
    function MBFormattedTextSegment() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MBFormattedTextSegment.prototype.render = function () {
        var _a = this.props, children = _a.children, token = _a.token, highlightColor = _a.highlightColor, otherProps = __rest(_a, ["children", "token", "highlightColor"]);
        var defaultProps = __assign(__assign({}, otherProps), { children: children, color: highlightColor, nativeId: 'bubble' });
        if (!token) {
            return React.createElement(components_1.MBText, __assign({}, otherProps, { children: children }));
        }
        if (token.type === 'url') {
            return React.createElement(LinkToken, __assign({ token: token }, defaultProps));
        }
        if (token.type === 'mention') {
            return React.createElement(components_1.MBText, __assign({ bold: true }, defaultProps));
        }
        if (token.type === 'email') {
            return React.createElement(LinkToken, __assign({ token: token }, defaultProps));
        }
        if (token.type === 'hashtag') {
            return React.createElement(components_1.MBText, __assign({ bold: true }, defaultProps));
        }
        if (token.type === null) {
            return React.createElement(components_1.MBText, __assign({}, defaultProps));
        }
        return React.createElement(components_1.MBText, __assign({}, otherProps, { children: children }));
    };
    return MBFormattedTextSegment;
}(React.PureComponent));
/*
    Formats text like "#hello @all king@coastapp.com is my http://email.com"
    Note: this uses API specific data (MBAPI.ChatMetaToken), but I figured
    it should be under standard components since it might be useful later
    in other contexts too.
*/
var MBFormattedText = function (_a) {
    var tokens = _a.tokens, text = _a.children, _b = _a.highlightColor, highlightColor = _b === void 0 ? styles_1.Colors.v2.purple : _b, otherProps = __rest(_a, ["tokens", "children", "highlightColor"]);
    // If there are no tokens, the regex below would just shread the string
    // in pieces (regex /(|)/ turns "asd" => ["a", "s", "d"] ), which would
    // make ex emojis not work
    if (!tokens.length) {
        return React.createElement(components_1.MBText, __assign({ nativeId: "bubble", children: text }, otherProps));
    }
    var tokensFromValue = _.keyBy(tokens, 'value');
    // Split text into segments based on the token values
    // "hello @all done" => ["hello ", "@all", " done"]
    // For reference: https://stackoverflow.com/q/54330321/9117995
    var values = tokens
        .map(function (token) { return token.value; })
        // Need to escape strings, otherwise eg links with "?" fail
        // https://stackoverflow.com/a/29700268
        .map(_.escapeRegExp);
    // Create regex that finds the token values that we want to format
    var regex = new RegExp("(".concat(values.join('|'), ")"));
    // Remove all empty strings (eg when splitting "@all" => ["", "@all", ""])
    var substrings = text.split(regex).filter(function (split) { return !!split; });
    return (React.createElement(components_1.MBText, null, substrings.map(function (substring, index) { return (React.createElement(MBFormattedTextSegment, __assign({}, otherProps, { key: index, children: substring, token: tokensFromValue[substring], highlightColor: highlightColor }))); })));
};
exports.MBFormattedText = MBFormattedText;
function LinkToken(_a) {
    var token = _a.token, children = _a.children, props = __rest(_a, ["token", "children"]);
    var style = {
        color: 'inherit',
        font: 'inherit',
        textDecoration: 'inherit',
    };
    return (React.createElement(components_1.MBText, __assign({ bold: true }, props),
        React.createElement("a", { href: token.href, target: "_blank", rel: "noreferrer", style: style }, children)));
}
exports.MBFormattedText.displayName = 'MBFormattedText';
