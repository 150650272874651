"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardStyles = void 0;
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
exports.DashboardStyles = react_native_1.StyleSheet.create({
    widget: {
        shadowColor: 'rgba(0, 0, 0, 0.1)',
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: (0, components_1.grid)(0.5),
        shadowOpacity: 1,
        padding: (0, components_1.grid)(1.5),
        elevation: 3,
        borderRadius: (0, components_1.grid)(1),
        marginHorizontal: (0, components_1.grid)(1),
        marginVertical: (0, components_1.grid)(1),
    },
});
