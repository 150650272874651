"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useReconnectWebsocketOnBusinessChange = void 0;
// @ts-strict-ignore
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var client_1 = require("@shared/api/client");
function useReconnectWebsocketOnBusinessChange() {
    var currentBusinessId = (0, react_redux_1.useSelector)(function (state) { return state.appState.currentBusinessId; });
    var _a = (0, react_1.useState)(false), hasInitiallyLoaded = _a[0], setHasInitiallyLoaded = _a[1];
    (0, react_1.useEffect)(function () {
        if (hasInitiallyLoaded) {
            (0, client_1.reconnectWebsocket)();
        }
        else {
            setHasInitiallyLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentBusinessId]);
}
exports.useReconnectWebsocketOnBusinessChange = useReconnectWebsocketOnBusinessChange;
