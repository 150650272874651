"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateCardPrompt = exports.useCreateCardButton = void 0;
// @ts-strict-ignore
var hook_1 = require("@shared/util/actionSheet/hook");
var hooks_1 = require("@shared/util/hooks");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var hooks_2 = require("../hooks");
var hasCreateTemplate = function (definition) {
    return definition === null || definition === void 0 ? void 0 : definition.templates.some(function (_a) {
        var template = _a.template;
        return (template === null || template === void 0 ? void 0 : template.context) === 'create';
    });
};
function useAvailableCardDefinitions(channel, includeRoot) {
    var cardDefinitions = (0, hooks_2.useCardDefinitions)(channel === null || channel === void 0 ? void 0 : channel.id, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId).cardDefinitions;
    return (0, react_1.useMemo)(function () {
        return cardDefinitions === null || cardDefinitions === void 0 ? void 0 : cardDefinitions.filter(function (definition) {
            var rootCondition = includeRoot || !definition.root;
            return hasCreateTemplate(definition) && rootCondition;
        });
    }, [cardDefinitions, includeRoot]);
}
var useCreateCardButton = function (_a) {
    var channelSlug = _a.channelSlug;
    var channel = (0, hooks_1.useChannel)(channelSlug);
    var navigator = (0, useNavigator_1.useNavigator)();
    var cardDefinition = (0, hooks_2.useCardDefinition)(channel === null || channel === void 0 ? void 0 : channel.id, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId).cardDefinition;
    var availableDefinitions = useAvailableCardDefinitions(channel);
    var onCreateWithDefinition = (0, react_1.useCallback)(function (definition) {
        navigator.navigate('CardCreate', { channelSlug: channelSlug, cardDefinitionId: definition.id });
    }, [channelSlug, navigator]);
    var onCreateTemplate = (0, react_1.useCallback)(function () {
        navigator.navigate('ChannelFragmentTemplatesCreate', { channelSlug: channelSlug });
    }, [channelSlug, navigator]);
    var onCreateWithRootDefinition = (0, react_1.useCallback)(function () { return onCreateWithDefinition(cardDefinition); }, [onCreateWithDefinition, cardDefinition]);
    return (0, react_1.useMemo)(function () { return ({
        availableDefinitions: availableDefinitions,
        onCreateWithDefinition: onCreateWithDefinition,
        onCreateTemplate: onCreateTemplate,
        onCreateWithRootDefinition: onCreateWithRootDefinition,
        channel: channel,
    }); }, [
        availableDefinitions,
        channel,
        onCreateTemplate,
        onCreateWithDefinition,
        onCreateWithRootDefinition,
    ]);
};
exports.useCreateCardButton = useCreateCardButton;
function useCreateCardPrompt(channelSlug) {
    var channel = (0, hooks_1.useChannel)(channelSlug);
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var navigate = (0, useNavigator_1.useNavigator)().navigate;
    var availableDefinitions = useAvailableCardDefinitions(channel, true);
    var showCreateCardSheet = (0, hook_1.useActionSheet)({
        webHorizontalAlignment: 'right',
        webVerticalAlignment: isDesktop ? 'below' : 'above',
        options: availableDefinitions === null || availableDefinitions === void 0 ? void 0 : availableDefinitions.map(function (definition) { return ({
            title: definition.name,
            onPress: function () { return navigate('CardCreate', { channelSlug: channelSlug, cardDefinitionId: definition.id }); },
        }); }),
    });
    var navigateToCreate = (0, useNavigator_1.useNavigateTo)('CardCreate', { channelSlug: channelSlug });
    return (0, react_1.useMemo)(function () {
        return !!availableDefinitions && availableDefinitions.length > 1
            ? showCreateCardSheet
            : navigateToCreate;
    }, [navigateToCreate, showCreateCardSheet, availableDefinitions]);
}
exports.useCreateCardPrompt = useCreateCardPrompt;
