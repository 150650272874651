"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSaveViewTemplateCollectionCriteria = void 0;
var react_1 = require("react");
var CardsEntityService_1 = require("@shared/data/cards/CardsEntityService");
var useFilter_1 = require("./useFilter");
var useGroup_1 = require("./useGroup");
var useSort_1 = require("./useSort");
var toast_1 = require("@shared/components/toast");
var i18n_1 = require("@shared/util/i18n");
var config_1 = require("@shared/config");
var lodash_1 = require("lodash");
function useSaveViewTemplateCollectionCriteria(viewTemplate, updateViewTemplate) {
    var selectedSortOptions = (0, useSort_1.useSort)().selectedSortOptions;
    var selectedFilterOptions = (0, useFilter_1.useFilter)().selectedFilterOptions;
    var selectedGroupOptions = (0, useGroup_1.useGroup)().selectedGroupOptions;
    var toastContext = (0, react_1.useContext)(toast_1.ToastContext);
    function onSave() {
        var sortByComponents = Array.from(selectedSortOptions.values()).map(function (o) { return (0, CardsEntityService_1.getSortComponent)(o); });
        var filterByComponents = Array.from(selectedFilterOptions.values()).map(function (o) {
            return (0, CardsEntityService_1.getFilterComponent)(o);
        });
        var groupsWithFilters = selectedGroupOptions.filter(function (g) { return !(0, lodash_1.isEmpty)(Array.from(g.values())); });
        var groups = groupsWithFilters.map(function (group) { return ({
            filterByComponents: Array.from(group.values()).map(function (value) { return (0, CardsEntityService_1.getFilterComponent)(value); }),
        }); });
        var updatedViewTemplate = __assign(__assign({}, viewTemplate), { collectionCriteria: {
                filterByComponents: filterByComponents,
                sortByComponents: sortByComponents,
                groups: groups,
                // TODO if saving
                // omniSearchQuery
            } });
        updateViewTemplate(updatedViewTemplate).then(function () {
            toastContext.show({
                style: 'confirmation',
                message: (0, i18n_1.i18n)('card.actions.viewOptions.success'),
                duration: config_1.Config.TOAST_DEFAULT_DURATION,
            });
        }, function () {
            toastContext.show({
                style: 'warning',
                message: (0, i18n_1.i18n)('errors.somethingWentWrong'),
                duration: config_1.Config.TOAST_DEFAULT_DURATION,
            });
        });
    }
    return {
        onSave: onSave,
    };
}
exports.useSaveViewTemplateCollectionCriteria = useSaveViewTemplateCollectionCriteria;
