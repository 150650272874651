"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBScrollView = void 0;
// @ts-strict-ignore
var React = __importStar(require("react"));
var _ = __importStar(require("lodash"));
var components_1 = require("@shared/components");
/*
    A scrollview wrapper with some additional features:
    * Callback for when the bottom is reached
    * Show shadows at borders where content is scrollable (showOverflowShadowIndicator)
*/
var MBScrollView = exports.MBScrollView = /** @class */ (function (_super) {
    __extends(MBScrollView, _super);
    function MBScrollView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isTopScrollable: true,
            isBottomScrollable: true,
        };
        // Determine if top or bottom of scrollview has been reached.
        // For reference:
        // https://stackoverflow.com/a/49573628/9117995
        _this._onScroll = function () { return _this.onScroll(); };
        /*
              Render shadow indicators if the user is not at the top or bottom
              of the scroll view
          */
        _this.renderShadowIndicator = function () {
            if (!_this.props.showOverflowShadowIndicator) {
                return null;
            }
            var indicatorSize = _this.props.overflowShadowIndicatorSize || 0;
            return (React.createElement("div", { style: {
                    position: 'absolute',
                    // To show a shadow, we make the margin 0, to hide it we
                    // make the box shadow overlay view stick outside of it's
                    // parent (which has overflow hidden), to hide the shadow.
                    top: _this.state.isTopScrollable ? 0 : -indicatorSize,
                    left: -indicatorSize,
                    right: -indicatorSize,
                    bottom: _this.state.isBottomScrollable ? 0 : -indicatorSize,
                    // Disable pointer events on this shadow overlay.
                    // TODO(fant): change this div to MBView. We current get errors
                    // because MBView (aka react-native View) doesn't want pointerEvents as style
                    pointerEvents: 'none',
                    boxShadow: "inset 0 0 ".concat(indicatorSize, "px rgba(0, 0, 0, 0.15)"),
                } }));
        };
        return _this;
    }
    // https://stackoverflow.com/questions/33188994
    MBScrollView.prototype.scrollToTop = function () {
        this.scrollTo(0);
    };
    // https://stackoverflow.com/a/270628/9117995
    MBScrollView.prototype.scrollToBottom = function () {
        this.scrollTo(this.scrollview.scrollHeight);
    };
    // animate scrolling to offset
    // https://stackoverflow.com/a/24559613/9117995
    MBScrollView.prototype.scrollTo = function (offset) {
        this.scrollview.scrollTop = offset;
        /*
            // Don't animate if we're already at the correct offset
            if (this.scrollview.scrollTop === offset) return;
    
            const startOffset = this.scrollview.scrollTop;
            const startedAt = Date.now();
            const duration = ms.seconds(0.5);
            const easing = Easing.inOut(Easing.quad);
    
            const step = () => {
                const progress = Math.max(1, (Date.now() - startedAt) / duration);
                const easedOffset = startOffset + (offset - startOffset) * easing(progress);
                this.scrollview.scrollTop = easedOffset;
                // ease until the animation is done
                if (progress < 1) window.requestAnimationFrame(step);
            };
    
            window.requestAnimationFrame(step);
            */
    };
    MBScrollView.prototype.onScroll = function () {
        if (!this.scrollview) {
            return;
        }
        var sv = this.scrollview;
        var isTopScrollable = sv.scrollTop !== 0;
        var isBottomScrollable = sv.scrollHeight - sv.scrollTop !== sv.clientHeight;
        if (this.state.isBottomScrollable !== isBottomScrollable && !!this.props.onEndReached) {
            this.props.onEndReached();
        }
        if (this.state.isTopScrollable !== isTopScrollable && !!this.props.onStartReached) {
            this.props.onStartReached();
        }
        if (this.state.isTopScrollable !== isTopScrollable ||
            this.state.isBottomScrollable !== isBottomScrollable) {
            this.setState({ isTopScrollable: isTopScrollable, isBottomScrollable: isBottomScrollable });
        }
    };
    // Check scroll status when the view loads to load the appropriate shadows
    MBScrollView.prototype.componentDidMount = function () {
        this.onScroll();
    };
    MBScrollView.prototype.render = function () {
        var _a;
        var _this = this;
        var horizontal = (_a = this.props, _a.horizontal), children = _a.children, style = _a.style, webScrollableDivStyle = _a.webScrollableDivStyle, webContentStyle = _a.webContentStyle;
        // TODO(fant): when we later want to use this.props.showOverflowShadowIndicator,
        // we need to set { overflow: 'hidden' } on the parent div to hide the shadow.
        return (React.createElement(components_1.MBView, { flex: true, style: style },
            React.createElement("div", { ref: function (ref) { return (_this.scrollview = ref); }, onScroll: _.debounce(this._onScroll, 100), style: __assign({ overflowX: horizontal ? 'auto' : undefined, overflowY: horizontal ? undefined : 'auto', flex: 1 }, webScrollableDivStyle) },
                React.createElement(components_1.MBView, { row: horizontal, style: webContentStyle }, children)),
            this.renderShadowIndicator()));
    };
    MBScrollView.defaultProps = {
        showOverflowShadowIndicator: false,
        overflowShadowIndicatorSize: 20,
    };
    return MBScrollView;
}(React.PureComponent));
