"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBMetricsCounter = exports.useUpgrade = exports.findMetric = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_native_1 = require("react-native");
var toast_1 = require("@shared/components/toast");
var i18n_1 = require("@shared/util/i18n");
var client_1 = require("@apollo/client");
var api_1 = require("@shared/api");
var component_1 = require("./component");
var provider_1 = require("@shared/scenes/billing/banners/provider");
var hooks_1 = require("@shared/util/hooks");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var intercom_1 = require("@shared/util/intercom");
function findMetric(type, data) {
    var _a, _b;
    return ((_b = (_a = data === null || data === void 0 ? void 0 : data.metrics) === null || _a === void 0 ? void 0 : _a.find(function (m) { return m.type === type; })) === null || _b === void 0 ? void 0 : _b.value) || 0;
}
exports.findMetric = findMetric;
function useIsBusinessAdmin() {
    var _a;
    var businessId = (0, react_redux_1.useSelector)(function (state) { return state.appState.currentBusinessId; });
    var data = (0, client_1.useQuery)(api_1.Queries.user.profile, {
        variables: { businessId: businessId },
        skip: !businessId,
    }).data;
    return (_a = data === null || data === void 0 ? void 0 : data.me) === null || _a === void 0 ? void 0 : _a.isBusinessAdmin;
}
function useUpgrade() {
    var toast = (0, react_1.useContext)(toast_1.ToastContext);
    var isBusinessAdmin = useIsBusinessAdmin();
    var showIntercomMessenger = (0, intercom_1.useShowIntercomMessenger)();
    var navigateToBilling = (0, useNavigator_1.useNavigateTo)('Billing');
    return (0, react_1.useCallback)(function () {
        if (!isBusinessAdmin) {
            toast.show({
                style: 'confirmation',
                icon: 'exclamation-filled',
                message: (0, i18n_1.i18n)('billing.metricsCounter.contactAdminPrompt'),
            });
        }
        else if (react_native_1.Platform.OS === 'web') {
            navigateToBilling();
        }
        else {
            showIntercomMessenger({ message: (0, i18n_1.i18n)('billing.metricsCounter.defaultMessageToSupport') });
        }
    }, [toast, isBusinessAdmin, showIntercomMessenger, navigateToBilling]);
}
exports.useUpgrade = useUpgrade;
var MBMetricsCounter = function () {
    var handleUpgrade = useUpgrade();
    var billing = (0, react_1.useContext)(provider_1.BillingContext);
    (0, hooks_1.useComponentDidMount)(function () {
        billing === null || billing === void 0 ? void 0 : billing.refetch();
    });
    if (!billing || billing.isPaidPlan) {
        return null;
    }
    return (react_1.default.createElement(component_1.MBMetricsCounter, { messages: billing.messages, messageLimit: billing.messageLimit, cards: billing.cards, cardLimit: billing.cardLimit, storage: billing.storage, storageLimit: billing.storageLimit, onUpgrade: handleUpgrade }));
};
exports.MBMetricsCounter = MBMetricsCounter;
exports.MBMetricsCounter.displayName = 'MBMetricsCounterContainer';
