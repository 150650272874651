"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCardExport = void 0;
var client_1 = require("@apollo/client");
var react_1 = require("react");
var cards_1 = require("@shared/api/mutations/cards");
function useCardExport() {
    var _a, _b;
    var _c = (0, client_1.useMutation)(cards_1.createCardExport), create = _c[0], _d = _c[1], loading = _d.loading, data = _d.data, error = _d.error;
    var createExport = (0, react_1.useCallback)(function (cardId) {
        create({
            variables: {
                input: {
                    id: cardId,
                    format: 'PDF',
                },
            },
        });
    }, [create]);
    return {
        createCardExport: createExport,
        response: {
            isLoading: loading,
            error: error === null || error === void 0 ? void 0 : error.message,
            url: (_b = (_a = data === null || data === void 0 ? void 0 : data.createCardExport) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : undefined,
        },
    };
}
exports.useCardExport = useCardExport;
