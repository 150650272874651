"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNavigateFromExternalAction = exports.useNotifications = exports.useRequestNotifications = void 0;
// @ts-strict-ignore
var react_1 = require("react");
function useRequestNotifications() {
    var requestPermission = (0, react_1.useCallback)(function () { return null; }, []);
    return {
        requestPermission: requestPermission,
        // TODO(fant)
        enabled: true,
        status: 'accepted',
    };
}
exports.useRequestNotifications = useRequestNotifications;
function useNotifications() {
    throw new Error('TODO(fant): implement useNotifications for web');
}
exports.useNotifications = useNotifications;
function useNavigateFromExternalAction() {
    return function (_) {
        throw new Error('Hook not available for web');
    };
}
exports.useNavigateFromExternalAction = useNavigateFromExternalAction;
