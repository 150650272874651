"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardListV2 = exports.MBCardListContainer = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var emptyState_1 = require("@shared/scenes/cards/components/emptyState");
var render_1 = require("@shared/components/blocks/contexts/render");
var sectionHeader_1 = require("@shared/scenes/cards/list/components/sectionHeader");
var sectionFooter_1 = require("@shared/scenes/cards/list/components/sectionFooter");
var hooks_1 = require("@shared/scenes/cards/hooks");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var provider_1 = require("@shared/scenes/cards/actions/provider");
var sectionCard_1 = require("@shared/scenes/cards/list/components/sectionCard");
var hooks_2 = require("../hooks");
var components_2 = require("@shared/scenes/channel/list/components");
var CollectionViewMetrics_1 = require("../../collection/CollectionViewMetrics");
var keyExtractor = function (item) { return item.id; };
var MBCardListContainer = function (_a) {
    var template = _a.template, renderItem = _a.renderItem, renderSectionFooter = _a.renderSectionFooter, renderSectionHeader = _a.renderSectionHeader;
    var sections = (0, hooks_2.useData)(template);
    var paginationSections = (0, hooks_1.useCardPaginationSections)(template);
    var channel = (0, react_1.useContext)(channel_1.CardChannelContext).channel;
    var appliedFilters = (0, react_1.useContext)(provider_1.CardFilterStateContext).appliedFilters;
    var refreshControl = (0, components_2.useRefreshControl)(sections.reset, false);
    if (!paginationSections) {
        return react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true, testID: "card-list-view-loading" });
    }
    if ((!channel.cardCount || !paginationSections.length) &&
        !!template.emptyState &&
        appliedFilters.length === 0) {
        return react_1.default.createElement(emptyState_1.MBCardEmptyState, { emptyState: template.emptyState, testID: "card-list-empty-state" });
    }
    return (react_1.default.createElement(react_native_1.SectionList, { keyboardShouldPersistTaps: "always", keyboardDismissMode: "on-drag", scrollIndicatorInsets: { right: 1 }, sections: sections.data, renderItem: renderItem, renderSectionHeader: renderSectionHeader, renderSectionFooter: renderSectionFooter, ListFooterComponent: function () { return react_1.default.createElement(components_1.MBView, { paddingBottom: 2 }); }, stickySectionHeadersEnabled: true, keyExtractor: keyExtractor, initialNumToRender: 10, refreshControl: refreshControl, testID: "card-list-container" }));
};
exports.MBCardListContainer = MBCardListContainer;
exports.MBCardListV2 = (0, react_1.memo)(function (_a) {
    var template = _a.template;
    (0, react_1.useEffect)(function () {
        (0, CollectionViewMetrics_1.trackCollectionViewLoad)(CollectionViewMetrics_1.CollectionViewType.LIST);
    }, []);
    return (react_1.default.createElement(render_1.CardRenderContext.Provider, { value: !!template.taskOptions ? 'taskList' : 'list' },
        react_1.default.createElement(exports.MBCardListContainer, { template: template, renderItem: sectionCard_1.renderSectionCard, renderSectionFooter: sectionFooter_1.renderCardListSectionFooter, renderSectionHeader: sectionHeader_1.renderCardListSectionHeader })));
});
exports.MBCardListContainer.displayName = 'MBCardListContainer';
exports.MBCardListV2.displayName = 'MBCardListV2';
