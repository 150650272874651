"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBOnboardingInviteContacts = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var context_1 = require("@shared/scenes/business/invite/context");
var i18n_1 = require("@shared/util/i18n");
var components_1 = require("@shared/components");
var typography_1 = require("@shared/components/typography");
var formField_1 = require("@shared/components/form/formField");
var addForm_1 = require("@shared/scenes/business/invite/components/addForm");
var analytics_1 = require("@shared/util/analytics");
var hooks_1 = require("@shared/util/analytics/hooks");
var util_1 = require("@shared/scenes/auth/util");
var styles_1 = require("@shared/styles");
var MBOnboardingInviteContacts = function (_a) {
    var onInvite = _a.onInvite;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _b = (0, react_1.useState)(''), channelName = _b[0], setChannelName = _b[1];
    var _c = (0, react_1.useState)(), channelNameError = _c[0], setChannelNameError = _c[1];
    var setChannelNameAndClearError = (0, react_1.useCallback)(function (updatedChannelName) {
        setChannelName(updatedChannelName);
        setChannelNameError(undefined);
    }, []);
    var submit = (0, react_1.useCallback)(function (rows) { return __awaiter(void 0, void 0, void 0, function () {
        var users;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    analytics_1.MBAnalytics.track('Onboarding::Invite::Form::Submit', { rows: rows, channelName: channelName });
                    if (!channelName) {
                        setChannelNameError((0, i18n_1.i18n)('onboarding.channel.create.name.errorMessage'));
                        return [2 /*return*/];
                    }
                    users = rows.map(function (row) {
                        var _a = row.name.split(' '), firstName = _a[0], otherNames = _a.slice(1);
                        var lastName = otherNames.join(' ');
                        return {
                            firstName: firstName,
                            lastName: lastName,
                            email: util_1.MBAuthUtil.isEmail(row.phoneNumberOrEmail) ? row.phoneNumberOrEmail : undefined,
                            phoneNumber: util_1.MBAuthUtil.isPhoneNumber(row.phoneNumberOrEmail)
                                ? row.phoneNumberOrEmail
                                : undefined,
                        };
                    });
                    return [4 /*yield*/, onInvite(channelName, users)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [channelName, onInvite]);
    return (react_1.default.createElement(context_1.InviteContextProvider, { allowInvalidRows: false, clearValidRowsOnSubmit: false, onSubmit: submit, onFocusNameInput: (0, hooks_1.useTrackEvent)('Onboarding::Invite::Form::NameInput::Focus'), onFocusContactInput: (0, hooks_1.useTrackEvent)('Onboarding::Invite::Form::ContactInput::Focus') },
        react_1.default.createElement(components_1.MBImage, { style: {
                width: 96,
                height: 96,
                borderRadius: 96 / 2,
                alignSelf: 'center',
                backgroundColor: colors.foregroundActive,
            }, source: require('@shared/assets/images/v2/onboarding/people.png') }),
        react_1.default.createElement(components_1.MBView, { paddingBottom: 2 }),
        react_1.default.createElement(typography_1.MBTextSubtitle, { align: "center", color: colors.foregroundActive }, (0, i18n_1.i18n)('onboarding.invite.description')),
        react_1.default.createElement(components_1.MBScrollView, null,
            react_1.default.createElement(components_1.MBView, { paddingBottom: 4 }),
            react_1.default.createElement(formField_1.MBFormField, { error: channelNameError, title: (0, i18n_1.i18n)('onboarding.channel.create.name.title'), required: true, children: function (props) { return (react_1.default.createElement(components_1.MBTextInput, __assign({}, props, { autoCapitalize: "words", autoFocus: true, value: channelName, returnKeyType: "done", placeholder: (0, i18n_1.i18n)('onboarding.channel.create.name.placeholder'), onChangeText: setChannelNameAndClearError }))); } }),
            react_1.default.createElement(components_1.MBView, { paddingBottom: 8 }),
            react_1.default.createElement(addForm_1.MBAddForm, null)),
        react_1.default.createElement(components_1.MBView, { centerHorizontally: true },
            react_1.default.createElement(context_1.InviteContext.Consumer, null, function (_a) {
                var onSubmit = _a.onSubmit;
                return (react_1.default.createElement(components_1.MBButton, { title: (0, i18n_1.i18n)('inviteSomeone.addForm.sendInvites'), onPress: onSubmit }));
            })),
        react_1.default.createElement(components_1.MBNativeKeyboardSpacer, null)));
};
exports.MBOnboardingInviteContacts = MBOnboardingInviteContacts;
