"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityWidgetItem = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var useEntityWidget_1 = require("../hooks/useEntityWidget");
var DashboardStyles_1 = require("./DashboardStyles");
var styles_2 = require("@shared/components/text/styles");
var react_redux_1 = require("react-redux");
var EntityWidgetOverflowOptions_1 = require("./EntityWidgetOverflowOptions");
var react_native_1 = require("react-native");
var EntityWidgetItem = function (_a) {
    var entityWidget = _a.entityWidget, deleteWidget = _a.deleteWidget, renameWidget = _a.renameWidget, addFavorite = _a.addFavorite, removeFavorite = _a.removeFavorite;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _b = (0, useEntityWidget_1.useEntityWidget)(entityWidget), loading = _b.loading, count = _b.count, shouldShowRefresh = _b.shouldShowRefresh, refresh = _b.refresh, navigate = _b.navigate;
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var _c = (0, react_1.useState)(false), isHovering = _c[0], setIsHovering = _c[1];
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: navigate },
        react_1.default.createElement(components_1.MBHover, { onHoverStart: function () { return setIsHovering(true); }, onHoverEnd: function () { return setIsHovering(false); } },
            react_1.default.createElement(components_1.MBView, { style: [
                    DashboardStyles_1.DashboardStyles.widget,
                    { width: 168, height: 168, backgroundColor: colors.background },
                ] },
                isDesktop && !shouldShowRefresh && (react_1.default.createElement(components_1.MBView, { style: { position: 'absolute', top: (0, components_1.grid)(1), right: (0, components_1.grid)(1), zIndex: 2 } },
                    react_1.default.createElement(EntityWidgetOverflowOptions_1.EntityWidgetOverlowOptions, { entityWidget: entityWidget, deleteWidget: deleteWidget, renameWidget: renameWidget, isButtonVisible: isHovering, addFavorite: addFavorite, removeFavorite: removeFavorite }))),
                isDesktop && !shouldShowRefresh && !isHovering && entityWidget.isFavorite && (react_1.default.createElement(components_1.MBView, { style: { position: 'absolute', top: (0, components_1.grid)(1), right: (0, components_1.grid)(1), zIndex: 1 } },
                    react_1.default.createElement(components_1.MBIconV2, { name: 'star', color: styles_1.Colors.v2.green30, size: (0, components_1.grid)(3) }))),
                !isDesktop && !shouldShowRefresh && !isHovering && entityWidget.isFavorite && (react_1.default.createElement(react_native_1.TouchableOpacity, { style: { position: 'absolute', top: (0, components_1.grid)(1), right: (0, components_1.grid)(1), zIndex: 1 }, onPress: function () { return removeFavorite(entityWidget.id); } },
                    react_1.default.createElement(components_1.MBIconV2, { name: 'star', color: styles_1.Colors.v2.green30, size: (0, components_1.grid)(3) }))),
                !isDesktop && !shouldShowRefresh && !isHovering && !entityWidget.isFavorite && (react_1.default.createElement(react_native_1.TouchableOpacity, { style: { position: 'absolute', top: (0, components_1.grid)(1), right: (0, components_1.grid)(1), zIndex: 1 }, onPress: function () { return addFavorite(entityWidget.id); } },
                    react_1.default.createElement(components_1.MBIconV2, { name: 'star', color: styles_1.Colors.v2.gray10, size: (0, components_1.grid)(3) }))),
                shouldShowRefresh && (react_1.default.createElement(components_1.MBView, { style: { position: 'absolute', top: (0, components_1.grid)(1), right: (0, components_1.grid)(1), zIndex: 3 } },
                    react_1.default.createElement(components_1.MBIconButton, { source: require('@shared/assets/icons/v2/arrow-refresh.png'), size: "small", inverted: true, onPress: function () { return refresh(); }, color: colors.foregroundInactive }))),
                react_1.default.createElement(components_1.MBView, null,
                    !loading && react_1.default.createElement(components_1.MBTextH3, { style: { flexGrow: 0 } }, count),
                    loading && (react_1.default.createElement(components_1.MBView, { style: { alignItems: 'flex-start', height: components_1.MBTypographyStylesRaw.h3.lineHeight } },
                        react_1.default.createElement(components_1.MBLoadingIndicator, null)))),
                react_1.default.createElement(components_1.MBTextSubtitle, { numberOfLines: 3, ellipsizeMode: 'tail', style: [{ marginVertical: (0, components_1.grid)(1) }, styles_2.UnvalidatedStyles.numberOfLines(3)] }, entityWidget.name),
                react_1.default.createElement(components_1.MBView, { style: { flexGrow: 1 } }),
                react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive, style: { flexGrow: 0 } }, entityWidget.channelName)))));
};
exports.EntityWidgetItem = EntityWidgetItem;
