"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeTrackerField = void 0;
var lodash_1 = require("lodash");
var TimeTrackerField = /** @class */ (function () {
    function TimeTrackerField(jsonBlob) {
        this.fieldType = 'TimeTrackerField';
        this.jsonBlob = jsonBlob;
    }
    TimeTrackerField.prototype.validate = function () {
        return null;
    };
    TimeTrackerField.prototype.isEqual = function (field) {
        return field instanceof TimeTrackerField && this.jsonBlob === field.jsonBlob;
    };
    TimeTrackerField.prototype.toFieldJson = function () {
        return this.jsonBlob;
    };
    Object.defineProperty(TimeTrackerField.prototype, "isEmpty", {
        get: function () {
            return (0, lodash_1.isNil)(this.jsonBlob);
        },
        enumerable: false,
        configurable: true
    });
    return TimeTrackerField;
}());
exports.TimeTrackerField = TimeTrackerField;
