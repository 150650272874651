"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = exports.initialState = void 0;
exports.initialState = {
    selectedCards: [],
    active: false,
};
function reducer(state, action) {
    switch (action.type) {
        case 'addCard': {
            var card = action.args;
            var selectedCards = __spreadArray(__spreadArray([], state.selectedCards, true), [card], false);
            return __assign(__assign({}, state), { selectedCards: selectedCards });
        }
        case 'removeCardById':
        case 'removeCard': {
            var cardId_1 = action.args.id;
            var selectedCards = state.selectedCards.filter(function (_a) {
                var id = _a.id;
                return id !== cardId_1;
            });
            return __assign(__assign({}, state), { selectedCards: selectedCards });
        }
        case 'setActive': {
            var active = action.args;
            return __assign(__assign({}, state), { active: active });
        }
        case 'reset': {
            return exports.initialState;
        }
    }
}
exports.reducer = reducer;
