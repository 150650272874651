"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetAllWorkflowTemplateAutomations = void 0;
var react_1 = require("react");
var useGetWorkflowTemplateAutomations_1 = require("./useGetWorkflowTemplateAutomations");
function useGetAllWorkflowTemplateAutomations(workflowTemplateId) {
    var _a = (0, useGetWorkflowTemplateAutomations_1.useGetWorkflowTemplateAutomations)(workflowTemplateId), automations = _a.automations, hasMore = _a.hasMore, fetchMore = _a.fetchMore, loading = _a.loading, moreLoading = _a.moreLoading, error = _a.error;
    (0, react_1.useEffect)(function () {
        if (hasMore && !moreLoading && automations.length > 0) {
            fetchMore();
        }
    }, [fetchMore, hasMore, moreLoading, automations.length]);
    return {
        automations: automations,
        loading: loading || moreLoading,
        error: error,
    };
}
exports.useGetAllWorkflowTemplateAutomations = useGetAllWorkflowTemplateAutomations;
