"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = void 0;
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
var view_1 = require("@shared/components/view");
exports.Styles = react_native_1.StyleSheet.create({
    header: {
        marginLeft: (0, view_1.grid)(2) - 1,
        marginRight: (0, view_1.grid)(2) - 1,
        height: 60,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        flexGrow: 1,
        // @ts-ignore
        cursor: 'pointer',
    },
    isStickyTop: {
        // @ts-ignore
        position: 'sticky',
        top: 74,
        zIndex: 4,
    },
    showBackground: {
        backgroundColor: styles_1.Colors.v2.white,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: styles_1.Colors.v2.gray10,
        borderBottomWidth: 0,
        height: 70,
    },
    headerInner: {
        paddingHorizontal: (0, view_1.grid)(2),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
    },
    isStickyLeft: {
        // @ts-ignore
        position: 'sticky',
        left: -1,
        zIndex: 1,
    },
    isCollapsed: {
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        borderBottomWidth: 1,
    },
    collapseArrow: {
        marginRight: (0, view_1.grid)(2),
    },
});
