"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBGIFSearch = void 0;
// @ts-strict-ignore
var React = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var row_1 = require("./row");
var empty_1 = require("./empty");
var error_1 = require("./error");
var _ = __importStar(require("lodash"));
var Bluebird = __importStar(require("bluebird"));
var styles_1 = require("./styles");
var MBGIFSearch = /** @class */ (function (_super) {
    __extends(MBGIFSearch, _super);
    function MBGIFSearch() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onSearch = function (query) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // Note: we first scroll to top and then do the search query.
                        // When updating data and scrolling at the same time, recyclerlistview
                        // can get confused and not scroll properly to the top.
                        if (!!this.flatlist) {
                            this.flatlist.scrollToTop();
                        }
                        // TODO(fant): super hacky... Delay search query somewhat, otherwise we get
                        // issues with scrolling and updating data at the same time...
                        return [4 /*yield*/, Bluebird.delay(200)];
                    case 1:
                        // TODO(fant): super hacky... Delay search query somewhat, otherwise we get
                        // issues with scrolling and updating data at the same time...
                        _a.sent();
                        return [4 /*yield*/, this.props.onSearch(query)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    MBGIFSearch.prototype.renderContent = function () {
        var _this = this;
        // TODO(fant): use MBLoadingIndicator here when that has been merged
        if (this.props.isLoading) {
            return React.createElement(components_1.MBLoadingIndicator, { fill: true });
        }
        if (this.props.isError) {
            return React.createElement(error_1.MBGIFError, null);
        }
        if (!this.props.gifs.length) {
            return React.createElement(empty_1.MBGIFEmptyResults, null);
        }
        return (React.createElement(components_1.MBFlatList, { ref: function (ref) { return (_this.flatlist = ref); }, style: styles_1.Styles.flatlist, numColumns: 3, data: this.props.gifs, onEndReached: this.props.onLoadMore, keyExtractor: function (gif) { return gif.id; }, onScrollBeginDrag: react_native_1.Keyboard && react_native_1.Keyboard.dismiss, renderItem: function (_a) {
                var gif = _a.item, index = _a.index;
                return (React.createElement(row_1.MBGIFListRow, { key: index, source: { uri: gif.previewUrl }, onPress: function () { return _this.props.onSelect(gif); } }));
            } }));
    };
    MBGIFSearch.prototype.render = function () {
        // For native we add some horizontal padding to the search bar
        // so that it doesn't bleed into the edges of the phone.
        var hasSearchBarHorizontalPadding = react_native_1.Platform.select({
            ios: true,
            android: true,
            web: false,
        });
        return (React.createElement(components_1.MBView, { fill: true },
            React.createElement(components_1.MBView, { paddingVertical: "small", paddingHorizontal: hasSearchBarHorizontalPadding ? 'small' : null },
                React.createElement(components_1.MBSearchBar, { onChangeText: _.debounce(this.onSearch, 200) })),
            React.createElement(components_1.MBView, { flex: true }, this.renderContent())));
    };
    return MBGIFSearch;
}(React.PureComponent));
exports.MBGIFSearch = MBGIFSearch;
