"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLoadMore = void 0;
var react_1 = require("react");
function useLoadMore(fetchMore, data, variables) {
    var _a;
    var _b = (0, react_1.useState)(false), isLoadingMore = _b[0], setIsLoadingMore = _b[1];
    var _c = (0, react_1.useState)(0), currentOffset = _c[0], setCurrentOffset = _c[1];
    var list = (_a = data === null || data === void 0 ? void 0 : data.list) === null || _a === void 0 ? void 0 : _a.entities;
    (0, react_1.useEffect)(function () {
        setCurrentOffset((list === null || list === void 0 ? void 0 : list.length) || 0);
    }, [list === null || list === void 0 ? void 0 : list.length]);
    var loadMore = (0, react_1.useCallback)(function () {
        setIsLoadingMore(true);
        fetchMore({
            variables: __assign(__assign({}, variables), { input: __assign(__assign({}, variables.input), { offset: currentOffset }) }),
        }).finally(function () { return setIsLoadingMore(false); });
    }, [setIsLoadingMore, fetchMore, variables, currentOffset]);
    return { isLoadingMore: isLoadingMore, loadMore: loadMore, currentOffset: currentOffset };
}
exports.useLoadMore = useLoadMore;
