"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRenameEntityWidget = void 0;
var client_1 = require("@apollo/client");
var react_1 = require("react");
var api_1 = require("@shared/api");
function useRenameEntityWidget(dashboard) {
    var updateDashboard = (0, client_1.useMutation)(api_1.Mutations.dashboard.updateDashboard)[0];
    return (0, react_1.useCallback)(function (entityWidget, name) {
        var widgets = dashboard.entityWidgets.map(function (it) {
            return {
                id: it.id,
                channelId: it.channelId,
                workflowTemplateId: it.workflowTemplateId,
                viewTemplateId: it.viewTemplateId,
                name: it.id === entityWidget.id ? name : it.name,
            };
        });
        return updateDashboard({
            variables: {
                input: {
                    businessId: dashboard.businessId,
                    id: dashboard.id,
                    entityWidgets: widgets,
                },
            },
            refetchQueries: [{ query: api_1.Queries.dashboard.listDashboards }],
        });
    }, [dashboard === null || dashboard === void 0 ? void 0 : dashboard.businessId, dashboard === null || dashboard === void 0 ? void 0 : dashboard.entityWidgets, dashboard === null || dashboard === void 0 ? void 0 : dashboard.id, updateDashboard]);
}
exports.useRenameEntityWidget = useRenameEntityWidget;
