"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChannelRow = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var styles_2 = require("./styles");
// Note(fant): not great that this component relies on scenes
var toString_1 = require("@shared/scenes/chat/thread/components/message/system/toString");
// TODO(fant): the typings in this module are incorrect
var emojiRegex = require('emoji-regex');
/*
    Based on a message, return a string summarizing the content.
    This is inspired by native/common/core/getChannelSubText
*/
function toString(message) {
    if (!message) {
        return null;
    }
    if (message.type === 'SYSTEM') {
        return (0, toString_1.toString)(message);
    }
    if (message.type === 'TEXT') {
        // If the message's content is not empty, use that for preview
        if (!!message.content) {
            return message.content;
        }
        // If the message has attachments, show that.
        if (!!message.meta.attachments) {
            // TODO(fant): be smarter here...
            // Different possibilities: GIF, picture,file
            return "".concat(message.sender.username, " uploaded a picture");
        }
    }
    return null;
}
var MBChannelRowSubtitle = function (_a) {
    var channel = _a.channel, subtitle = _a.subtitle, hasUnread = _a.hasUnread;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    if (react_1.default.isValidElement(subtitle)) {
        return subtitle;
    }
    if (!!subtitle) {
        return react_1.default.createElement(components_1.MBTextBody1, { color: colors.foregroundInactive }, subtitle);
    }
    if (!!channel.messages.length) {
        var TypoComponent = hasUnread ? components_1.MBTextBody2Bold : components_1.MBTextBody2;
        var color = hasUnread ? colors.foregroundActive : colors.foregroundInactive;
        return (react_1.default.createElement(TypoComponent, { numberOfLines: 2, color: color }, toString(channel.messages[0])));
    }
    return null;
};
/*
    A simple row showing a channel list item.

*/
var MBChannelRow = function (_a) {
    var channel = _a.channel, nameHighlight = _a.nameHighlight, subtitle = _a.subtitle, _b = _a.showUnreadCount, showUnreadCount = _b === void 0 ? true : _b, otherProps = __rest(_a, ["channel", "nameHighlight", "subtitle", "showUnreadCount"]);
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(components_1.MBListRow, __assign({ isHidingPressableIndicator: true, paddingVertical: 1, image: react_1.default.createElement(components_1.MBView, { fill: true, centerVertically: true },
            react_1.default.createElement(components_1.MBChannelImage, { channel: channel }),
            showUnreadCount && !!channel.unreadCount && (react_1.default.createElement(components_1.MBNotificationBadge, { count: channel.unreadCount, style: styles_2.Styles.notificationBadge }))), title: (function () {
            // Note(fant): if there's an emoji in the name, it will be
            // shown in MBChannelImage
            var nameWithoutEmoji = channel.name.replace(emojiRegex(), '').trim();
            // If we have supplied a range to highlight the name
            // we semi-hackily return a MBHighlightedText instead.
            // The alternative would be to clone this component
            // and have a MBHighlightedChannelRow component, but
            // that would have been more maintenance work than this.
            if (!!nameHighlight) {
                return (react_1.default.createElement(components_1.MBHighlightedText, { color: colors.foregroundActive, numberOfLines: 1, range: nameHighlight }, nameWithoutEmoji));
            }
            var TypoComponent = showUnreadCount && !!channel.unreadCount ? components_1.MBTextBody1Bold : components_1.MBTextBody1;
            var color = !showUnreadCount || !!channel.unreadCount
                ? colors.foregroundActive
                : colors.foregroundInactive;
            return (react_1.default.createElement(TypoComponent, { color: color, numberOfLines: 1 }, nameWithoutEmoji));
        })(), subtitle: react_1.default.createElement(MBChannelRowSubtitle, { channel: channel, subtitle: subtitle, hasUnread: showUnreadCount && !!channel.unreadCount }) }, otherProps)));
};
exports.MBChannelRow = MBChannelRow;
exports.MBChannelRow.displayName = 'MBChannelRow';
