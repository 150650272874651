"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBSelectUsersList = void 0;
var React = __importStar(require("react"));
var _ = __importStar(require("lodash"));
var i18n_1 = require("@shared/util/i18n");
var styles_1 = require("@shared/styles");
var components_1 = require("@shared/components");
var styles_2 = require("./styles");
/*

    TODO(fant): use MBFlatList instead of looping over all users
    <MBFlatList
        data={this.users()}
        keyExtractor={keyExtractor}
        renderItem={({ item: user }: { item: MBAPI.ShortUser }) => (
            <MBSelectableUserRow
                user={user} onPress={onSelect}
                selected={user.id === selectedUserId}
            />
        )
    />
*/
var MBSelectUsersList = /** @class */ (function (_super) {
    __extends(MBSelectUsersList, _super);
    function MBSelectUsersList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = { query: '' };
        _this.onSearch = function (query) { return _this.setState({ query: query }); };
        _this.onClear = function () {
            if (!!_this.props.onSelect) {
                _this.props.onSelect([]);
            }
        };
        _this.onPressUser = function (selectedUser) {
            if (!_this.props.onSelect) {
                return;
            }
            if (_this.props.multiselect) {
                var selected_1 = _.includes(_this.props.selectedIds, selectedUser.id);
                var updatedIds = (function () {
                    if (selected_1) {
                        return _this.props.selectedIds.filter(function (id) { return id !== selectedUser.id; });
                    }
                    return __spreadArray(__spreadArray([], _this.props.selectedIds, true), [selectedUser.id], false);
                })();
                var userById_1 = _.keyBy(_this.props.users, 'id');
                var users = updatedIds.map(function (id) { return userById_1[id]; }).filter(function (user) { return !!user; });
                _this.props.onSelect(users);
            }
            else {
                _this.props.onSelect([selectedUser]);
            }
        };
        _this.onToggleEveryone = function () {
            if (_this.isEveryoneSelected()) {
                _this.onClear();
            }
            else {
                if (!!_this.props.onSelect) {
                    _this.props.onSelect(_this.props.users);
                }
            }
        };
        return _this;
    }
    MBSelectUsersList.prototype.users = function () {
        var query = this.state.query;
        var users = this.props.users;
        if (!query) {
            return users;
        }
        return users.filter(function (user) {
            return _.default(user.fullName.toLowerCase()).includes(query.toLowerCase());
        });
    };
    MBSelectUsersList.prototype.isEveryoneSelected = function () {
        return _.isEqual(this.props.selectedIds.sort(), this.props.users.map(function (u) { return u.id; }).sort());
    };
    MBSelectUsersList.prototype.render = function () {
        var _this = this;
        var _a = this.props, unselectable = _a.unselectable, multiselect = _a.multiselect;
        var users = this.users();
        return (React.createElement(components_1.MBView, { fill: true },
            React.createElement(components_1.MBView, { padding: "small", style: styles_2.Styles.whiteBackground },
                React.createElement(components_1.MBSearchBar, { initialValue: this.state.query, onChangeText: this.onSearch })),
            React.createElement(components_1.MBScrollView, { nativeShouldPersistTaps: true },
                !!unselectable && (React.createElement(components_1.MBSelectableRow, { selected: !this.props.selectedIds.length, onPress: this.onClear, radio: !multiselect, image: React.createElement(components_1.MBBoxedIcon, { backgroundColor: styles_1.Colors.v2.gray30, source: require('@shared/assets/icons/v2/person.png') }), title: React.createElement(components_1.MBText, { bold: true, size: "large" }, (0, i18n_1.i18n)('form.placeholder.unassigned')) })),
                !!multiselect && users.length > 1 && (React.createElement(components_1.MBSelectableRow, { selected: this.isEveryoneSelected(), onPress: this.onToggleEveryone, radio: false, image: React.createElement(components_1.MBUserCollage, { users: users }), title: React.createElement(components_1.MBText, { bold: true, size: "large" }, (0, i18n_1.i18n)('form.placeholder.everyone')) })),
                users.map(function (user) { return (React.createElement(components_1.MBSelectableUserRow, { key: user.id, user: user, radio: !multiselect, onPress: _this.onPressUser, selected: _.includes(_this.props.selectedIds, user.id) })); }))));
    };
    return MBSelectUsersList;
}(React.PureComponent));
exports.MBSelectUsersList = MBSelectUsersList;
