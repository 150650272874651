"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSearchControls = void 0;
var useSearch_1 = require("@shared/components/filter/state/useSearch");
var useSearchControls = function () {
    var _a = (0, useSearch_1.useSearch)(), search = _a.search, setSearch = _a.setSearch;
    var onChange = function (term) {
        setSearch(term);
    };
    return {
        search: search,
        onChange: onChange,
    };
};
exports.useSearchControls = useSearchControls;
