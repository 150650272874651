"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toCreateWorkflowEntity = void 0;
var lodash_1 = require("lodash");
function toCreateWorkflowEntity(entity) {
    var allFields = entity.allFields, workflowTemplateId = entity.workflowTemplateId;
    var fields = {};
    allFields.forEach(function (value, key) {
        fields[key] = (0, lodash_1.isNil)(value) ? null : value.toFieldJson();
    });
    return {
        fields: fields,
        workflowTemplateId: workflowTemplateId,
    };
}
exports.toCreateWorkflowEntity = toCreateWorkflowEntity;
