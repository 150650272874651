"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardListItem = void 0;
var react_1 = __importStar(require("react"));
var styles_1 = require("@shared/styles");
var Utils_1 = require("@shared/data/cards/Utils");
var components_1 = require("@shared/components");
var CardCollectionViewField_1 = require("../../collectionView/components/fields/CardCollectionViewField");
var context_1 = require("@shared/scenes/channel/webLayout/context");
var label_1 = require("@shared/components/blocks/utils/label");
var lodash_1 = require("lodash");
var cardOptions_1 = require("@shared/scenes/cards/collectionView/components/cardOptions");
var react_native_1 = require("react-native");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var cardSelection_1 = require("@shared/api/providers/cardSelection");
var CollectionViewCard_1 = require("../../collectionView/components/CollectionViewCard");
function CardListItem(_a) {
    var viewTemplate = _a.viewTemplate, workflowTemplate = _a.workflowTemplate, openEntity = _a.openEntity, entity = _a.entity, canUpdateCard = _a.canUpdateCard, analytics = _a.analytics, _b = _a.isRedacted, isRedacted = _b === void 0 ? false : _b, _c = _a.hasOverflowMenu, hasOverflowMenu = _c === void 0 ? true : _c;
    var mode = (0, react_1.useContext)(context_1.WebLayoutContext).mode;
    var renderType = mode === 'split-workflow' || mode === 'workflow' ? 'extended' : 'collapsed';
    var isRow = renderType === 'extended';
    var headerCardListFieldStyles = getCardListFieldStyles('header');
    var cardListFieldStyles = getCardListFieldStyles(renderType);
    var componentList = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components.filter(function (component) {
        var _a;
        var viewOptions = (_a = viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.componentsViewOptions) === null || _a === void 0 ? void 0 : _a.find(function (options) { return options.componentId === component.id; });
        var field = entity.fields.get(component.id);
        var needsFieldValue = (0, hooks_1.isInputComponent)(component);
        var isFieldEmpty = (0, lodash_1.isNil)(field) || field.isEmpty;
        var isComponentHidden = (0, Utils_1.isComponentHidden)(viewTemplate, viewOptions);
        return !isComponentHidden && (!isFieldEmpty || !needsFieldValue);
    });
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _d = (0, cardSelection_1.useCardSelection)(entity.id), isCardSelected = _d.isCardSelected, loadingCards = _d.loadingCards, selectionActive = _d.selectionActive;
    var onPress = function () {
        openEntity(entity.id, entity.navigationViewTemplateId);
    };
    var style = {
        paddingHorizontal: (0, components_1.grid)(2),
        paddingVertical: (0, components_1.grid)(0.5),
    };
    return (react_1.default.createElement(components_1.MBView, { flex: true, style: style },
        react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onPress },
            react_1.default.createElement(cardOptions_1.CardSelectionOverlay, { entityId: entity.id, isCardSelectionActive: selectionActive }),
            react_1.default.createElement(CollectionViewCard_1.CollectionViewCard, { isCardSelected: isCardSelected, isRedacted: isRedacted },
                react_1.default.createElement(components_1.MBView, { style: { opacity: isRedacted ? 0.6 : 1.0 } },
                    react_1.default.createElement(components_1.MBView, { paddingRight: 2 },
                        react_1.default.createElement(CardCollectionViewComponent, { component: componentList[0], entity: entity, workflowTemplateId: workflowTemplate.id, fieldStyles: headerCardListFieldStyles, showLabel: isRedacted ? false : !viewTemplate.defaultHiddenLabels, colors: colors, analytics: analytics, canUpdateCard: canUpdateCard, height: (0, components_1.grid)(8), isRedacted: isRedacted, isCollapsed: renderType === 'collapsed' })),
                    react_1.default.createElement(components_1.MBView, { style: isRow ? { flexDirection: 'row', flexWrap: 'wrap' } : {} },
                        !isRedacted &&
                            componentList.slice(1).map(function (component) {
                                return (react_1.default.createElement(CardCollectionViewComponent, { key: component.id, component: component, entity: entity, workflowTemplateId: workflowTemplate.id, fieldStyles: cardListFieldStyles, showLabel: !viewTemplate.defaultHiddenLabels, colors: colors, analytics: analytics, canUpdateCard: canUpdateCard, height: (0, components_1.grid)(8), isRedacted: false, isCollapsed: renderType === 'collapsed' }));
                            }),
                        isRedacted && (react_1.default.createElement(react_native_1.View, { style: {
                                backgroundColor: styles_1.Colors.v2.grayBackground,
                                borderRadius: (0, components_1.grid)(1),
                                height: (0, components_1.grid)(1.5),
                                maxWidth: (0, components_1.grid)(35),
                                width: '100%',
                                marginLeft: (0, components_1.grid)(2),
                                marginBottom: (0, components_1.grid)(2),
                            } }))),
                    !isRedacted && (react_1.default.createElement(react_1.default.Fragment, null,
                        react_1.default.createElement(components_1.MBView, { style: {
                                position: 'absolute',
                                top: (0, components_1.grid)(0.5),
                                right: (0, components_1.grid)(0.5),
                                display: selectionActive ? 'none' : 'flex',
                            } }, hasOverflowMenu && (react_1.default.createElement(cardOptions_1.CardOverflowButton, { entity: entity, workflowTemplate: workflowTemplate, openEntity: function () { return openEntity(entity.id, entity.navigationViewTemplateId); } }))),
                        selectionActive && (react_1.default.createElement(react_native_1.View, { style: {
                                position: 'absolute',
                                top: (0, components_1.grid)(1),
                                right: (0, components_1.grid)(1.5),
                            } },
                            react_1.default.createElement(cardOptions_1.CardSelectionCheckbox, { entityId: entity.id, isLoading: loadingCards.includes(entity.id) }))))))))));
}
exports.CardListItem = CardListItem;
var extendedFieldStyles = {
    addressStyle: { maxWidth: 300 },
    dateStyle: { maxWidth: 300, paddingVertical: (0, components_1.grid)(1) },
    emailStyle: { maxWidth: 300 },
    fileStyle: { maxWidth: 300 },
    geolocationStyle: { maxWidth: 300 },
    numberStyle: { maxWidth: 300 },
    personStyle: { maxWidth: 300 },
    relatedCardStyle: { maxWidth: 300 },
    signatureStyle: { maxWidth: 300 },
    subformStyle: { maxWidth: 300 },
    tagStyle: { maxWidth: 300 },
    textStyle: { maxWidth: 300 },
    timeTrackerStyle: { maxWidth: 300 },
    todoStyle: { maxWidth: 300 },
    inputButtonStyle: { maxWidth: 300 },
};
var collapsedFieldStyles = {
    addressStyle: { maxWidth: '100%', marginBottom: -(0, components_1.grid)(0.5) },
    dateStyle: { maxWidth: '100%' },
    emailStyle: { maxWidth: '100%' },
    fileStyle: { maxWidth: '100%', marginBottom: -(0, components_1.grid)(1) },
    geolocationStyle: { maxWidth: '100%', marginBottom: (0, components_1.grid)(1) },
    numberStyle: { maxWidth: '100%' },
    personStyle: { maxWidth: '100%' },
    relatedCardStyle: { maxWidth: '100%' },
    signatureStyle: { maxWidth: '100%', marginBottom: (0, components_1.grid)(1) },
    subformStyle: { maxWidth: '100%', marginBottom: -(0, components_1.grid)(1) },
    tagStyle: { maxWidth: '100%' },
    textStyle: { maxWidth: '100%' },
    timeTrackerStyle: { maxWidth: '100%' },
    todoStyle: { maxWidth: '100%', marginBottom: -(0, components_1.grid)(1) },
    inputButtonStyle: { maxWidth: '100%' },
};
var headerFieldStyles = {
    addressStyle: { width: '100%' },
    dateStyle: { width: '100%' },
    emailStyle: { width: '100%' },
    fileStyle: { width: '100%' },
    geolocationStyle: { width: '100%' },
    numberStyle: { width: '100%' },
    personStyle: { width: '100%' },
    relatedCardStyle: { width: '100%' },
    signatureStyle: { width: '100%' },
    subformStyle: { width: '100%' },
    tagStyle: { width: '100%' },
    textStyle: { width: '100%' },
    timeTrackerStyle: { width: '100%' },
    todoStyle: { width: '100%' },
    inputButtonStyle: { width: '100%' },
};
function getCardListFieldStyles(type) {
    switch (type) {
        case 'extended':
            return extendedFieldStyles;
        case 'collapsed':
            return collapsedFieldStyles;
        case 'header':
            return headerFieldStyles;
    }
}
function getIcon(component) {
    return 'icon' in component ? component.icon : null;
}
function getLabel(component) {
    if ((0, hooks_1.isInputComponent)(component)) {
        return component.label;
    }
    if ((0, hooks_1.isCombinedTagsComponent)(component)) {
        return component.label;
    }
    if ((0, hooks_1.isInputButtonComponent)(component)) {
        return null;
    }
    return null;
}
function CardCollectionViewComponent(_a) {
    var component = _a.component, entity = _a.entity, workflowTemplateId = _a.workflowTemplateId, fieldStyles = _a.fieldStyles, showLabel = _a.showLabel, colors = _a.colors, analytics = _a.analytics, canUpdateCard = _a.canUpdateCard, height = _a.height, _b = _a.isRedacted, isRedacted = _b === void 0 ? false : _b, _c = _a.isCollapsed, isCollapsed = _c === void 0 ? false : _c;
    var field = entity.fields.get(component.id);
    var componentIconName = getIcon(component);
    var componentLabel = getLabel(component);
    return (react_1.default.createElement(components_1.MBView, { row: true, paddingVertical: showLabel ? 1 : 0.5, paddingBottom: isRedacted || (isCollapsed && !showLabel) ? 0 : 1, paddingHorizontal: 0.75, style: { minHeight: isRedacted || (isCollapsed && !showLabel) ? 0 : height } },
        !(0, lodash_1.isEmpty)(componentIconName) && (react_1.default.createElement(components_1.MBView, { paddingTop: 0.3 },
            react_1.default.createElement(components_1.MBIconV2, { name: componentIconName, size: (0, components_1.grid)(4), color: colors.accent }))),
        react_1.default.createElement(components_1.MBView, { paddingHorizontal: 1, fill: true },
            showLabel && (0, lodash_1.isString)(componentLabel) && !(0, lodash_1.isEmpty)(componentLabel) && (react_1.default.createElement(label_1.MBBlockLabel, { label: componentLabel })),
            react_1.default.createElement(CardCollectionViewField_1.CardCollectionViewField, { field: field, workflowTemplateId: workflowTemplateId, entityId: entity.id, component: component, fieldStyles: fieldStyles, isCollapsed: isCollapsed, showLabel: showLabel, canUpdateCard: canUpdateCard, analytics: analytics }))));
}
