"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createWebSocketLink = void 0;
// @ts-strict-ignore
var subscriptions_1 = require("@apollo/client/link/subscriptions");
var config_1 = require("@shared/config");
var store_1 = require("@shared/store");
var graphql_ws_1 = require("graphql-ws");
var react_native_1 = require("react-native");
function createWebSocketLink(connectivityNotifier) {
    function eventClosedListener() {
        connectivityNotifier.offline('subscriptionClient::disconnected');
    }
    function authenticationConnectionParameters() {
        var authToken = react_native_1.Platform.select({
            web: function () { return localStorage.getItem('authToken'); },
            default: function () { return store_1.store === null || store_1.store === void 0 ? void 0 : store_1.store.getState().appState.userAuthToken; },
        })();
        return {
            authToken: authToken,
            'x-coast-business-id': store_1.store === null || store_1.store === void 0 ? void 0 : store_1.store.getState().appState.currentBusinessId,
        };
    }
    return new subscriptions_1.GraphQLWsLink((0, graphql_ws_1.createClient)({
        url: "".concat(config_1.Config.WEBSOCKET_URL, "/subscriptions"),
        on: {
            closed: eventClosedListener,
        },
        connectionParams: authenticationConnectionParameters,
        shouldRetry: function () { return true; },
        retryAttempts: 10,
    }));
}
exports.createWebSocketLink = createWebSocketLink;
