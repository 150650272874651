"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetUsers = exports.useUpdateWorkflowTemplate = exports.useUpdateSubformComponentWorkflows = exports.useCreateWorkflowTemplate = exports.useListWorkflowTemplates = exports.useGetWorkflowTemplateLazyQuery = exports.useUpdateViewTemplate = exports.useCreateViewTemplate = exports.useDeleteViewTemplate = exports.useViewTemplateWithUITemplate = exports.useViewTemplate = exports.useAllViewTemplates = exports.useViewTemplates = exports.useWorkflowEntity = exports.useWorkflowTemplateWithCardDefinition = exports.useWorkflowTemplate = exports.isAuditComponent = exports.getPrimaryNodeAttribute = exports.hasRelatedCardEntityFilter = exports.hasRelatedCardFilter = exports.hasDefault = exports.hasSelectableDefault = exports.isReferencedInComponent = exports.isTimeTrackerComponent = exports.isInputButtonComponent = exports.isTodoComponent = exports.isTextComponent = exports.isStaticTextComponent = exports.isInputComponent = exports.isTagComponent = exports.isSubformComponent = exports.isSignatureComponent = exports.isRelatedCardLookupComponent = exports.isRelatedCardComponent = exports.isPersonComponent = exports.isNumberComponent = exports.isGeolocationComponent = exports.isFileComponent = exports.isEmailComponent = exports.isDateComponent = exports.isCombinedTagsComponent = exports.isAuditTextComponent = exports.isAuditTagComponent = exports.isAuditCheckboxComponent = exports.isAddressComponent = exports.isDisplayableComponent = void 0;
// @ts-strict-ignore
var client_1 = require("@apollo/client");
var api_1 = require("@shared/api");
var client_2 = require("@shared/api/client");
var workflow_1 = require("@shared/util/workflow");
var lodash_1 = __importStar(require("lodash"));
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var config_1 = require("./config");
function isDisplayableComponent(component) {
    return component.type !== 'INPUT_BUTTON' && component.type !== 'STATIC_TEXT';
}
exports.isDisplayableComponent = isDisplayableComponent;
function isAddressComponent(component) {
    return component.type === 'ADDRESS';
}
exports.isAddressComponent = isAddressComponent;
function isAuditCheckboxComponent(component) {
    return component.type === 'AUDIT_CHECKBOX';
}
exports.isAuditCheckboxComponent = isAuditCheckboxComponent;
function isAuditTagComponent(component) {
    return component.type === 'AUDIT_TAG';
}
exports.isAuditTagComponent = isAuditTagComponent;
function isAuditTextComponent(component) {
    return component.type === 'AUDIT_TEXT';
}
exports.isAuditTextComponent = isAuditTextComponent;
function isCombinedTagsComponent(component) {
    return component.type === 'COMBINED_TAGS';
}
exports.isCombinedTagsComponent = isCombinedTagsComponent;
function isDateComponent(component) {
    return component.type === 'DATE';
}
exports.isDateComponent = isDateComponent;
function isEmailComponent(component) {
    return component.type === 'EMAIL';
}
exports.isEmailComponent = isEmailComponent;
function isFileComponent(component) {
    return component.type === 'FILE';
}
exports.isFileComponent = isFileComponent;
function isGeolocationComponent(component) {
    return component.type === 'GEOLOCATION';
}
exports.isGeolocationComponent = isGeolocationComponent;
function isNumberComponent(component) {
    return component.type === 'NUMBER';
}
exports.isNumberComponent = isNumberComponent;
function isPersonComponent(component) {
    return component.type === 'PERSON';
}
exports.isPersonComponent = isPersonComponent;
function isRelatedCardComponent(component) {
    return (component === null || component === void 0 ? void 0 : component.type) === 'RELATED_CARD';
}
exports.isRelatedCardComponent = isRelatedCardComponent;
function isRelatedCardLookupComponent(component) {
    return (component === null || component === void 0 ? void 0 : component.type) === 'RELATED_CARD_LOOKUP';
}
exports.isRelatedCardLookupComponent = isRelatedCardLookupComponent;
function isSignatureComponent(component) {
    return component.type === 'SIGNATURE';
}
exports.isSignatureComponent = isSignatureComponent;
function isSubformComponent(component) {
    return component.type === 'SUBFORM';
}
exports.isSubformComponent = isSubformComponent;
function isTagComponent(component) {
    return component.type === 'TAG';
}
exports.isTagComponent = isTagComponent;
function isInputComponent(component) {
    return (!isStaticTextComponent(component) &&
        !isInputButtonComponent(component) &&
        !isCombinedTagsComponent(component) &&
        !isReferencedInComponent(component));
}
exports.isInputComponent = isInputComponent;
function isStaticTextComponent(component) {
    return 'STATIC_TEXT' === component.type;
}
exports.isStaticTextComponent = isStaticTextComponent;
function isTextComponent(component) {
    return component.type === 'TEXT';
}
exports.isTextComponent = isTextComponent;
function isTodoComponent(component) {
    return component.type === 'TODO';
}
exports.isTodoComponent = isTodoComponent;
function isInputButtonComponent(component) {
    return component.type === 'INPUT_BUTTON';
}
exports.isInputButtonComponent = isInputButtonComponent;
function isTimeTrackerComponent(component) {
    return component.type === 'TIME_TRACKER';
}
exports.isTimeTrackerComponent = isTimeTrackerComponent;
function isReferencedInComponent(component) {
    return component.type === 'REFERENCED_IN';
}
exports.isReferencedInComponent = isReferencedInComponent;
function hasSelectableDefault(component) {
    return (isPersonComponent(component) ||
        isRelatedCardComponent(component) ||
        isSubformComponent(component) ||
        isTagComponent(component) ||
        isAuditTagComponent(component));
}
exports.hasSelectableDefault = hasSelectableDefault;
function hasDefault(component) {
    return !isEmailComponent(component);
}
exports.hasDefault = hasDefault;
function hasRelatedCardFilter(component) {
    var _a, _b, _c;
    return (!lodash_1.default.isNil((_a = component === null || component === void 0 ? void 0 : component.cardFilter) === null || _a === void 0 ? void 0 : _a.channelId) ||
        !lodash_1.default.isNil((_b = component === null || component === void 0 ? void 0 : component.cardFilter) === null || _b === void 0 ? void 0 : _b.workflowTemplateId) ||
        !lodash_1.default.isNil((_c = component === null || component === void 0 ? void 0 : component.cardFilter) === null || _c === void 0 ? void 0 : _c.nameQuery));
}
exports.hasRelatedCardFilter = hasRelatedCardFilter;
function hasRelatedCardEntityFilter(component) {
    return !lodash_1.default.isNil(component.workflowTemplateId);
}
exports.hasRelatedCardEntityFilter = hasRelatedCardEntityFilter;
function getPrimaryNodeAttribute(component) {
    if (isAuditTagComponent(component)) {
        return 'tags';
    }
    if (isAuditCheckboxComponent(component)) {
        return 'checked';
    }
    if (isAuditTextComponent(component)) {
        return 'text';
    }
}
exports.getPrimaryNodeAttribute = getPrimaryNodeAttribute;
var isAuditComponent = function (component) {
    return (isAuditTagComponent(component) ||
        isAuditCheckboxComponent(component) ||
        isAuditTextComponent(component));
};
exports.isAuditComponent = isAuditComponent;
function useWorkflowTemplate(id) {
    var _a = (0, client_1.useQuery)(api_1.Queries.workflow.getWorkflowTemplate, {
        variables: { id: id },
        skip: lodash_1.default.isNil(id),
    }), data = _a.data, loading = _a.loading, error = _a.error;
    var workflowTemplate = data === null || data === void 0 ? void 0 : data.getWorkflowTemplate;
    return { workflowTemplate: workflowTemplate, loading: loading, error: error };
}
exports.useWorkflowTemplate = useWorkflowTemplate;
function useWorkflowTemplateWithCardDefinition(id) {
    var _a = (0, client_1.useQuery)(api_1.Queries.workflow.getWorkflowTemplateWithCardDefinition, {
        variables: { id: id },
        skip: lodash_1.default.isNil(id),
    }), data = _a.data, loading = _a.loading, error = _a.error;
    var workflowTemplate = data === null || data === void 0 ? void 0 : data.getWorkflowTemplate;
    return { workflowTemplate: workflowTemplate, loading: loading, error: error };
}
exports.useWorkflowTemplateWithCardDefinition = useWorkflowTemplateWithCardDefinition;
function useWorkflowEntity(id) {
    var _a = (0, client_1.useQuery)(api_1.Queries.workflow.getWorkflowEntity, { variables: { input: { id: id } }, skip: lodash_1.default.isNil(id) }), data = _a.data, loading = _a.loading, error = _a.error;
    var workflowEntity = data === null || data === void 0 ? void 0 : data.workflowEntity.entity;
    var workflowTemplate = data === null || data === void 0 ? void 0 : data.workflowEntity.refs.workflowTemplateRefs.find(function (wft) { return wft.id === (workflowEntity === null || workflowEntity === void 0 ? void 0 : workflowEntity.workflowTemplateId); });
    return { workflowEntity: workflowEntity, workflowTemplate: workflowTemplate, loading: loading, error: error };
}
exports.useWorkflowEntity = useWorkflowEntity;
function useViewTemplates(workflowTemplateId, type, subtype, name, isPublic, skip) {
    var _a, _b, _c, _d, _e, _f, _g;
    if (skip === void 0) { skip = false; }
    var _h = (0, react_1.useState)(false), moreLoading = _h[0], setMoreLoading = _h[1];
    var _j = (0, react_1.useState)(false), offsetUpdated = _j[0], setOffsetUpdated = _j[1];
    var queryVariables = {
        input: {
            workflowTemplateId: workflowTemplateId,
            type: type,
            subtype: subtype,
            isPublic: isPublic,
            name: name,
            sort: {
                field: 'CREATED_AT',
                direction: 'ASC',
            },
        },
    };
    var _k = (0, client_1.useQuery)(api_1.Queries.viewTemplate.listViewTemplatesV2, {
        variables: queryVariables,
        skip: skip || lodash_1.default.isNil(workflowTemplateId),
    }), data = _k.data, loading = _k.loading, error = _k.error, fetchMoreTemplates = _k.fetchMore;
    var offset = (0, react_1.useMemo)(function () {
        var _a, _b, _c;
        setOffsetUpdated(true);
        return (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.listViewTemplatesV2) === null || _a === void 0 ? void 0 : _a.viewTemplates) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0;
    }, [(_b = (_a = data === null || data === void 0 ? void 0 : data.listViewTemplatesV2) === null || _a === void 0 ? void 0 : _a.viewTemplates) === null || _b === void 0 ? void 0 : _b.length]);
    var fetchMore = (0, react_1.useCallback)(function () {
        var _a;
        if (((_a = data === null || data === void 0 ? void 0 : data.listViewTemplatesV2) === null || _a === void 0 ? void 0 : _a.hasMore) && !loading && !moreLoading && offsetUpdated) {
            setOffsetUpdated(false);
            setMoreLoading(true);
            fetchMoreTemplates({
                variables: {
                    input: __assign(__assign({}, queryVariables.input), { offset: offset }),
                },
            }).finally(function () {
                setMoreLoading(false);
            });
        }
    }, [
        (_c = data === null || data === void 0 ? void 0 : data.listViewTemplatesV2) === null || _c === void 0 ? void 0 : _c.hasMore,
        fetchMoreTemplates,
        loading,
        moreLoading,
        offset,
        offsetUpdated,
        queryVariables.input,
    ]);
    return {
        viewTemplates: (_e = (_d = data === null || data === void 0 ? void 0 : data.listViewTemplatesV2) === null || _d === void 0 ? void 0 : _d.viewTemplates) !== null && _e !== void 0 ? _e : [],
        hasMore: (_g = (_f = data === null || data === void 0 ? void 0 : data.listViewTemplatesV2) === null || _f === void 0 ? void 0 : _f.hasMore) !== null && _g !== void 0 ? _g : false,
        fetchMore: fetchMore,
        loading: loading,
        moreLoading: moreLoading,
        error: error,
    };
}
exports.useViewTemplates = useViewTemplates;
function useAllViewTemplates(workflowTemplateId, type, subtype, name, isPublic, skip) {
    var _a = useViewTemplates(workflowTemplateId, type, subtype, name, isPublic, skip), viewTemplates = _a.viewTemplates, hasMore = _a.hasMore, fetchMore = _a.fetchMore, loading = _a.loading, moreLoading = _a.moreLoading, error = _a.error;
    (0, react_1.useEffect)(function () {
        if (hasMore && !moreLoading && viewTemplates.length > 0) {
            fetchMore();
        }
    }, [fetchMore, hasMore, moreLoading, viewTemplates.length]);
    return {
        viewTemplates: viewTemplates,
        loading: loading || moreLoading,
        error: error,
    };
}
exports.useAllViewTemplates = useAllViewTemplates;
function useViewTemplate(workflowTemplateId, viewTemplateId) {
    var _a = (0, client_1.useQuery)(api_1.Queries.viewTemplate.getViewTemplate, {
        variables: { input: { workflowTemplateId: workflowTemplateId, id: viewTemplateId } },
        skip: lodash_1.default.isNil(workflowTemplateId) || lodash_1.default.isEmpty(viewTemplateId),
    }), data = _a.data, loading = _a.loading, error = _a.error;
    return {
        viewTemplate: data === null || data === void 0 ? void 0 : data.getViewTemplate,
        loading: loading,
        error: error,
    };
}
exports.useViewTemplate = useViewTemplate;
function useViewTemplateWithUITemplate(workflowTemplateId, viewTemplateId) {
    var _a = (0, client_1.useQuery)(api_1.Queries.viewTemplate.getViewTemplateWithUITemplate, {
        variables: { input: { workflowTemplateId: workflowTemplateId, id: viewTemplateId } },
        skip: lodash_1.default.isNil(workflowTemplateId) || lodash_1.default.isNil(viewTemplateId),
    }), data = _a.data, loading = _a.loading, error = _a.error;
    return {
        viewTemplate: data === null || data === void 0 ? void 0 : data.getViewTemplate,
        loading: loading,
        error: error,
    };
}
exports.useViewTemplateWithUITemplate = useViewTemplateWithUITemplate;
function useDeleteViewTemplate(workflowTemplateId) {
    var apollo = (0, client_1.useApolloClient)();
    return (0, react_1.useCallback)(function (viewTemplateId) {
        return apollo.mutate(__assign({ mutation: api_1.Mutations.viewTemplate.deleteViewTemplate, variables: {
                input: {
                    workflowTemplateId: workflowTemplateId,
                    id: viewTemplateId,
                },
            } }, refetchAndInvalidateQueriesAfterMutatingViewTemplates(workflowTemplateId)));
    }, [apollo, workflowTemplateId]);
}
exports.useDeleteViewTemplate = useDeleteViewTemplate;
function useCreateViewTemplate(workflowTemplateId) {
    var apollo = (0, client_1.useApolloClient)();
    return (0, react_1.useCallback)(function (viewTemplate) {
        var includeGroupByComponentId = (0, config_1.subtypeRequiresGrouping)(viewTemplate.subtype);
        return apollo.mutate(__assign({ mutation: api_1.Mutations.viewTemplate.createViewTemplate, variables: {
                input: __assign(__assign({}, viewTemplate), { groupByComponentId: includeGroupByComponentId ? viewTemplate.groupByComponentId : null, workflowTemplateId: workflowTemplateId }),
            } }, refetchAndInvalidateQueriesAfterMutatingViewTemplates(workflowTemplateId)));
    }, [apollo, workflowTemplateId]);
}
exports.useCreateViewTemplate = useCreateViewTemplate;
function useUpdateViewTemplate(workflowTemplateId) {
    var apollo = (0, client_1.useApolloClient)();
    return (0, react_1.useCallback)(function (viewTemplate) {
        var viewTemplateWithoutUITemplate = lodash_1.default.omit(viewTemplate, 'cardUITemplate');
        return apollo.mutate(__assign({ mutation: api_1.Mutations.viewTemplate.updateViewTemplate, variables: {
                input: __assign(__assign({}, viewTemplateWithoutUITemplate), { groupByComponentId: (0, config_1.subtypeRequiresGrouping)(viewTemplate.subtype)
                        ? viewTemplate.groupByComponentId
                        : null, componentsViewOptions: viewTemplate.componentsViewOptions.map(function (viewOption) {
                        return {
                            componentId: viewOption.componentId,
                            isHidden: viewOption.isHidden,
                            overrides: (0, workflow_1.toComponentOneOf)(viewOption.overrides),
                        };
                    }), workflowTemplateId: workflowTemplateId }),
            } }, refetchAndInvalidateQueriesAfterMutatingViewTemplates(workflowTemplateId)));
    }, [apollo, workflowTemplateId]);
}
exports.useUpdateViewTemplate = useUpdateViewTemplate;
function useGetWorkflowTemplateLazyQuery() {
    var _this = this;
    var getWorkflowTemplate = (0, client_1.useLazyQuery)(api_1.Queries.workflow.getWorkflowTemplateWithCardDefinition)[0];
    return (0, react_1.useCallback)(function (id) { return __awaiter(_this, void 0, void 0, function () {
        var variables, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!!lodash_1.default.isNil(id)) return [3 /*break*/, 2];
                    variables = { id: id };
                    return [4 /*yield*/, getWorkflowTemplate({ variables: variables })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, result.data.getWorkflowTemplate];
                case 2: return [2 /*return*/];
            }
        });
    }); }, [getWorkflowTemplate]);
}
exports.useGetWorkflowTemplateLazyQuery = useGetWorkflowTemplateLazyQuery;
function useListWorkflowTemplates(isSubform, fetchPolicy, ids) {
    if (fetchPolicy === void 0) { fetchPolicy = 'network-only'; }
    if (ids === void 0) { ids = null; }
    var _a = (0, client_1.useQuery)(api_1.Queries.workflow.listWorkflowTemplates, {
        variables: { input: { isPublic: false, isSubform: isSubform, ids: ids } },
        fetchPolicy: fetchPolicy,
    }), data = _a.data, loading = _a.loading, error = _a.error;
    var workflowTemplates = data === null || data === void 0 ? void 0 : data.listWorkflowTemplates;
    return (0, react_1.useMemo)(function () { return ({ workflowTemplates: workflowTemplates, loading: loading, error: error }); }, [workflowTemplates, loading, error]);
}
exports.useListWorkflowTemplates = useListWorkflowTemplates;
var useCreateWorkflowTemplate = function (isSubform) {
    var _a = (0, client_1.useMutation)(api_1.Mutations.workflow.createWorkflow), createWorkflow = _a[0], _b = _a[1], loading = _b.loading, error = _b.error;
    return [
        (0, react_1.useCallback)(function (workflowTemplate) { return __awaiter(void 0, void 0, void 0, function () {
            var result;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, createWorkflow({
                            variables: {
                                input: {
                                    name: workflowTemplate.name,
                                    requiresName: workflowTemplate.requiresName,
                                    isSubform: isSubform,
                                    components: workflowTemplate.components.map(workflow_1.toComponentOneOf),
                                },
                            },
                            refetchQueries: [
                                {
                                    query: api_1.Queries.workflow.listWorkflowTemplates,
                                    variables: { input: { isPublic: false, isSubform: true } },
                                },
                            ],
                            awaitRefetchQueries: true,
                        })];
                    case 1:
                        result = _b.sent();
                        return [2 /*return*/, (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.createWorkflowTemplate];
                }
            });
        }); }, [createWorkflow, isSubform]),
        { loading: loading, error: error },
    ];
};
exports.useCreateWorkflowTemplate = useCreateWorkflowTemplate;
var useUpdateSubformComponentWorkflows = function (workflowTemplateId, subformComponentId) {
    var workflowTemplate = useWorkflowTemplate(workflowTemplateId).workflowTemplate;
    var updateWorkflow = (0, exports.useUpdateWorkflowTemplate)()[0];
    return (0, react_1.useCallback)(function (newWorkflowTemplateId) { return __awaiter(void 0, void 0, void 0, function () {
        var updateWorkflowTemplate;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    updateWorkflowTemplate = lodash_1.default.cloneDeep(workflowTemplate);
                    addWorkflowToSubformComponent(updateWorkflowTemplate, subformComponentId, newWorkflowTemplateId);
                    return [4 /*yield*/, updateWorkflow(updateWorkflowTemplate)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, 
    // Because of cloneDeep, if we add updateWorkflowTemplate here
    // we get an infinite loop
    [subformComponentId, updateWorkflow, workflowTemplate]);
};
exports.useUpdateSubformComponentWorkflows = useUpdateSubformComponentWorkflows;
var addWorkflowToSubformComponent = function (workflowTemplate, subformComponentId, newWorkflowTemplateId) {
    for (var _i = 0, _a = workflowTemplate.components; _i < _a.length; _i++) {
        var component = _a[_i];
        if (isSubformComponent(component) && subformComponentId === component.id) {
            component.workflowTemplateIds = component.workflowTemplateIds.concat(newWorkflowTemplateId);
            break;
        }
    }
};
var useUpdateWorkflowTemplate = function (params) {
    var _a = (0, client_1.useMutation)(api_1.Mutations.workflow.updateWorkflow), updateWorkflow = _a[0], _b = _a[1], loading = _b.loading, error = _b.error;
    var refetchQueries = (0, react_1.useMemo)(function () {
        return lodash_1.default.isNil(params === null || params === void 0 ? void 0 : params.channelId)
            ? []
            : [
                {
                    query: api_1.Queries.card.listCardFieldStringTags,
                    variables: { input: { channelId: params.channelId } },
                },
            ];
    }, [params === null || params === void 0 ? void 0 : params.channelId]);
    return [
        (0, react_1.useCallback)(function (workflowTemplate) { return __awaiter(void 0, void 0, void 0, function () {
            var cacheId, cachedData;
            var _a;
            return __generator(this, function (_b) {
                cacheId = "WorkflowTemplate".concat(workflowTemplate.id);
                cachedData = client_2.apolloClient.readFragment({
                    id: cacheId,
                    fragment: api_1.Fragments.workflow.WorkflowTemplateWithCardDefinition,
                    fragmentName: 'WorkflowTemplateWithCardDefinition',
                });
                return [2 /*return*/, updateWorkflow({
                        variables: {
                            input: {
                                id: workflowTemplate.id,
                                name: workflowTemplate.name,
                                requiresName: workflowTemplate.requiresName,
                                components: workflowTemplate.components.map(workflow_1.toComponentOneOf),
                                archivedComponents: (_a = workflowTemplate.archivedComponents) === null || _a === void 0 ? void 0 : _a.map(workflow_1.toComponentOneOf),
                            },
                        },
                        optimisticResponse: {
                            updateWorkflowTemplate: __assign(__assign({}, cachedData), workflowTemplate),
                        },
                        refetchQueries: refetchQueries,
                        awaitRefetchQueries: true,
                    })];
            });
        }); }, [updateWorkflow, refetchQueries]),
        { loading: loading, error: error },
    ];
};
exports.useUpdateWorkflowTemplate = useUpdateWorkflowTemplate;
function useGetUsers(workflowTemplateId) {
    var _a = (0, react_1.useState)([]), users = _a[0], setUsers = _a[1];
    var businessId = (0, react_redux_1.useSelector)(function (store) { return store.appState.currentBusinessId; });
    (0, client_1.useQuery)(api_1.Queries.channel.list, {
        variables: { businessId: businessId },
        onCompleted: function (data) {
            data.me.business.channels.forEach(function (item) {
                if (item.workflowTemplateId === workflowTemplateId) {
                    var mappedUsers = item.users.map(function (user) {
                        return {
                            id: user.id,
                            fullName: user.fullName,
                            userName: user.username,
                            imageUrl: user.imageUrl,
                        };
                    });
                    setUsers(mappedUsers);
                }
            });
        },
        skip: (0, lodash_1.isNil)(businessId),
    });
    return users;
}
exports.useGetUsers = useGetUsers;
function refetchAndInvalidateQueriesAfterMutatingViewTemplates(workflowTemplateId) {
    return {
        refetchQueries: [
            {
                query: api_1.Queries.workflow.getWorkflowTemplate,
                variables: { id: workflowTemplateId },
            },
            api_1.Queries.viewTemplate.getViewTemplate,
        ],
        update: function (cache) {
            cache.modify({
                fields: function (fieldValue, _a) {
                    var fieldName = _a.fieldName, storeFieldName = _a.storeFieldName, DELETE = _a.DELETE;
                    // This is a workaround because we want to pass `listViewTemplatesV2` to `refetchQueries` but there are no
                    // active `listViewTemplatesV2` queries in some places of the app where we allow the user to modify view
                    // templates. We explicitly do not cache view templates independently, so the contents of the view templates
                    // are cached in the query responses and needs to be managed.
                    return fieldName === 'listViewTemplatesV2' &&
                        (lodash_1.default.isNil(workflowTemplateId) ||
                            storeFieldName.includes("\"workflowTemplateId\":\"".concat(workflowTemplateId, "\"")))
                        ? DELETE
                        : fieldValue;
                },
            });
        },
    };
}
