"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBMessageBubbleTheme = exports.Styles = exports.UnvalidatedStyles = void 0;
// @ts-strict-ignore
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
exports.UnvalidatedStyles = {
    reactionPickerBottomMargin: 40,
    senderImageSize: 40,
    senderImageMarginRight: 6,
};
exports.Styles = react_native_1.StyleSheet.create({
    // TODO(fant): move these super small paddings to MBView
    /*
      smallPaddingTop: { paddingTop: 5 },
      smallPaddingBottom: { paddingBottom: 5 },
      smallPaddingHorizontal: { paddingHorizontal: 5 },
      //*/
    smallMarginTop: { marginTop: 5 },
    alignRight: { alignItems: 'flex-end' },
    justifyRight: { justifyContent: 'flex-end' },
    bubbleMaxWidth: { maxWidth: '75%' },
    semitransparent: { opacity: 0.5 },
    rowAlignRight: {
        width: '100%',
        flexDirection: 'row-reverse',
        alignItems: 'center',
    },
    rowAlignLeft: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
    },
    flex: { flex: 1 },
    deliveryStatus: {
        flex: 1,
        alignSelf: 'flex-end',
    },
    messageBubble: {
        // Note: same border radius as MBLinkPreview
        // TODO(fant): there seems to be a bug with react-native-web
        // where borderRadius sometimes overrides border{Direction}Radius
        borderTopLeftRadius: 24,
        borderTopRightRadius: 24,
        borderBottomLeftRadius: 24,
        borderBottomRightRadius: 24,
        minHeight: exports.UnvalidatedStyles.senderImageSize,
        justifyContent: 'center',
    },
    messageBubbleContent: {
        paddingVertical: 8,
        paddingHorizontal: 14,
    },
    // prevent the nested text inside MBFormattedText from overflowing
    maxWidth: { maxWidth: '100%' },
    userImage: {
        width: exports.UnvalidatedStyles.senderImageSize,
        height: exports.UnvalidatedStyles.senderImageSize,
        borderRadius: exports.UnvalidatedStyles.senderImageSize / 2,
        overflow: 'hidden',
        alignSelf: 'flex-end',
        marginRight: exports.UnvalidatedStyles.senderImageMarginRight,
    },
    userImagePlaceholder: {
        width: exports.UnvalidatedStyles.senderImageSize + exports.UnvalidatedStyles.senderImageMarginRight,
    },
    // Border radius to make link preview (with its border) fit with the
    // message bubble's border. Note: this might be overriden by
    // meMessageBubbleGroupedWithBelow/otherUserMessageBubbleGroupedWithBelow
    linkPreviewBorderRadius: {
        overflow: 'hidden',
        borderBottomLeftRadius: 24,
        borderBottomRightRadius: 24,
        borderRadius: 0,
    },
    // only small border radius when grouped
    smallTopRightBorderRadius: { borderTopRightRadius: 4 },
    smallBottomRightBorderRadius: { borderBottomRightRadius: 4 },
    // only small obrder radius when grouped
    smallTopLeftBorderRadius: { borderTopLeftRadius: 4 },
    smallBottomLeftBorderRadius: { borderBottomLeftRadius: 4 },
});
var me = {
    backgroundColor: styles_1.Colors.v2.green10,
    // Note(fant): textColor and highlightColor aren't used by web
    textColor: styles_1.Colors.v2.greenSecondary90,
    highlightColor: styles_1.Colors.v2.greenSecondary90,
    align: 'right',
    showImage: false,
    isDM: false,
    isEditing: false,
};
var other = {
    backgroundColor: styles_1.Colors.gray.touch,
    textColor: styles_1.Colors.v2.purple,
    highlightColor: styles_1.Colors.v2.purple,
    align: 'left',
    showImage: true,
    isDM: false,
    isEditing: false,
};
var dm = { isDM: true };
var edit = {
    isEditing: true,
    backgroundColor: styles_1.Colors.v2.green10,
};
var meSelected = __assign(__assign({}, me), { blinkBackgroundColor: styles_1.Colors.v2.green30 });
var otherSelected = __assign(__assign({}, other), { blinkBackgroundColor: styles_1.Colors.v2.gray10 });
exports.MBMessageBubbleTheme = {
    me: me,
    other: other,
    meSelected: meSelected,
    otherSelected: otherSelected,
    meDM: __assign(__assign({}, me), dm),
    otherDM: __assign(__assign({}, other), dm),
    meSelectedDM: __assign(__assign({}, meSelected), dm),
    otherSelectedDM: __assign(__assign({}, otherSelected), dm),
    edit: __assign(__assign({}, me), edit),
    editDM: __assign(__assign(__assign({}, me), edit), dm),
    get: getTheme,
};
function getTheme(props) {
    if (props.me) {
        return getMeTheme(props);
    }
    else {
        return getOtherTheme(props);
    }
}
function getMeTheme(props) {
    // ME and DM
    if (props.dm && props.editing) {
        return exports.MBMessageBubbleTheme.editDM;
    }
    if (props.dm && props.selected) {
        return exports.MBMessageBubbleTheme.meSelectedDM;
    }
    if (props.dm && !props.selected) {
        return exports.MBMessageBubbleTheme.meDM;
    }
    // ME and !DM
    if (!props.dm && props.editing) {
        return exports.MBMessageBubbleTheme.edit;
    }
    if (!props.dm && props.selected) {
        return exports.MBMessageBubbleTheme.meSelected;
    }
    if (!props.dm && !props.selected) {
        return exports.MBMessageBubbleTheme.me;
    }
}
function getOtherTheme(props) {
    // !ME and DM
    if (props.dm && props.selected) {
        return exports.MBMessageBubbleTheme.otherSelectedDM;
    }
    if (props.dm && !props.selected) {
        return exports.MBMessageBubbleTheme.otherDM;
    }
    // !ME and !DM
    if (!props.dm && props.selected) {
        return exports.MBMessageBubbleTheme.otherSelected;
    }
    if (!props.dm && !props.selected) {
        return exports.MBMessageBubbleTheme.other;
    }
}
