"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBEditUser = void 0;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var client_1 = require("@apollo/client");
var EmailValidator = __importStar(require("email-validator"));
var libphonenumber_js_1 = require("libphonenumber-js");
var i18n_1 = require("@shared/util/i18n");
var alert_1 = require("@shared/util/alert");
var components_1 = require("@shared/components");
var formField_1 = require("@shared/components/form/formField");
var hooks_1 = require("@shared/util/navigation/hooks");
var styles_1 = require("@shared/styles");
var hooks_2 = require("@shared/util/analytics/hooks");
var useLogout_1 = require("@shared/scenes/navigation/menu/containers/useLogout");
var storage_1 = require("@shared/util/storage");
var context_1 = require("@shared/scenes/auth/signin/context");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var styles = react_native_1.StyleSheet.create({
    capWidth: {
        width: '100%',
        maxWidth: 320,
        alignSelf: 'center',
    },
});
function MBEditUser(_a) {
    var _this = this;
    var user = _a.user, footer = _a.footer, _b = _a.autoFocus, autoFocus = _b === void 0 ? false : _b, _c = _a.showHeaderBackground, showHeaderBackground = _c === void 0 ? true : _c, _d = _a.requireEmailAndPhoneNumber, requireEmailAndPhoneNumber = _d === void 0 ? false : _d, _e = _a.disableImageUpload, disableImageUpload = _e === void 0 ? false : _e, _f = _a.cta, cta = _f === void 0 ? (0, i18n_1.i18n)('common.saveChanges') : _f, onUpdate = _a.onUpdate, onStartSelectImage = _a.onStartSelectImage, onSelectedImage = _a.onSelectedImage, onUploadedImage = _a.onUploadedImage;
    var defaultEmail = user.email || '';
    var defaultPhoneNumber = user.phoneNumber || '';
    var _g = (0, react_1.useState)(user.fullName), name = _g[0], setName = _g[1];
    var _h = (0, react_1.useState)(user.imageUrl), imageUrl = _h[0], setImageUrl = _h[1];
    var _j = (0, react_1.useState)(defaultEmail), email = _j[0], setEmail = _j[1];
    var _k = (0, react_1.useState)(defaultPhoneNumber), phoneNumber = _k[0], setPhoneNumber = _k[1];
    var phoneNumberInput = (0, react_1.useRef)(null);
    var emailInput = (0, react_1.useRef)(null);
    // Need to update the imageUrl when it changes, otherwise "edited"
    // below will always be true after having uploaded an image. The reason
    // is because the imageUrl the GraphQL layer returns is from imgix
    (0, react_1.useEffect)(function () { return setImageUrl(user.imageUrl); }, [user.imageUrl]);
    var handleUploadedImage = (0, react_1.useCallback)(function (updatedImageUrl) {
        setImageUrl(updatedImageUrl);
        onUploadedImage === null || onUploadedImage === void 0 ? void 0 : onUploadedImage();
    }, [onUploadedImage]);
    var _l = (0, react_1.useState)(), nameErrorMessage = _l[0], setNameErrorMessage = _l[1];
    var _m = (0, react_1.useState)(), emailErrorMessage = _m[0], setEmailErrorMessage = _m[1];
    var _o = (0, react_1.useState)(), phoneNumberErrorMessage = _o[0], setPhoneNumberErrorMessage = _o[1];
    // Clear error message when the value changes
    (0, react_1.useEffect)(function () { return setNameErrorMessage(undefined); }, [name]);
    (0, react_1.useEffect)(function () { return setEmailErrorMessage(undefined); }, [email]);
    (0, react_1.useEffect)(function () { return setPhoneNumberErrorMessage(undefined); }, [phoneNumber]);
    var logout = (0, useLogout_1.useLogout)();
    var navigateToSignIn = (0, useNavigator_1.useNavigateTo)('LoginExistingAccount');
    var edited = name !== user.fullName ||
        imageUrl !== user.imageUrl ||
        email !== defaultEmail ||
        // The GraphQL layer formats the phone number in a pretty way.
        // We want to disregard that formatting when comparing phone numbers.
        phoneNumber.replace(/ /g, '') !== defaultPhoneNumber.replace(/ /g, '');
    (0, hooks_1.useUnsavedChangesNavigationWarning)(edited);
    var trackFocusName = (0, hooks_2.useTrackEvent)('Profile::NameInput::Focus', { name: name });
    var trackBlurName = (0, hooks_2.useTrackEvent)('Profile::NameInput::Blur', { name: name });
    var trackFocusPhone = (0, hooks_2.useTrackEvent)('Profile::PhoneNumberInput::Focus', { phoneNumber: phoneNumber });
    var trackBlurPhone = (0, hooks_2.useTrackEvent)('Profile::PhoneNumberInput::Blur', { phoneNumber: phoneNumber });
    var trackFocusEmail = (0, hooks_2.useTrackEvent)('Profile::EmailInput::Focus', { email: email });
    var trackBlurEmail = (0, hooks_2.useTrackEvent)('Profile::EmailInput::Blur', { email: email });
    var handleSubmit = (0, react_1.useCallback)(function () { return __awaiter(_this, void 0, void 0, function () {
        var isEmailInvalid, isPhoneNumberInvalid, needsEmail, needsPhoneNumber, error_1, codes;
        var _this = this;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    isEmailInvalid = !!email && !EmailValidator.validate(email);
                    isPhoneNumberInvalid = (function () {
                        if (!phoneNumber) {
                            return false;
                        }
                        var parsed = (0, libphonenumber_js_1.parsePhoneNumberFromString)(phoneNumber, 'US');
                        return !(parsed === null || parsed === void 0 ? void 0 : parsed.isValid());
                    })();
                    needsEmail = requireEmailAndPhoneNumber || !phoneNumber;
                    needsPhoneNumber = requireEmailAndPhoneNumber || !email;
                    if (!name) {
                        setNameErrorMessage((0, i18n_1.i18n)('editProfile.name.errorMessage'));
                    }
                    if (!!email && isEmailInvalid) {
                        setEmailErrorMessage((0, i18n_1.i18n)('editProfile.email.errorMessage'));
                    }
                    if (!!phoneNumber && isPhoneNumberInvalid) {
                        setPhoneNumberErrorMessage((0, i18n_1.i18n)('editProfile.phoneNumber.errorMessage'));
                    }
                    if (!email && needsEmail) {
                        setEmailErrorMessage((0, i18n_1.i18n)('editProfile.email.errorMessage'));
                    }
                    if (!phoneNumber && needsPhoneNumber) {
                        setPhoneNumberErrorMessage((0, i18n_1.i18n)('editProfile.phoneNumber.errorMessage'));
                    }
                    if (!(!!name &&
                        (!needsEmail || !!email) &&
                        (!needsPhoneNumber || !!phoneNumber) &&
                        !isEmailInvalid &&
                        !isPhoneNumberInvalid)) return [3 /*break*/, 4];
                    react_native_1.Keyboard.dismiss();
                    setNameErrorMessage(undefined);
                    setEmailErrorMessage(undefined);
                    setPhoneNumberErrorMessage(undefined);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, onUpdate({ name: name, imageUrl: imageUrl, email: email, phoneNumber: phoneNumber })];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _b.sent();
                    if (error_1 instanceof client_1.ApolloError) {
                        codes = (_a = error_1.graphQLErrors) === null || _a === void 0 ? void 0 : _a.map(function (err) { var _a; return (_a = err.extensions) === null || _a === void 0 ? void 0 : _a.code; });
                        if (codes.includes('USER_DUPLICATE_EMAIL')) {
                            setEmailErrorMessage((0, i18n_1.i18n)('editProfile.email.errorMessageDuplicate'));
                            return [2 /*return*/];
                        }
                        if (codes.includes('USER_DUPLICATE_PHONE_NUMBER')) {
                            alert_1.MBAlert.alert({
                                title: (0, i18n_1.i18n)('editProfile.phoneNumber.errorMessageDuplicate'),
                                message: (0, i18n_1.i18n)('editProfile.phoneNumber.errorMessage'),
                                confirmText: (0, i18n_1.i18n)('common.continue'),
                                onConfirm: function () { return __awaiter(_this, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, logout(false)];
                                            case 1:
                                                _a.sent();
                                                // This is seeding the contact data in the PersistentSignInProvider so it is found when the signin page is shown
                                                return [4 /*yield*/, storage_1.MBStorage.set(context_1.contactKey, phoneNumber)];
                                            case 2:
                                                // This is seeding the contact data in the PersistentSignInProvider so it is found when the signin page is shown
                                                _a.sent();
                                                navigateToSignIn();
                                                return [2 /*return*/];
                                        }
                                    });
                                }); },
                            });
                            return [2 /*return*/];
                        }
                    }
                    alert_1.MBAlert.alert({ message: (0, i18n_1.i18n)('editProfile.errorMessage') });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [
        onUpdate,
        name,
        imageUrl,
        email,
        phoneNumber,
        requireEmailAndPhoneNumber,
        navigateToSignIn,
        logout,
    ]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.MBScrollView, { nativeShouldPersistTaps: true, nativeKeyboardDismissMode: "on-drag", webContentStyle: { minHeight: '100%' }, nativeContentContainerStyle: { minHeight: '100%' } },
            react_1.default.createElement(components_1.MBView, { center: true, paddingVertical: 1, style: !!showHeaderBackground && { backgroundColor: styles_1.Colors.v2.green05 } },
                react_1.default.createElement(components_1.MBSelectAvatarImage, { imageUrl: imageUrl, description: (0, i18n_1.i18n)('editProfile.addProfilePhoto'), onUploadedImage: !disableImageUpload ? handleUploadedImage : undefined, onStartSelectImage: onStartSelectImage, onSelectedImage: onSelectedImage, placeholder: react_1.default.createElement(components_1.MBImage, { fill: true, source: require('@shared/assets/images/v2/placeholder-edit-profile.png') }) })),
            react_1.default.createElement(components_1.MBView, { paddingHorizontal: 2 },
                react_1.default.createElement(formField_1.MBFormField, { title: (0, i18n_1.i18n)('editProfile.name.title'), error: nameErrorMessage, required: true, style: styles.capWidth, children: function (props) {
                        var _a;
                        return (react_1.default.createElement(components_1.MBTextInput, __assign({}, props, { autoCapitalize: "words", autoFocus: autoFocus, value: name, returnKeyType: "next", textContentType: "name", blurOnSubmit: false, placeholder: (0, i18n_1.i18n)('editProfile.name.placeholder'), onFocus: trackFocusName, onBlur: trackBlurName, onChangeText: setName, onSubmitEditing: (_a = phoneNumberInput.current) === null || _a === void 0 ? void 0 : _a.focus })));
                    } }),
                react_1.default.createElement(formField_1.MBFormField, { title: (0, i18n_1.i18n)('editProfile.phoneNumber.title'), error: phoneNumberErrorMessage, required: requireEmailAndPhoneNumber, style: styles.capWidth, children: function (props) {
                        var _a;
                        return (react_1.default.createElement(components_1.MBTextInput, __assign({}, props, { ref: phoneNumberInput, value: phoneNumber, returnKeyType: "next", textContentType: "telephoneNumber", blurOnSubmit: false, keyboardType: "phone-pad", placeholder: (0, i18n_1.i18n)('editProfile.phoneNumber.placeholder'), onFocus: trackFocusPhone, onBlur: trackBlurPhone, onChangeText: setPhoneNumber, onSubmitEditing: (_a = emailInput.current) === null || _a === void 0 ? void 0 : _a.focus })));
                    } }),
                react_1.default.createElement(formField_1.MBFormField, { title: (0, i18n_1.i18n)('editProfile.email.title'), error: emailErrorMessage, required: requireEmailAndPhoneNumber, style: styles.capWidth, children: function (props) { return (react_1.default.createElement(components_1.MBTextInput, __assign({}, props, { autoCorrect: false, autoCapitalize: "none", ref: emailInput, value: email, returnKeyType: "next", textContentType: "emailAddress", keyboardType: "email-address", placeholder: (0, i18n_1.i18n)('editProfile.email.placeholder'), onFocus: trackFocusEmail, onBlur: trackBlurEmail, onChangeText: setEmail, onSubmitEditing: handleSubmit }))); } }),
                react_1.default.createElement(components_1.MBView, { paddingTop: 5 },
                    react_1.default.createElement(components_1.MBButton, { style: styles.capWidth, title: cta, attributionId: "profile.submit", onPress: handleSubmit }))),
            react_1.default.createElement(components_1.MBView, { flex: true }),
            react_1.default.createElement(components_1.MBView, { padding: 2, paddingTop: 5 }, footer)),
        react_1.default.createElement(components_1.MBNativeKeyboardSpacer, null)));
}
exports.MBEditUser = MBEditUser;
