"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RenameSectionForm = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var hooks_1 = require("../hooks");
var i18n_1 = require("@shared/util/i18n");
var toast_1 = require("@shared/components/toast");
var RenameSectionForm = function (_a) {
    var section = _a.section, setRenameSheetVisible = _a.setRenameSheetVisible;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var updateWorkspaceSection = (0, hooks_1.useUpdateWorkspaceSection)();
    var _b = (0, react_1.useState)(section.title), value = _b[0], setValue = _b[1];
    var _c = (0, react_1.useState)(false), loading = _c[0], setLoading = _c[1];
    var _d = (0, react_1.useState)(false), networkError = _d[0], setNetworkError = _d[1];
    var toast = (0, react_1.useContext)(toast_1.ToastContext);
    var title = value.trim();
    var isEmpty = title === '';
    var isSameTitle = title === section.title;
    var isTooLong = value.length > 64;
    var isInvalid = isEmpty || isTooLong;
    function close() {
        setRenameSheetVisible(false);
    }
    function save() {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (isTooLong || isEmpty) {
                            return [2 /*return*/];
                        }
                        if (isSameTitle) {
                            setRenameSheetVisible(false);
                            return [2 /*return*/];
                        }
                        setLoading(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, updateWorkspaceSection({
                                workspaceSectionId: section.workspaceSectionId,
                                title: title,
                            })];
                    case 2:
                        _a.sent();
                        // wait a tick to allow children to update state before dismount
                        requestAnimationFrame(close);
                        return [3 /*break*/, 5];
                    case 3:
                        err_1 = _a.sent();
                        if (react_native_1.Platform.OS === 'web') {
                            toast.show({
                                message: (0, i18n_1.i18n)('channel.list.options.sectionRenameError'),
                                style: 'warning',
                            });
                        }
                        else {
                            // show network error as a label below the field
                            setNetworkError(true);
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        setLoading(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    }
    function onKeyPress(_a) {
        var nativeEvent = _a.nativeEvent;
        setNetworkError(false);
        if (nativeEvent.key === 'Enter') {
            save();
        }
        else if (nativeEvent.key === 'Escape') {
            close();
        }
    }
    var baseFieldStyle = { backgroundColor: colors.backgroundInactive, borderRadius: (0, components_1.grid)(1) };
    var errorFieldStyle = {
        borderColor: colors.error.foreground,
        backgroundColor: colors.error.background,
    };
    var style = __assign(__assign({}, baseFieldStyle), (isInvalid ? errorFieldStyle : {}));
    return (react_1.default.createElement(components_1.MBView, { fill: true, padding: 2 },
        react_1.default.createElement(components_1.MBView, { paddingBottom: 2 },
            react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('channel.list.options.renameSection'))),
        react_1.default.createElement(components_1.MBView, { paddingBottom: 2 },
            react_1.default.createElement(components_1.MBTextInput, { style: style, value: value, onChangeText: setValue, onKeyPress: onKeyPress, placeholder: (0, i18n_1.i18n)('channel.list.options.sectionName'), autoFocus: true }),
            isTooLong && react_1.default.createElement(ErrorLabel, { messageKey: "channel.list.options.sectionNameTooLong" }),
            isEmpty && react_1.default.createElement(ErrorLabel, { messageKey: "channel.list.options.sectionNameEmpty" }),
            networkError && (react_1.default.createElement(ErrorLabel, { showIcon: true, messageKey: "channel.list.options.sectionRenameError" }))),
        react_1.default.createElement(components_1.MBButton, { title: (0, i18n_1.i18n)('common.save'), onPress: save, loading: loading })));
};
exports.RenameSectionForm = RenameSectionForm;
function ErrorLabel(_a) {
    var showIcon = _a.showIcon, messageKey = _a.messageKey;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, paddingTop: 1 },
        showIcon && (react_1.default.createElement(components_1.MBIconV2, { name: "exclamation-filled", size: (0, components_1.grid)(3), color: colors.error.foreground })),
        react_1.default.createElement(components_1.MBTextBody2, { color: colors.error.foreground }, (0, i18n_1.i18n)(messageKey))));
}
