"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatedLookupField = void 0;
// @ts-strict-ignore
var lodash_1 = require("lodash");
var DateField_1 = require("./DateField");
var NumberField_1 = require("./NumberField");
var TextField_1 = require("./TextField");
var AddressField_1 = require("./AddressField");
var EmailField_1 = require("./EmailField");
var TagField_1 = require("./TagField");
function RelatedLookupField(apiCard, component, refs) {
    var _a, _b;
    var relatedCardWorkflowTemplate = (_a = refs.workflowTemplateRefs) === null || _a === void 0 ? void 0 : _a.find(function (wft) { return wft.id === component.lookupWorkflowTemplateId; });
    var relatedCardComponent = apiCard.fields[component.relatedCardComponentId];
    var firstCard = relatedCardComponent === null || relatedCardComponent === void 0 ? void 0 : relatedCardComponent[0];
    var relatedCard = (_b = refs.workflowEntityRefs) === null || _b === void 0 ? void 0 : _b.find(function (we) { return we.id === (firstCard === null || firstCard === void 0 ? void 0 : firstCard.id); });
    var data = relatedCard === null || relatedCard === void 0 ? void 0 : relatedCard.fields[component.lookupComponentId];
    var lookupComponent = relatedCardWorkflowTemplate === null || relatedCardWorkflowTemplate === void 0 ? void 0 : relatedCardWorkflowTemplate.components.find(function (c) { return c.id === component.lookupComponentId; });
    if ((0, lodash_1.isNil)(lookupComponent)) {
        return null;
    }
    switch (lookupComponent.type) {
        case 'NUMBER':
            return new NumberField_1.NumberField(data, lookupComponent.format);
        case 'DATE':
            return new DateField_1.DateField(data);
        case 'TEXT':
            return new TextField_1.TextField(data);
        case 'ADDRESS':
            return new AddressField_1.AddressField(data);
        case 'EMAIL':
            return new EmailField_1.EmailField(data);
        case 'TAG':
            return new TagField_1.TagField(data, component.id, lookupComponent.options);
        default:
            return null;
    }
}
exports.RelatedLookupField = RelatedLookupField;
