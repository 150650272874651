"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSubformsForSubformIdRecords = exports.useSubformIdRecordsForWorkflowTemplate = exports.useSubforms = void 0;
var hooks_1 = require("@shared/scenes/workflows/hooks");
var lodash_1 = require("lodash");
var react_1 = require("react");
var hooks_2 = require("../hooks");
function useSubforms(_a) {
    var workflowTemplateId = _a.workflowTemplateId;
    var templateQuery = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId);
    var templateListQuery = (0, hooks_1.useListWorkflowTemplates)(true);
    var workflowTemplate = templateQuery.workflowTemplate;
    var workflowTemplates = templateListQuery.workflowTemplates;
    var subformIdRecords = useSubformIdRecordsForWorkflowTemplate(workflowTemplate);
    var subforms = useSubformsForSubformIdRecords(subformIdRecords, workflowTemplates);
    var error = templateQuery.error || templateListQuery.error;
    var loading = templateQuery.loading || templateListQuery.loading;
    return { subforms: subforms, error: error, loading: loading };
}
exports.useSubforms = useSubforms;
/**
 * useSubformIdRecordsForWorkFlowTemplate
 *
 * get subform id records for a workflow template
 * @param workflowTemplate
 * @returns SubFormIdRecord[], or undefined if no workflow template is provided
 */
function useSubformIdRecordsForWorkflowTemplate(workflowTemplate) {
    return (0, react_1.useMemo)(function () {
        if ((0, lodash_1.isNil)(workflowTemplate)) {
            return undefined;
        }
        var subformComponents = workflowTemplate.components.filter(hooks_2.isSubformComponent);
        var subformIdRecords = subformComponents.reduce(function (memo, component) {
            var componentIdRecords = component.workflowTemplateIds.map(function (id) { return ({
                workflowTemplateId: id,
                componentId: component.id,
            }); });
            return memo.concat(componentIdRecords);
        }, []);
        return subformIdRecords;
    }, [workflowTemplate]);
}
exports.useSubformIdRecordsForWorkflowTemplate = useSubformIdRecordsForWorkflowTemplate;
/**
 * useSubformsForSubformIdRecords
 *
 * get all subforms for a list of subform id records, from a list of workflow
 * templates
 * @param records
 * @param workflowTemplates
 * @returns Subform[], or undefined if no subform id records or workflow templates are provided
 */
function useSubformsForSubformIdRecords(records, workflowTemplates) {
    return (0, react_1.useMemo)(function () {
        if ((0, lodash_1.isNil)(records) || (0, lodash_1.isNil)(workflowTemplates)) {
            return undefined;
        }
        var subforms = records.reduce(function (memo, item) {
            // find the workflow template that matches the subform id record
            var subformWorkflowTemplate = workflowTemplates === null || workflowTemplates === void 0 ? void 0 : workflowTemplates.find(function (template) { return item.workflowTemplateId === template.id; });
            // if no match, return the list unchanged
            if (!subformWorkflowTemplate) {
                return memo;
            }
            // else, add the match to the list of subforms
            return memo.concat({
                workflowTemplate: subformWorkflowTemplate,
                componentId: item.componentId,
                createdAt: subformWorkflowTemplate.createdAt,
            });
        }, []);
        return (0, lodash_1.orderBy)(subforms, 'createdAt', 'asc');
    }, [records, workflowTemplates]);
}
exports.useSubformsForSubformIdRecords = useSubformsForSubformIdRecords;
