exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-2!../../../../node_modules/postcss-loader/src/index.js??postcss!../../composes.css"), "");
// Module
exports.push([module.id, "._26RlPUDW4k8FU7hi81aKA7 {\n  overflow: scroll;\n}\n\n.VnyJeykMciDWbEMSrusYD {\n}\n\n.QWizOvmh8mXCxy6MKwXi1 {\n  font-size: 0.75rem;\n  color: #242424;\n  padding: 8px 0;\n}\n\n._2e0lI0S2IY7Ku0bf7nQVRg {\n  height: 6px;\n}\n\n.fv7Vue0chL2kxz56lZRu1 {\n  height: 16px;\n}\n\n._3w-BjDvT6Hn0ugH6jt_IPb {\n  padding-top: 32px;\n  margin-bottom: 8px;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "_26RlPUDW4k8FU7hi81aKA7",
	"pictureWrapper": "VnyJeykMciDWbEMSrusYD " + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-2!../../../../node_modules/postcss-loader/src/index.js??postcss!../../composes.css").locals["flexColumnCenter"] + "",
	"text": "QWizOvmh8mXCxy6MKwXi1",
	"helper": "_2e0lI0S2IY7Ku0bf7nQVRg",
	"error": "fv7Vue0chL2kxz56lZRu1",
	"button": "_3w-BjDvT6Hn0ugH6jt_IPb " + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-2!../../../../node_modules/postcss-loader/src/index.js??postcss!../../composes.css").locals["flex1"] + " " + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-2!../../../../node_modules/postcss-loader/src/index.js??postcss!../../composes.css").locals["flexRowCenter"] + ""
};