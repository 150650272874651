"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuditTagFieldOption = void 0;
var AuditTagFieldOption = /** @class */ (function () {
    function AuditTagFieldOption(title, icon, component) {
        this.title = title;
        this.icon = icon;
        this.component = component;
    }
    AuditTagFieldOption.prototype.matchesComponent = function (component, reservedIds) {
        var isReserved = !!reservedIds.find(function (id) { return id === component.id; });
        return (!isReserved &&
            component.type === 'AUDIT_TAG' &&
            !!component.multiselect === !!this.component.multiselect);
    };
    return AuditTagFieldOption;
}());
exports.AuditTagFieldOption = AuditTagFieldOption;
