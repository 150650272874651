"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonField = void 0;
var lodash_1 = require("lodash");
var PersonField = /** @class */ (function () {
    function PersonField(jsonBlob, personRefs) {
        this.fieldType = 'PersonField';
        this.jsonBlob = jsonBlob;
        this.users = (0, lodash_1.compact)(jsonBlob === null || jsonBlob === void 0 ? void 0 : jsonBlob.map(function (user) { return personRefs === null || personRefs === void 0 ? void 0 : personRefs.find(function (pr) { return pr.id === user; }); }));
    }
    PersonField.prototype.validate = function () {
        return null;
    };
    PersonField.prototype.isEqual = function (field) {
        return field instanceof PersonField && this.users === field.users;
    };
    PersonField.prototype.toFieldJson = function () {
        return this.jsonBlob;
    };
    Object.defineProperty(PersonField.prototype, "isEmpty", {
        get: function () {
            return (0, lodash_1.isEmpty)(this.users);
        },
        enumerable: false,
        configurable: true
    });
    return PersonField;
}());
exports.PersonField = PersonField;
