"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSort = void 0;
var react_1 = require("react");
var ControlsProvider_1 = require("./ControlsProvider");
var useSort = function () {
    var _a = (0, react_1.useContext)(ControlsProvider_1.ControlsContext), sorts = _a.sorts, setSortOption = _a.setSortOption, availableSorts = _a.availableSorts, selectedSorts = _a.selectedSorts, setSelectedSorts = _a.setSelectedSorts;
    return {
        availableSorts: availableSorts,
        selectedSorts: selectedSorts,
        setSelectedSorts: setSelectedSorts,
        selectedSortOptions: sorts,
        setSortOption: setSortOption,
    };
};
exports.useSort = useSort;
