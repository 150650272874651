"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCustomTagOptions = exports.getTagOption = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var lodash_1 = require("lodash");
var multiSelect_1 = require("@shared/components/multiSelect");
var components_1 = require("@shared/components");
var tag_1 = require("@shared/components/blocks/lib/tags/tag");
var styles_1 = require("@shared/styles");
var field_1 = require("@shared/components/blocks/lib/field");
var i18n_1 = require("@shared/util/i18n");
var createTagRow_1 = require("@shared/components/blocks/lib/tags/createTagRow");
var tagOptions_1 = require("../../contexts/tagOptions");
var getTagOption = function (value, options) {
    return options.find(function (o) { return o.value === value; });
};
exports.getTagOption = getTagOption;
var MBCustomTagOptions = function (_a) {
    var selectedTagGroup = _a.selectedTagGroup;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _b = (0, tagOptions_1.useTagOptionGroups)(), availableTagGroups = _b.availableTagGroups, addOption = _b.addOption, removeOption = _b.removeOption;
    var options = (0, react_1.useMemo)(function () { return availableTagGroups[selectedTagGroup]; }, [selectedTagGroup, availableTagGroups]).options;
    var labelExtractor = (0, react_1.useCallback)(function (value) { var _a; return (_a = (0, exports.getTagOption)(value, options)) === null || _a === void 0 ? void 0 : _a.label; }, [options]);
    var values = (0, react_1.useMemo)(function () { return options.map(function (_a) {
        var value = _a.value;
        return value;
    }); }, [options]);
    var onNewTag = (0, react_1.useCallback)(function (label) { return Promise.resolve(addOption(selectedTagGroup, label)); }, [addOption, selectedTagGroup]);
    var renderTag = (0, react_1.useCallback)(function (tagSlug) {
        var option = (0, exports.getTagOption)(tagSlug, options);
        return (react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, style: { justifyContent: 'space-between' } },
            react_1.default.createElement(components_1.MBView, { row: true, paddingLeft: 4, centerVertically: true, flex: true },
                react_1.default.createElement(tag_1.Tag, { color: option.theme.backgroundColor }, (0, field_1.defaultFieldPresenter)(option.label, 'text', 'body1', colors.foregroundActive))),
            react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: function () { return removeOption(selectedTagGroup, tagSlug); } },
                react_1.default.createElement(components_1.MBIcon, { color: colors.input.borderInactive, source: require('@shared/assets/icons/clear.png') }))));
    }, [colors, options, removeOption, selectedTagGroup]);
    var renderCreateNew = (0, react_1.useCallback)(function (query, creating) {
        if (creating) {
            return (react_1.default.createElement(components_1.MBView, { style: { height: (0, components_1.grid)(8) }, center: true },
                react_1.default.createElement(components_1.MBLoadingIndicator, null)));
        }
        return react_1.default.createElement(createTagRow_1.MBCreateTagRow, { query: query, numberOfOptions: options.length });
    }, [options]);
    var match = (0, react_1.useCallback)(function (value, query, exact) {
        var label = labelExtractor(value);
        if (exact) {
            return label.toLowerCase() === query.toLowerCase();
        }
        return (0, lodash_1.includes)(label.toLowerCase(), query.toLowerCase());
    }, [labelExtractor]);
    return (react_1.default.createElement(multiSelect_1.MBMultiSelect, { itemLabel: (0, i18n_1.i18n)('card.blocks.tagsInput.itemLabel'), itemLabelPlural: (0, i18n_1.i18n)('card.blocks.tagsInput.itemLabelPlural'), errorLabel: (0, i18n_1.i18n)('errors.tag.enterName'), labelExtractor: labelExtractor, max: 1, disableSelection: true, onCreate: onNewTag, renderItem: renderTag, renderCreateNew: renderCreateNew, match: match, values: values }));
};
exports.MBCustomTagOptions = MBCustomTagOptions;
exports.MBCustomTagOptions.displayName = 'MBCustomTagOptions';
