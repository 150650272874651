"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CombinedTagsMenu = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var styles_1 = require("@shared/styles");
var components_1 = require("@shared/components");
var i18n_1 = require("@shared/util/i18n");
var lodash_1 = require("lodash");
var menuRow_1 = require("@shared/scenes/cards/bulk/components/menuRow");
function CombinedTagsMenu(_a) {
    var components = _a.components, selectedComponentIds = _a.selectedComponentIds, onTagComponentSelect = _a.onTagComponentSelect;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _b = (0, react_1.useState)(''), filterValue = _b[0], setFilterValue = _b[1];
    var _c = (0, react_1.useState)([]), filteredComponents = _c[0], setFilteredComponents = _c[1];
    (0, react_1.useEffect)(function () {
        setFilteredComponents(components.filter(function (c) { return c.label.toLowerCase().includes(filterValue.toLowerCase().trim()); }));
    }, [components, filterValue]);
    return (react_1.default.createElement(components_1.MBView, { paddingBottom: 2 },
        react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 2 },
            react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('builder.combinedTags.fieldOptions'))),
        react_1.default.createElement(components_1.MBView, { paddingHorizontal: 2 },
            react_1.default.createElement(components_1.MBSearchBar, { value: filterValue, onChangeText: function (val) {
                    setFilterValue(!(0, lodash_1.isNil)(val) ? val : '');
                }, placeholder: (0, i18n_1.i18n)("common.search") })),
        react_1.default.createElement(components_1.MBScrollView, { style: { maxHeight: (0, components_1.grid)(30) } },
            react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingTop: 2, paddingBottom: 1.5 },
                react_1.default.createElement(components_1.MBTextBody2, null, (0, i18n_1.i18n)('builder.combinedTags.helpText'))), filteredComponents === null || filteredComponents === void 0 ? void 0 :
            filteredComponents.map(function (component) {
                return (react_1.default.createElement(TagField, { key: component.id, component: component, isSelected: (selectedComponentIds === null || selectedComponentIds === void 0 ? void 0 : selectedComponentIds.includes(component.id)) || false, onSelect: function () { return onTagComponentSelect(component); } }));
            }))));
}
exports.CombinedTagsMenu = CombinedTagsMenu;
var TagField = function (_a) {
    var component = _a.component, isSelected = _a.isSelected, onSelect = _a.onSelect;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(menuRow_1.MBMenuRow, { isHidingPressableIndicator: true, onPress: onSelect, title: component.label, key: component.id, skipWrappingIcon: true, icon: isSelected && react_1.default.createElement(components_1.MBIconV2, { name: "check-filled", size: (0, components_1.grid)(3), color: colors.accent }), image: react_1.default.createElement(components_1.MBIconV2, { name: component.icon, color: colors.foregroundActive, size: (0, components_1.grid)(3) }), imageStyle: { marginRight: (0, components_1.grid)(1) } }));
};
