"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = exports.styles = void 0;
var constants_1 = require("../constants");
var react_native_1 = require("react-native");
exports.styles = {
    content: {
        backgroundColor: 'transparent',
        border: 'none',
        position: 'relative',
        top: null,
        left: null,
        right: null,
        bottom: null,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'visible',
        padding: 0,
        width: '100vw',
        height: '100%',
    },
    overlay: {
        display: 'flex',
        backgroundColor: '#000',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        // TODO(nf) modal zIndex controller to track open modal stacking
        // for now, 900 is modal level, 901 is image preview
        zIndex: 901,
    },
    title: {
        position: 'absolute',
        top: 0,
        margin: 0,
        height: constants_1.PREVIEW_MODAL_HEADER_HEIGHT,
        // room for L and R button
        left: 80,
        right: 80,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 20,
        fontWeight: 600,
        whiteSpace: 'nowrap',
    },
    titleInside: {
        textAlign: 'left',
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
};
exports.Styles = react_native_1.StyleSheet.create({
    carouselContainer: {
        position: 'absolute',
        top: constants_1.PREVIEW_MODAL_HEADER_HEIGHT,
        bottom: 0,
        left: 0,
        right: 0,
    },
    download: {
        position: 'absolute',
        top: 0,
        right: 0,
        height: 56,
    },
    close: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: 56,
    },
});
