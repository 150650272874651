"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = void 0;
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
exports.Styles = react_native_1.StyleSheet.create({
    container: {
        alignItems: 'center',
    },
    inputContainer: {
        borderRadius: 18,
        overflow: 'hidden',
        backgroundColor: styles_1.Colors.v2.gray05,
        justifyContent: 'flex-end',
        flexGrow: 1,
        flexShrink: 1,
        marginBottom: 4,
    },
    iconButton: {
        width: 44,
        height: 44,
    },
    cancelButton: {
        paddingHorizontal: 6,
        // no buttons to the left and/or right of it
        height: 38,
        alignItems: 'center',
    },
    alignBottom: { justifyContent: 'flex-end' },
    plusButtonTooltipPosition: {
        position: 'absolute',
        bottom: '100%',
        left: 0,
    },
    mentionListPosition: __assign({ width: '100%', maxWidth: 400 }, react_native_1.Platform.select({
        web: { position: 'absolute', bottom: '100%' },
        ios: __assign({ position: 'absolute', bottom: '100%' }, styles_1.SharedUnvalidatedStyles.shadow),
    })),
    plusButtonActionRowHoverBackground: {
        backgroundColor: styles_1.Colors.gray.touch,
    },
    gifModal: react_native_1.Platform.select({
        web: {
            height: '60vh',
            // Note: if width is wider than 640px (MBModal max width for web),
            // the content will be wider than the modal content. Instead we
            // cap the mobile width of the GIF search modal to the same value
            // as it is on desktop. This is only relevant for eg tablets
            maxWidth: 480,
        },
        default: {
            flex: 1,
        },
    }),
    gifModalDesktop: {
        width: 480,
        height: 480,
    },
});
