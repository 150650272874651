"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardMenu = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var icon_1 = require("@shared/components/button/icon");
var view_1 = require("@shared/components/view");
var useOpenCardOptionsMenu_1 = require("@shared/scenes/cards/details/useOpenCardOptionsMenu");
var ShareSheet_1 = require("@shared/scenes/cards/details/containers/ShareSheet");
function MBCardMenu(_a) {
    var card = _a.card, menuIsVisible = _a.menuIsVisible, onMenuClose = _a.onMenuClose;
    var anchorRef = (0, react_1.useRef)();
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _b = (0, react_1.useState)(false), isHovered = _b[0], setIsHovered = _b[1];
    var _c = (0, react_1.useState)(false), dropdownOpen = _c[0], setDropdownOpen = _c[1];
    var _d = (0, react_1.useState)(false), showShareSheet = _d[0], setShowShareSheet = _d[1];
    var onOpen = (0, react_1.useCallback)(function () { return setDropdownOpen(true); }, []);
    var onClose = (0, react_1.useCallback)(function () {
        setDropdownOpen(false);
        onMenuClose();
    }, [onMenuClose]);
    var openCardOptionsMenu = (0, useOpenCardOptionsMenu_1.useOpenCardOptionsMenu)(card, {
        onOpen: onOpen,
        onClose: onClose,
        onShare: function () { return setShowShareSheet(true); },
    });
    var handlePress = (0, react_1.useCallback)(function () { return openCardOptionsMenu(anchorRef.current); }, [openCardOptionsMenu, anchorRef]);
    return (react_1.default.createElement(components_1.MBView, { ref: anchorRef, style: [
            styles.cardMenuWrapper,
            { borderRadius: (0, view_1.grid)(1), position: 'absolute', top: 0, right: 0 },
        ] },
        react_1.default.createElement(components_1.MBHover, { onHoverStart: function () { return setIsHovered(true); }, onHoverEnd: function () { return setIsHovered(false); } },
            react_1.default.createElement(react_1.default.Fragment, null, (menuIsVisible || dropdownOpen) && (react_1.default.createElement(icon_1.MBIconButton, { source: require('@shared/assets/icons/v2/dots.png'), size: "small", inverted: true, onPress: handlePress, backgroundColor: isHovered ? colors.highlightActive : colors.highlightInactive })))),
        showShareSheet && (react_1.default.createElement(ShareSheet_1.ShareSheet, { containerRef: anchorRef, card: card, onCloseSheet: function () { return setShowShareSheet(false); } }))));
}
exports.MBCardMenu = MBCardMenu;
var styles = react_native_1.StyleSheet.create({
    cardMenuWrapper: {
        padding: (0, view_1.grid)(1),
        paddingLeft: 0,
    },
});
