"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useScrollTextInputIntoView = void 0;
var react_1 = require("react");
var react_native_1 = require("react-native");
var scroll_1 = require("@shared/components/blocks/contexts/scroll");
function useScrollTextInputIntoView() {
    var scrollView = (0, react_1.useContext)(scroll_1.CardBlockScrollContext);
    return (0, react_1.useCallback)(function () {
        var _a;
        if (react_native_1.Platform.OS === 'web') {
            (_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'nearest',
            });
        }
        else if (react_native_1.Platform.OS === 'ios') {
            var currentlyFocusedInput = react_native_1.TextInput.State.currentlyFocusedInput();
            if (!!currentlyFocusedInput) {
                var component_1 = (0, react_native_1.findNodeHandle)(currentlyFocusedInput);
                setTimeout(function () {
                    var _a;
                    (_a = scrollView.current) === null || _a === void 0 ? void 0 : _a.scrollResponderScrollNativeHandleToKeyboard(component_1, 150, true);
                }, 0);
            }
        }
    }, [scrollView]);
}
exports.useScrollTextInputIntoView = useScrollTextInputIntoView;
