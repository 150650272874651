"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getQueryOrder = exports.getQueryStartDateAfter = exports.getQueryStartDateBefore = exports.getQueryLegacyRelationFilters = exports.getQueryRelationFilters = exports.toKey = exports.defaultOrder = void 0;
exports.defaultOrder = { orderBy: 'createdAt', orderDirection: 'DESC' };
function toKey(query) {
    function replacer(key, value) {
        return this.__typename === 'CardFieldStringTag' && key === 'archivedAt' ? undefined : value;
    }
    return JSON.stringify(query, replacer);
}
exports.toKey = toKey;
function getQueryRelationFilters(query) {
    var _a, _b, _c, _d;
    var tagFilters = (_b = (_a = query.filters) === null || _a === void 0 ? void 0 : _a.filter(function (f) { return f.type === 'tag'; }).map(function (f) {
        var _a, _b, _c;
        return ({
            type: 'tags',
            mustHaveAllIds: (_a = f.mustHaveAll) === null || _a === void 0 ? void 0 : _a.map(function (t) { return t.id; }),
            mustHaveAnyIds: (_b = f.mustHaveAny) === null || _b === void 0 ? void 0 : _b.map(function (t) { return t.id; }),
            mustNotHaveAnyIds: (_c = f.mustNotHaveAny) === null || _c === void 0 ? void 0 : _c.map(function (t) { return t.id; }),
        });
    })) !== null && _b !== void 0 ? _b : [];
    var cardFilters = (_d = (_c = query.filters) === null || _c === void 0 ? void 0 : _c.filter(function (f) { return f.type === 'card'; }).map(function (f) {
        var _a, _b, _c;
        return ({
            type: 'relatedCards',
            mustHaveAllIds: (_a = f.mustHaveAll) === null || _a === void 0 ? void 0 : _a.map(function (t) { return t.cardId; }),
            mustHaveAnyIds: (_b = f.mustHaveAny) === null || _b === void 0 ? void 0 : _b.map(function (t) { return t.cardId; }),
            mustNotHaveAnyIds: (_c = f.mustNotHaveAny) === null || _c === void 0 ? void 0 : _c.map(function (t) { return t.cardId; }),
        });
    })) !== null && _d !== void 0 ? _d : [];
    return __spreadArray(__spreadArray([], tagFilters, true), cardFilters, true);
}
exports.getQueryRelationFilters = getQueryRelationFilters;
function getQueryLegacyRelationFilters(query) {
    var _a, _b;
    var assigneeFilters = (_b = (_a = query.filters) === null || _a === void 0 ? void 0 : _a.filter(function (f) { return f.type === 'user'; }).map(function (f) { return ({
        type: 'assignees',
        mustHaveAllIds: f.mustHaveAllIds,
        mustHaveAnyIds: f.mustHaveAnyIds,
        mustNotHaveAnyIds: f.mustNotHaveAnyIds,
    }); })) !== null && _b !== void 0 ? _b : [];
    return assigneeFilters;
}
exports.getQueryLegacyRelationFilters = getQueryLegacyRelationFilters;
function getQueryStartDateBefore(query) {
    var _a, _b;
    return (_b = (_a = query.filters) === null || _a === void 0 ? void 0 : _a.find(function (f) { return f.type === 'startDate'; })) === null || _b === void 0 ? void 0 : _b.before;
}
exports.getQueryStartDateBefore = getQueryStartDateBefore;
function getQueryStartDateAfter(query) {
    var _a, _b;
    return (_b = (_a = query.filters) === null || _a === void 0 ? void 0 : _a.find(function (f) { return f.type === 'startDate'; })) === null || _b === void 0 ? void 0 : _b.after;
}
exports.getQueryStartDateAfter = getQueryStartDateAfter;
function getQueryOrder(query) {
    if (!query.order) {
        return [exports.defaultOrder];
    }
    if (query.order.some(function (order) { return order.orderBy === exports.defaultOrder.orderBy; })) {
        return query.order;
    }
    return __spreadArray(__spreadArray([], query.order, true), [exports.defaultOrder], false);
}
exports.getQueryOrder = getQueryOrder;
