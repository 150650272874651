"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBDeveloperMenu = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var bottomSheet_1 = require("@shared/components/bottomSheet");
var titleBar_1 = require("@shared/components/bottomSheet/titleBar");
var i18n_1 = require("@shared/util/i18n");
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var channelDevDetails_1 = require("./channelDevDetails");
var react_redux_1 = require("react-redux");
var MBDeveloperMenu = function (_a) {
    var channelSlug = _a.channelSlug;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var containerRef = (0, react_1.useRef)();
    var _b = (0, react_1.useState)(false), showingSheet = _b[0], setShowingSheet = _b[1];
    var showSheet = (0, react_1.useCallback)(function () {
        setShowingSheet(true);
    }, []);
    var hideSheet = (0, react_1.useCallback)(function () {
        setShowingSheet(false);
    }, []);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_native_1.TouchableOpacity, { ref: containerRef }),
        react_1.default.createElement(components_1.MBView, { padding: 1, style: { zIndex: 99 } },
            react_1.default.createElement(components_1.MBIconButton, { source: require('@shared/assets/icons/v2/wrench-tools.png'), color: colors.foregroundActive, backgroundColor: colors.background, size: isDesktop ? 'small' : 'normal', onPress: showSheet })),
        showingSheet && (react_1.default.createElement(bottomSheet_1.MBBottomSheet, { onClose: hideSheet, containerRef: containerRef, renderTitleBar: function () { return react_1.default.createElement(titleBar_1.MBBottomSheetTitleBar, { title: (0, i18n_1.i18n)('devTools.developerMenu') }); } },
            react_1.default.createElement(react_native_1.ScrollView, null,
                react_1.default.createElement(channelDevDetails_1.MBChannelDevDetails, { channelSlug: channelSlug }))))));
};
exports.MBDeveloperMenu = MBDeveloperMenu;
exports.MBDeveloperMenu.displayName = 'MBDeveloperMenu';
