"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBFormField = void 0;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var typography_1 = require("@shared/components/typography");
var styles_1 = require("@shared/styles");
var styles_2 = require("./styles");
// TODO(fant): abstract this to shared components. Problem: we already have
// a set of form components. They are currently used for task form fields.
// For other forms (edit profile, billing), we have different styling.
var MBFormField = function (_a) {
    var title = _a.title, error = _a.error, style = _a.style, children = _a.children, required = _a.required, titleStyle = _a.titleStyle;
    var _b = (0, react_1.useState)(false), focused = _b[0], setFocused = _b[1];
    var onFocus = (0, react_1.useCallback)(function () { return setFocused(true); }, [setFocused]);
    var onBlur = (0, react_1.useCallback)(function () { return setFocused(false); }, [setFocused]);
    return (react_1.default.createElement(components_1.MBView, { style: style },
        !!title && (react_1.default.createElement(components_1.MBView, { paddingTop: "large", paddingHorizontal: "small", paddingBottom: "tiny", style: titleStyle },
            react_1.default.createElement(typography_1.MBTextOverlineBold, { color: focused ? styles_1.Colors.v2.purple : styles_1.Colors.v2.gray30 },
                title.toUpperCase(),
                !!required && ' *'))),
        children({ focused: focused, onFocus: onFocus, onBlur: onBlur, style: [styles_2.Styles.field, !!error && styles_2.Styles.fieldError] }),
        !!error && (react_1.default.createElement(components_1.MBView, { paddingTop: "tiny", paddingHorizontal: "small" },
            react_1.default.createElement(typography_1.MBTextBody2Bold, { color: styles_1.Colors.v2.actionWarmRed }, error)))));
};
exports.MBFormField = MBFormField;
exports.MBFormField.displayName = 'MBFormField';
