"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePrepareRemoteFileForSharing = void 0;
var react_1 = require("react");
/**
 * On native, this hook prepares a remote URL for sharing by downloading it to a temporary file. The file path is
 * returned to the caller, who can then share it via react-native-share.
 *
 * On web, this hook is a no-op and returns the URL as-is.
 */
function usePrepareRemoteFileForSharing() {
    var _a = (0, react_1.useState)(), response = _a[0], setResponse = _a[1];
    var prepareRemoteFileForSharing = (0, react_1.useCallback)(function (url) {
        setResponse({ state: 'success', data: url });
    }, [setResponse]);
    return { response: response, prepareRemoteFileForSharing: prepareRemoteFileForSharing };
}
exports.usePrepareRemoteFileForSharing = usePrepareRemoteFileForSharing;
