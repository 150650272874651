"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBoolean = void 0;
var react_1 = require("react");
function useBoolean(initialState) {
    if (initialState === void 0) { initialState = false; }
    var _a = (0, react_1.useState)(initialState), value = _a[0], setValue = _a[1];
    var setTrue = (0, react_1.useCallback)(function setTrue() {
        setValue(true);
    }, []);
    var setFalse = (0, react_1.useCallback)(function setFalse() {
        setValue(false);
    }, []);
    var toggle = (0, react_1.useCallback)(function toggle() {
        setValue(function (prevState) { return !prevState; });
    }, []);
    var reset = (0, react_1.useCallback)(function reset() {
        setValue(initialState);
    }, [initialState]);
    return [value, setTrue, setFalse, toggle, reset];
}
exports.useBoolean = useBoolean;
