"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.editMessage = exports.deleteMessage = exports.resendAll = exports.restoreFromPersist = exports.resend = exports.sendMessageFromDraft = exports.messageFromDraft = exports.PERSIST_MESSAGES_FROM_STORAGE = exports.REMOVE_MESSAGE_FROM_CACHE = exports.UPDATE_MESSAGE_DELIVERY_STATUS = exports.ADD_MESSAGE_TO_CACHE = void 0;
// @ts-strict-ignore
var api_1 = require("@shared/api");
var client_1 = require("@shared/api/client");
var ChatInputActions = __importStar(require("@shared/scenes/chat/input/store/actions"));
var MBMessagePersist = __importStar(require("@shared/scenes/chat/thread/util/persist"));
var queue_1 = require("@shared/scenes/chat/thread/util/queue");
var store_1 = require("@shared/store");
var analytics_1 = require("@shared/util/analytics");
var uuid = __importStar(require("@shared/util/uuid"));
var _ = __importStar(require("lodash"));
var messageDraft_1 = require("./messageDraft");
exports.ADD_MESSAGE_TO_CACHE = 'CHAT:ADD_MESSAGE_TO_CACHE';
exports.UPDATE_MESSAGE_DELIVERY_STATUS = 'CHAT:UPDATE_MESSAGE_DELIVERY_STATUS';
exports.REMOVE_MESSAGE_FROM_CACHE = 'CHAT:REMOVE_MESSAGE_FROM_CACHE';
exports.PERSIST_MESSAGES_FROM_STORAGE = 'CHAT:PERSIST_MESSAGES_FROM_STORAGE';
/*
    Create a message from a message draft
*/
var messageFromDraft = function (draft) {
    // 1. get current user from the cache to create a proper optimistic message
    // TODO(fant): the default value below is a hack... Just after the user has
    // signed up, this fragment returns null. This causes UI to break later
    // since it expects message.sender to be set.
    var me = client_1.apolloClient.readFragment({
        id: "User".concat(store_1.store.getState().appState.currentUserId),
        fragment: api_1.Fragments.user.short,
    }) || { id: store_1.store.getState().appState.currentUserId, fullName: '' };
    // 2. create the message from the draft data
    return {
        id: Date.now(),
        sender: me,
        type: 'TEXT',
        subType: null,
        content: draft.content.trim(),
        createdAt: new Date().toISOString(),
        editedAt: null,
        urgent: draft.urgent,
        sentBySMS: false,
        meta: {
            id: Date.now(),
            uuid: uuid.v4(),
            channelName: 'TODO(fant)',
            tokens: [],
            reactions: [],
            attachments: draft.attachments.map(function (attachment) { return ({
                fileKey: attachment.fileKey,
                name: attachment.name,
                width: attachment.width,
                height: attachment.height,
                url: attachment.url,
                // TODO(fant): get this from the attachment instead...
                contentType: attachment.contentType,
                thumbnails: attachment.thumbnails,
            }); }),
            users: null,
            cardId: null,
            startDate: null,
            endDate: null,
        },
    };
};
exports.messageFromDraft = messageFromDraft;
/*
    Send a message from a message draft. This is currently used by native to
    start sending a message.
*/
var sendMessageFromDraft = function (draft, channelSlug) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var message, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                ChatInputActions.hideTooltips(dispatch);
                message = (0, exports.messageFromDraft)(draft);
                return [4 /*yield*/, queue_1.messageQueue.add(message, channelSlug)];
            case 1:
                _a.sent();
                return [3 /*break*/, 3];
            case 2:
                e_1 = _a.sent();
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
exports.sendMessageFromDraft = sendMessageFromDraft;
/*
    Try to send messages in a particular channel
*/
var resend = function (channelSlug) {
    return function (_a) { return __awaiter(void 0, void 0, void 0, function () {
        var e_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, queue_1.messageQueue.retry(channelSlug)];
                case 1:
                    _b.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _b.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
exports.resend = resend;
/*
    Restores the data saved in setupListeningToChatMessagesToPersist
    from AsyncStorage to the redux store.

    Note: we don't restore message attachments and if they've been uploaded.
    The reason is that we know which attachments have been uploaded by
    looking if their url is public (http) or local.
*/
var restoreFromPersist = function () { return __awaiter(void 0, void 0, void 0, function () {
    var restored;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, MBMessagePersist.restore()];
            case 1:
                restored = _a.sent();
                if (!restored) {
                    return [2 /*return*/, null];
                }
                store_1.store.dispatch({ type: exports.PERSIST_MESSAGES_FROM_STORAGE, value: restored });
                return [2 /*return*/];
        }
    });
}); };
exports.restoreFromPersist = restoreFromPersist;
/*
    Resend all messages in all channel. This is typically done when
    reconnecting or after restoring all messages on app start.
*/
var resendAll = function () { return __awaiter(void 0, void 0, void 0, function () {
    var channelSlugs;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                channelSlugs = _.default(store_1.store.getState().chatMessages.cachedMessageUuidsFromChannelSlug)
                    .pickBy(function (uuids) { return !!uuids.length; })
                    .keys()
                    .value();
                return [4 /*yield*/, Promise.all(channelSlugs.map(function (slug) { return queue_1.messageQueue.retry(slug); }))];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
exports.resendAll = resendAll;
var deleteMessage = function (message, channelSlug) {
    return function (_a) { return __awaiter(void 0, void 0, void 0, function () {
        var e_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, queue_1.messageQueue.delete(message, channelSlug)];
                case 1:
                    _b.sent();
                    analytics_1.MBAnalytics.track('Message::Delete', { messageId: message.id });
                    return [3 /*break*/, 3];
                case 2:
                    e_3 = _b.sent();
                    // TODO(fant): how do we notify the user?
                    console.warn('failed to delete message:', e_3.message);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
exports.deleteMessage = deleteMessage;
var editMessage = function (_a) {
    var message = _a.message, content = _a.content, channelSlug = _a.channelSlug;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var e_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    (0, messageDraft_1.endEditingMessage)(channelSlug)(dispatch);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, queue_1.messageQueue.performEditAPIRequest(message, content, channelSlug)];
                case 2:
                    _a.sent();
                    analytics_1.MBAnalytics.track('Message::Edit', { messageId: message.id });
                    return [3 /*break*/, 4];
                case 3:
                    e_4 = _a.sent();
                    // TODO(fant): how do we notify the user?
                    console.warn('failed to edit message:', e_4.message);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
exports.editMessage = editMessage;
