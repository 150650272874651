"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubformsList = void 0;
var components_1 = require("@shared/components");
var listWrapper_1 = require("@shared/components/wrapper/listWrapper");
var hooks_1 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var SubformItem_1 = require("./SubformItem");
var useSubforms_1 = require("./useSubforms");
function SubformsList(_a) {
    var channelSlug = _a.channelSlug;
    var channel = (0, hooks_1.useGetChannel)({ channelSlug: channelSlug });
    var workflowTemplateId = channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId;
    var _b = (0, useSubforms_1.useSubforms)({ workflowTemplateId: workflowTemplateId }), subforms = _b.subforms, loading = _b.loading, error = _b.error;
    var _c = (0, react_1.useState)(''), query = _c[0], setQuery = _c[1];
    var filteredSubforms = (0, react_1.useMemo)(function () {
        if ((0, lodash_1.isEmpty)(query)) {
            return subforms;
        }
        return subforms === null || subforms === void 0 ? void 0 : subforms.filter(function (_a) {
            var name = _a.workflowTemplate.name;
            return name === null || name === void 0 ? void 0 : name.toLowerCase().includes(query.toLowerCase());
        });
    }, [query, subforms]);
    return (react_1.default.createElement(listWrapper_1.MBListLoadingWrapper, { loading: loading || (0, lodash_1.isNil)(workflowTemplateId), error: !!error, empty: !loading && (0, lodash_1.isEmpty)(subforms), renderEmptyOptions: {
            title: (0, i18n_1.i18n)('workflowTemplate.subformsEmptyStateTitle'),
            description: (0, i18n_1.i18n)('workflowTemplate.subformsEmptyStateDescription'),
        }, renderData: function () { return (react_1.default.createElement(components_1.MBScrollView, { nativeShouldPersistTaps: true, nativeKeyboardDismissMode: "on-drag", style: { height: '100%' } },
            react_1.default.createElement(components_1.MBView, { paddingVertical: 1, paddingHorizontal: 1 },
                react_1.default.createElement(components_1.MBSearchBar, { value: query, onChangeText: setQuery, placeholder: (0, i18n_1.i18n)('workflowTemplate.subformsSearch') })),
            !(0, lodash_1.isNil)(workflowTemplateId) &&
                (filteredSubforms === null || filteredSubforms === void 0 ? void 0 : filteredSubforms.map(function (_a) {
                    var subformWorkflowTemplate = _a.workflowTemplate, componentId = _a.componentId;
                    return (react_1.default.createElement(SubformItem_1.SubformItem, { key: subformWorkflowTemplate === null || subformWorkflowTemplate === void 0 ? void 0 : subformWorkflowTemplate.id, componentId: componentId, subformWorkflowTemplate: subformWorkflowTemplate, channelSlug: channelSlug, mainWorkflowTemplateId: workflowTemplateId }));
                })))); } }));
}
exports.SubformsList = SubformsList;
