"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = exports.SELECTED_CHANNEL_IMAGE_SIZE = void 0;
// @ts-strict-ignore
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
var view_1 = require("@shared/components/view");
var CHANNEL_IMAGE_REMOVE_BUTTON_SIZE = (0, view_1.grid)(3);
exports.SELECTED_CHANNEL_IMAGE_SIZE = (0, view_1.grid)(10);
exports.Styles = react_native_1.StyleSheet.create({
    recipientInput: {
        // Make sure the row doesn't overflow,
        // but instead starts adding items to
        // the next row.
        flexWrap: 'wrap',
    },
    recipientInputTextInput: {
        flex: 1,
        padding: 5, // MBView padding small
    },
    horizontalUserList: {
        // The gray background on the horizontal user list
        // looks ugly on native. Therefore we remove it
        backgroundColor: react_native_1.Platform.OS !== 'web' && styles_1.Colors.v2.gray05,
    },
    horizontalUserListContentContainer: {
        padding: 5, // padding="small" on MBView
    },
    horizontalUserListRowImage: {
        width: 56,
        height: 56,
        borderRadius: 56 / 2,
        borderWidth: 4,
        borderColor: 'white',
        overflow: 'hidden',
    },
    horizontalUserListRowText: {
        maxWidth: 56,
    },
    horizontalUserListRowRemoveButton: {
        padding: 0,
        width: 18,
        height: 18,
        borderRadius: 18 / 2,
        backgroundColor: 'white',
    },
    horizontalUserListRowRemoveButtonPosition: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    selectChannelImageWrapper: {
        width: (0, view_1.grid)(8),
        height: (0, view_1.grid)(8),
        borderRadius: (0, view_1.grid)(8) / 2,
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: styles_1.Colors.v2.green,
    },
    selectedChannelImageWrapper: {
        width: exports.SELECTED_CHANNEL_IMAGE_SIZE,
        height: exports.SELECTED_CHANNEL_IMAGE_SIZE,
        borderRadius: exports.SELECTED_CHANNEL_IMAGE_SIZE / 2,
        overflow: 'hidden',
        position: 'relative',
    },
    detailsUserList: {
        // The gray background on the horizontal user list
        // looks ugly on native. Therefore we remove it
        backgroundColor: react_native_1.Platform.OS !== 'web' && styles_1.Colors.v2.gray05,
        flexWrap: 'wrap',
    },
    channelImageRemoveButton: {
        padding: 0,
        width: CHANNEL_IMAGE_REMOVE_BUTTON_SIZE,
        height: CHANNEL_IMAGE_REMOVE_BUTTON_SIZE,
        borderRadius: CHANNEL_IMAGE_REMOVE_BUTTON_SIZE / 2,
        backgroundColor: 'white',
    },
    channelImageRemoveButtonPosition: {
        position: 'absolute',
        top: -(0, view_1.grid)(1),
        right: -(0, view_1.grid)(1),
        zIndex: 1,
        padding: (0, view_1.grid)(1), // for extra large click area
    },
    channelImageRemoveButtonOutline: {
        padding: 1,
        backgroundColor: 'white',
        borderRadius: (CHANNEL_IMAGE_REMOVE_BUTTON_SIZE + 2) / 2, // +2 due to padding adding a total of 2px to width/height
    },
    channelImageSelectorWrapper: {
        height: (0, view_1.grid)(20),
        backgroundColor: styles_1.Colors.v2.gray05,
    },
    channelImageSelectorsContainer: {
        justifyContent: 'space-between',
    },
    formColor: {
        backgroundColor: styles_1.Colors.v2.white,
    },
    fillColor: {
        backgroundColor: styles_1.Colors.v2.gray05,
    },
    imageSelectorPadding: {
        paddingHorizontal: '12.5%',
    },
});
