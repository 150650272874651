"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCenterContent = void 0;
// @ts-strict-ignore
var react_1 = require("react");
var react_native_1 = require("react-native");
var view_1 = require("../view");
// HACK ALERT: onLayout does not work on Mobile Safari due to missing ResizeObserver
var missingResizeObserver = react_native_1.Platform.select({
    web: typeof window.ResizeObserver === 'undefined',
    default: false,
});
var initialWidth = missingResizeObserver ? (0, view_1.grid)(5) : undefined;
function useCenterContent(_a) {
    var hasLeft = _a.hasLeft, hasRight = _a.hasRight;
    var _b = (0, react_1.useState)(initialWidth), leftWidth = _b[0], setLeftWidth = _b[1];
    var _c = (0, react_1.useState)(initialWidth), rightWidth = _c[0], setRightWidth = _c[1];
    var _d = (0, react_1.useState)(0), contentWidth = _d[0], setContentWidth = _d[1];
    var leftOnSize = (0, react_1.useCallback)(function (_a) {
        var width = _a.width;
        return setLeftWidth(width);
    }, []);
    var rightOnSize = (0, react_1.useCallback)(function (_a) {
        var width = _a.width;
        return setRightWidth(width);
    }, []);
    var contentOnSize = (0, react_1.useCallback)(function (_a) {
        var width = _a.width;
        return setContentWidth(width);
    }, []);
    var loading = (hasLeft && leftWidth === undefined) || (hasRight && rightWidth === undefined);
    var paddingHorizontal = loading ? undefined : Math.max(leftWidth, rightWidth);
    return { loading: loading, paddingHorizontal: paddingHorizontal, contentWidth: contentWidth, leftOnSize: leftOnSize, rightOnSize: rightOnSize, contentOnSize: contentOnSize };
}
exports.useCenterContent = useCenterContent;
