"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatedEntityField = void 0;
// @ts-strict-ignore
var lodash_1 = require("lodash");
var RelatedEntityField = /** @class */ (function () {
    function RelatedEntityField(jsonBlob, relatedEntities) {
        var _this = this;
        var _a;
        this.fieldType = 'RelatedCardField';
        this.items = jsonBlob;
        this.cards = [];
        (_a = this.items) === null || _a === void 0 ? void 0 : _a.forEach(function (item) {
            var relatedEntity = relatedEntities === null || relatedEntities === void 0 ? void 0 : relatedEntities.find(function (rc) { return rc.id === item.id; });
            if (!(0, lodash_1.isEmpty)(relatedEntity)) {
                _this.cards.push(__assign(__assign({}, relatedEntity), (!(0, lodash_1.isNil)(item.quantity) ? { quantity: item.quantity } : {})));
            }
        });
    }
    RelatedEntityField.prototype.validate = function () {
        return null;
    };
    RelatedEntityField.prototype.isEqual = function (field) {
        return field instanceof RelatedEntityField && this.items === field.items;
    };
    RelatedEntityField.prototype.toFieldJson = function () {
        return this.items;
    };
    Object.defineProperty(RelatedEntityField.prototype, "isEmpty", {
        get: function () {
            return (0, lodash_1.isEmpty)(this.cards);
        },
        enumerable: false,
        configurable: true
    });
    return RelatedEntityField;
}());
exports.RelatedEntityField = RelatedEntityField;
var RelatedItem = /** @class */ (function () {
    function RelatedItem() {
    }
    return RelatedItem;
}());
