"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeTrackerFieldOption = void 0;
var hooks_1 = require("../../hooks");
var TimeTrackerFieldOption = /** @class */ (function () {
    function TimeTrackerFieldOption(title, icon, component) {
        this.title = title;
        this.icon = icon;
        this.component = component;
    }
    TimeTrackerFieldOption.prototype.matchesComponent = function (component, reservedIds) {
        var isReserved = !!reservedIds.find(function (id) { return id === component.id; });
        return !isReserved && (0, hooks_1.isTimeTrackerComponent)(component);
    };
    return TimeTrackerFieldOption;
}());
exports.TimeTrackerFieldOption = TimeTrackerFieldOption;
