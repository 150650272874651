"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatedCardLookupFieldOption = void 0;
var hooks_1 = require("../../hooks");
var RelatedCardLookupFieldOption = /** @class */ (function () {
    function RelatedCardLookupFieldOption(title, icon, component) {
        this.title = title;
        this.icon = icon;
        this.component = component;
    }
    RelatedCardLookupFieldOption.prototype.matchesComponent = function (component, _reservedIds) {
        return (0, hooks_1.isRelatedCardLookupComponent)(component);
    };
    return RelatedCardLookupFieldOption;
}());
exports.RelatedCardLookupFieldOption = RelatedCardLookupFieldOption;
