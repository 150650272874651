"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGroupCollapse = void 0;
var react_1 = require("react");
var storage_1 = require("@shared/util/storage");
var lodash_1 = require("lodash");
var GROUP_STATE_CACHE_KEY = 'groupState';
var useGroupCollapse = function (_a) {
    var group = _a.group, viewTemplate = _a.viewTemplate, totalCardCount = _a.totalCardCount;
    var _b = (0, react_1.useState)(null), isCollapsed = _b[0], setIsCollapsed = _b[1];
    var selectionState = Array.from(group.values())[0];
    var componentId = selectionState === null || selectionState === void 0 ? void 0 : selectionState.filter.component.id;
    var selectionValue = selectionState === null || selectionState === void 0 ? void 0 : selectionState.selection[0].value;
    var storageKey = "".concat(viewTemplate.id, "-").concat(componentId, "-").concat(selectionValue);
    (0, react_1.useEffect)(function () {
        var getCollapsedStatesFromCache = function () { return __awaiter(void 0, void 0, void 0, function () {
            var cachedState;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, storage_1.MBStorage.getJSON(GROUP_STATE_CACHE_KEY, [])];
                    case 1:
                        cachedState = _a.sent();
                        setIsCollapsed(cachedState.includes(storageKey) || false);
                        return [2 /*return*/];
                }
            });
        }); };
        getCollapsedStatesFromCache();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var cacheCollapsedState = (0, react_1.useCallback)(function (newState) { return __awaiter(void 0, void 0, void 0, function () {
        var cachedState, nextState;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, storage_1.MBStorage.getJSON(GROUP_STATE_CACHE_KEY, [])];
                case 1:
                    cachedState = _a.sent();
                    nextState = __spreadArray([], cachedState.filter(function (i) { return i !== storageKey; }), true);
                    if (newState && totalCardCount > 0) {
                        nextState.push(storageKey);
                    }
                    storage_1.MBStorage.setJSON(GROUP_STATE_CACHE_KEY, nextState);
                    return [2 /*return*/];
            }
        });
    }); }, [storageKey, totalCardCount]);
    (0, react_1.useEffect)(function () {
        if ((0, lodash_1.isNil)(totalCardCount)) {
            return;
        }
        var showIfFirstCardAdded = function () { return __awaiter(void 0, void 0, void 0, function () {
            var cachedState;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(totalCardCount > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, storage_1.MBStorage.getJSON(GROUP_STATE_CACHE_KEY, [])];
                    case 1:
                        cachedState = _a.sent();
                        setIsCollapsed(cachedState.includes(storageKey));
                        return [3 /*break*/, 3];
                    case 2:
                        setIsCollapsed(true);
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        showIfFirstCardAdded();
    }, [totalCardCount, storageKey]);
    (0, react_1.useEffect)(function () {
        if ((0, lodash_1.isNil)(isCollapsed)) {
            return;
        }
        cacheCollapsedState(isCollapsed);
    }, [cacheCollapsedState, isCollapsed, storageKey]);
    return [isCollapsed, setIsCollapsed];
};
exports.useGroupCollapse = useGroupCollapse;
