"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCardTableView = void 0;
var hooks_1 = require("@shared/scenes/workflows/hooks");
var ColumnBuilder_1 = require("../utils/ColumnBuilder");
var react_1 = require("react");
var useCollectionViewData_1 = require("@shared/scenes/cards/collectionView/hooks/useCollectionViewData");
var CollectionViewAnalytics_1 = require("../../collectionView/CollectionViewAnalytics");
function useCardTableView(workflowTemplateId, viewTemplateId, workspaceId, baseNavigationPath) {
    var workflowTemplate = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId).workflowTemplate;
    var viewTemplate = (0, hooks_1.useViewTemplate)(workflowTemplateId, viewTemplateId).viewTemplate;
    var collectionViewData = (0, useCollectionViewData_1.useCollectionViewData)({
        workflowTemplateId: workflowTemplateId,
        viewTemplateId: viewTemplateId,
        workspaceId: workspaceId,
        baseNavigationPath: baseNavigationPath,
    });
    var columns = (0, react_1.useMemo)(function () {
        var analytics = new CollectionViewAnalytics_1.CollectionViewAnalytics(workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.id, viewTemplateId);
        return (0, ColumnBuilder_1.buildCardColumns)(workflowTemplate, collectionViewData.canUpdateCard, analytics, viewTemplate);
    }, [collectionViewData.canUpdateCard, viewTemplate, viewTemplateId, workflowTemplate]);
    return __assign(__assign({}, collectionViewData), { columns: columns });
}
exports.useCardTableView = useCardTableView;
