"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CombinedTagsField = void 0;
// @ts-strict-ignore
var lodash_1 = require("lodash");
var CombinedTagsField = /** @class */ (function () {
    function CombinedTagsField(componentIds, apiCard) {
        this.fieldType = 'CombinedTagsField';
        this.componentIds = componentIds;
        var tags = [];
        componentIds === null || componentIds === void 0 ? void 0 : componentIds.forEach(function (componentId) {
            if (!(0, lodash_1.isNil)(apiCard.fields[componentId])) {
                tags = __spreadArray(__spreadArray([], tags, true), apiCard.fields[componentId], true);
            }
        });
        this.tags = tags;
    }
    CombinedTagsField.prototype.validate = function () {
        return null;
    };
    CombinedTagsField.prototype.isEqual = function (field) {
        return field instanceof CombinedTagsField && (0, lodash_1.isEqual)(this.tags, field.tags);
    };
    CombinedTagsField.prototype.toFieldJson = function () {
        return this.tags;
    };
    Object.defineProperty(CombinedTagsField.prototype, "isEmpty", {
        get: function () {
            return (0, lodash_1.isEmpty)(this.tags);
        },
        enumerable: false,
        configurable: true
    });
    return CombinedTagsField;
}());
exports.CombinedTagsField = CombinedTagsField;
