"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
exports.styles = react_native_1.StyleSheet.create({
    container: { flex: 1 },
    list: { paddingBottom: (0, components_1.grid)(12) },
    day: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        borderTopColor: styles_1.Colors.v2.gray10,
        borderTopWidth: 1,
        borderLeftColor: styles_1.Colors.v2.transparent,
        borderLeftWidth: (0, components_1.grid)(0.75),
    },
    firstDay: { borderTopWidth: 0 },
    today: {
        borderLeftColor: styles_1.Colors.v2.green,
    },
    dayMarker: {
        marginHorizontal: (0, components_1.grid)(0.5),
        marginVertical: (0, components_1.grid)(1),
        borderRadius: (0, components_1.grid)(1),
        width: (0, components_1.grid)(6),
        height: (0, components_1.grid)(6),
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: 'transparent',
    },
    dayNumber: { textAlign: 'center', fontSize: 24, lineHeight: 28 },
    dayName: { textAlign: 'center', fontSize: 13, lineHeight: 12 },
    addButton: { width: (0, components_1.grid)(6), height: (0, components_1.grid)(8), justifyContent: 'center', alignItems: 'center' },
    cardList: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'stretch',
        paddingTop: (0, components_1.grid)(1),
        paddingBottom: (0, components_1.grid)(0.5),
    },
    card: {
        marginBottom: (0, components_1.grid)(0.75),
        minHeight: (0, components_1.grid)(6),
        paddingHorizontal: (0, components_1.grid)(2),
        paddingVertical: (0, components_1.grid)(1.5),
    },
    cardOverflowMenu: {
        position: 'absolute',
        right: (0, components_1.grid)(0.75),
        top: (0, components_1.grid)(0.75),
        zIndex: 1,
    },
    selectionCheckbox: {
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 1,
    },
});
