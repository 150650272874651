"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePaywallExperiment = exports.useTrackPaywallExperimentView = exports.useTrackPaywallExperimentClick = void 0;
var react_1 = require("react");
var __1 = require("../");
var analytics_1 = require("@shared/util/analytics");
var useCanViewCollectionView_1 = require("@shared/data/billing/hooks/useCanViewCollectionView");
var useTrackPaywallExperimentClick = function (group) {
    return function () {
        analytics_1.MBAnalytics.track('PaywallExperiment::Click', {
            group: group,
        });
    };
};
exports.useTrackPaywallExperimentClick = useTrackPaywallExperimentClick;
var useTrackPaywallExperimentView = function (group) {
    var isPaid = (0, useCanViewCollectionView_1.useCanViewCollectionView)();
    (0, react_1.useEffect)(function () {
        if (isPaid) {
            return;
        }
        analytics_1.MBAnalytics.track('PaywallExperiment::View', {
            group: group,
        });
    }, []);
};
exports.useTrackPaywallExperimentView = useTrackPaywallExperimentView;
var usePaywallExperiment = function () {
    var groupName = (0, __1.useFeatureFlag)(__1.PAYWALL_EXPERIMENT);
    return {
        isRedactedGroup: groupName === 'redacted',
        isBannerGroup: groupName === 'banner',
        groupName: groupName,
    };
};
exports.usePaywallExperiment = usePaywallExperiment;
