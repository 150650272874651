"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.linkPreviewRequester = exports.fetchLinkPreview = exports.UPDATE_LINK_PREVIEW = void 0;
// @ts-strict-ignore
var _ = __importStar(require("lodash"));
var client_1 = require("@shared/api/client");
var api_1 = require("@shared/api");
var store_1 = require("@shared/store");
var util_1 = require("../util");
exports.UPDATE_LINK_PREVIEW = 'CHAT:LINK_PREVIEW:UPDATE';
var fetchLinkPreview = function (url) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var preview;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                // TODO(fant): checking that store isn't null is a Storybook hack.
                // The store we (optionally) wrap components in isn't set to the
                // global @shared/store (because we can have one store per story).
                // Therefore we cannot use the required store. This is a problem in
                // message/bubble/me|other|dm
                if (!store_1.store || !!store_1.store.getState().chatLinkPreview.linkPreviewFromUrl[url]) {
                    return [2 /*return*/];
                }
                return [4 /*yield*/, exports.linkPreviewRequester.request(url)];
            case 1:
                preview = _a.sent();
                dispatch({ type: exports.UPDATE_LINK_PREVIEW, value: { url: url, preview: preview } });
                return [2 /*return*/];
        }
    });
}); }; };
exports.fetchLinkPreview = fetchLinkPreview;
var MBLinkPreviewDataRequester = /** @class */ (function () {
    function MBLinkPreviewDataRequester() {
        this.fetchRequests = {};
    }
    /*
          Store promises to fetch url data so we don't perform the same
          request more than once per link.
      */
    MBLinkPreviewDataRequester.prototype.request = function (url) {
        if (!!this.fetchRequests[url]) {
            return this.fetchRequests[url];
        }
        var promise = this.fetchFromAPI(url);
        this.fetchRequests[url] = promise;
        return promise;
    };
    /*
          TODO(fant): all of this logic should ideally be supplied by the backend.
          It's pretty bs that the client has to manually request all link details
          in separate requests...
      */
    MBLinkPreviewDataRequester.prototype.fetchFromAPI = function (url) {
        return __awaiter(this, void 0, void 0, function () {
            var res, baseUrl;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        return [4 /*yield*/, client_1.apolloClient.query({
                                query: api_1.Queries.link.details,
                                variables: { url: url },
                                // Note(fant): need to specify fetch policy when querying
                                // using apollo client directly (network vs cache)
                                fetchPolicy: 'network-only',
                            })];
                    case 1:
                        res = _a.sent();
                        // res.data.details is {} (empty object) for invalid urls
                        // If we get an url that fails, we try to request details
                        // from the page's main url (ex github.com instead of
                        // github.com/townsquared). In this way, we can create
                        // some previews even though we can't get it for the
                        // specific url.
                        if (!res.data.details || _.isEmpty(res.data.details)) {
                            baseUrl = util_1.MBLinkPreviewUtil.baseUrlFromUrl(url);
                            if (!baseUrl || url === baseUrl) {
                                return [2 /*return*/, null];
                            }
                            return [2 /*return*/, this.fetchFromAPI(baseUrl)];
                        }
                        return [2 /*return*/, {
                                // We return the url from the API, which will eg have prefixed
                                // "http://" if it's missing (eg for lol.com => http://lol.com)
                                // If this returns null (e.g. for Google Drive urls - 503?),
                                // we return the provided url. The provided url will fail if
                                url: url.indexOf('http') !== 0 ? "http://".concat(url) : url,
                                title: res.data.details.title,
                                description: res.data.details.description,
                                imageUrl: res.data.details.thumbnail_url,
                                imageWidth: res.data.details.thumbnail_width,
                                imageHeight: res.data.details.thumbnail_height,
                            }];
                }
            });
        });
    };
    return MBLinkPreviewDataRequester;
}());
exports.linkPreviewRequester = new MBLinkPreviewDataRequester();
