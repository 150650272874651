exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-2!../../../../node_modules/postcss-loader/src/index.js??postcss!../../composes.css"), "");
// Module
exports.push([module.id, ".q0w85J8lv8191vaJUZJ_N {\n  height: 100%;\n  background-color: #F8F8F8;\n  padding: 32px 0;\n  overflow: scroll;\n}\n\n._1a0ky9vEiw23EfwKanGPO5 {\n  color: #EB0902;\n  text-align: center;\n  font-size: 0.875rem;\n  padding-top: 24px;\n}\n\n._3Q3HbKyQBlkS7-8vBEgmfY {\n  margin: 24px auto;\n  background-color: #FFF;\n  border: 1px solid #E9E9E9;\n}\n\n._2vta7aJagMCpPke5rcv4oj {\n  border-left: none;\n  border-right: none;\n  width: 100%;\n}\n\n._2tT6rTpN7pRvhX9Svxb3Lw {\n  width: 560px;\n  max-height: 22rem; /* Rows are 4rem so 4.5 rows */\n}\n\n._5PciupbTNF-AtOE1rHqov {\n  color: #242424;\n  font-size: 0.875rem;\n  padding-bottom: 16px;\n}\n\n._3DqRdPl62bVWvj4RL-iRx_ {\n  margin: 32px auto 16px;\n  text-align: center;\n}\n\n._3KjLbjiXl09T0x8T4Wa0AS {\n  width: 100%;\n}\n\n._3K_USz8UfBj8Y2uxpXR5DD {\n  width: 560px;\n}\n\n._2h52MkgsA4eA5ugHJowYg7 {\n  margin: 0 16px 24px;\n  text-align: center;\n}\n\n._3cPRNB5Xj9W1P361MU_qUH {\n  color: #656565;\n  font-size: 0.875rem;\n}\n\n._3cPRNB5Xj9W1P361MU_qUH:hover {\n  cursor: pointer;\n  text-decoration: underline;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "q0w85J8lv8191vaJUZJ_N",
	"error": "_1a0ky9vEiw23EfwKanGPO5",
	"list": "_3Q3HbKyQBlkS7-8vBEgmfY",
	"listFullWidth": "_2vta7aJagMCpPke5rcv4oj _3Q3HbKyQBlkS7-8vBEgmfY",
	"listInContainer": "_2tT6rTpN7pRvhX9Svxb3Lw _3Q3HbKyQBlkS7-8vBEgmfY " + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-2!../../../../node_modules/postcss-loader/src/index.js??postcss!../../composes.css").locals["scroller"] + "",
	"text": "_5PciupbTNF-AtOE1rHqov",
	"footer": "_3DqRdPl62bVWvj4RL-iRx_",
	"footerFullWidth": "_3KjLbjiXl09T0x8T4Wa0AS _3DqRdPl62bVWvj4RL-iRx_",
	"footerInContainer": "_3K_USz8UfBj8Y2uxpXR5DD _3DqRdPl62bVWvj4RL-iRx_",
	"logoutLinkWrapper": "_2h52MkgsA4eA5ugHJowYg7",
	"logoutLink": "_3cPRNB5Xj9W1P361MU_qUH"
};