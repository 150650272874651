"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOnDragEnd = void 0;
// @ts-strict-ignore
var react_1 = require("react");
var utils_1 = require("@shared/components/blocks/utils");
var hooks_1 = require("@shared/scenes/cards/form/hooks");
var cardPagination_1 = require("@shared/api/providers/cardPagination");
var hooks_2 = require("@shared/components/blocks/lib/inputRecurrence/hooks");
var hooks_3 = require("../hooks");
var cardList_1 = require("./components/calendar/cells/cardList");
function useOnDragEnd(cells, container) {
    var _this = this;
    var getCard = (0, react_1.useContext)(cardPagination_1.CardPaginationStateContext).getCard;
    var updateCard = (0, hooks_1.useUpdateCardMutation)();
    var updateCardLocally = (0, hooks_1.useUpdateCardLocally)();
    var resolveCardDefinition = (0, hooks_3.useResolveCardDefinition)();
    return (0, react_1.useCallback)(function (result) { return __awaiter(_this, void 0, void 0, function () {
        var sameSection, _a, rowIndex, columnIndex, getCardListCell, cell, cardId, card, cardDefinition, values, newValues, shouldRequestRecurrenceScope, requestRecurrencePromise, recurrenceUpdateScope, _b, _c;
        var _d, _e, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    sameSection = ((_d = result.destination) === null || _d === void 0 ? void 0 : _d.droppableId) === ((_e = result.source) === null || _e === void 0 ? void 0 : _e.droppableId);
                    if (result.reason !== 'DROP' || !result.destination || sameSection) {
                        return [2 /*return*/];
                    }
                    _a = (0, cardList_1.getIndexesFromDroppableId)(result.destination.droppableId), rowIndex = _a[0], columnIndex = _a[1];
                    getCardListCell = function (cell) { return !!cell['cards']; };
                    cell = [cells[rowIndex][columnIndex]].find(getCardListCell);
                    if (!cell) {
                        return [2 /*return*/];
                    }
                    cardId = (0, cardList_1.getCardIdFromDraggableId)(result.draggableId);
                    card = getCard(cardId);
                    cardDefinition = resolveCardDefinition(card.cardDefinitionId);
                    if (!cardDefinition) {
                        console.warn('useDropCard: could not resolve card definition', card.cardDefinitionId);
                        return [2 /*return*/];
                    }
                    values = (0, utils_1.extractAllFields)(card, cardDefinition === null || cardDefinition === void 0 ? void 0 : cardDefinition.definition);
                    newValues = (_f = cell.onDropCard) === null || _f === void 0 ? void 0 : _f.call(cell, values);
                    shouldRequestRecurrenceScope = !!card.recurrence;
                    requestRecurrencePromise = function () {
                        return Promise.resolve(updateCardLocally(card, newValues)).then(function () {
                            return (0, hooks_2.requestRecurrenceScope)(container);
                        });
                    };
                    _g.label = 1;
                case 1:
                    _g.trys.push([1, 5, , 6]);
                    if (!shouldRequestRecurrenceScope) return [3 /*break*/, 3];
                    return [4 /*yield*/, requestRecurrencePromise()];
                case 2:
                    _b = _g.sent();
                    return [3 /*break*/, 4];
                case 3:
                    _b = 'ENTITY';
                    _g.label = 4;
                case 4:
                    recurrenceUpdateScope = _b;
                    updateCard(card, __assign(__assign({}, newValues), { recurrenceUpdateScope: recurrenceUpdateScope }));
                    return [3 /*break*/, 6];
                case 5:
                    _c = _g.sent();
                    // Reverts the optmistic update if use canceled the recurrence prompt
                    updateCardLocally(card, values);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); }, [cells, getCard, resolveCardDefinition, updateCardLocally, container, updateCard]);
}
exports.useOnDragEnd = useOnDragEnd;
