"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGroup = void 0;
var react_1 = require("react");
var ControlsProvider_1 = require("@shared/components/filter/state/ControlsProvider");
var useGroup = function () {
    var _a = (0, react_1.useContext)(ControlsProvider_1.ControlsContext), groups = _a.groups, setGroupOption = _a.setGroupOption, availableGroups = _a.availableGroups, selectedGroups = _a.selectedGroups, setSelectedGroups = _a.setSelectedGroups, resetGroups = _a.resetGroups;
    return {
        availableGroups: availableGroups,
        selectedGroups: selectedGroups,
        setSelectedGroups: setSelectedGroups,
        selectedGroupOptions: groups,
        setGroupOption: setGroupOption,
        resetGroups: resetGroups,
    };
};
exports.useGroup = useGroup;
