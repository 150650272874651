"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setStore = exports.store = void 0;
/*
    In shared components, we want to be able to run access the store
    in some actions without having access to getState.
*/
exports.store = null;
/*
    To use the apollo client, we need to get it supplied by where it
    is configured in web/native. We add auth headers etc there and
    want to use the exact same client as is being used there. This
    semi-hack is a way to "send" that client object over into the
    shared component world so that we can use it freely here.
    Note: this is expected to be called before apolloClient (above)
    is used by shared components.
*/
function setStore(__store) {
    exports.store = __store;
}
exports.setStore = setStore;
