"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.pickerPositionFromButtonPosition = void 0;
var dimensions_1 = require("@shared/util/dimensions");
var styles_1 = require("../styles");
/*
    We want to position the reaction picker above the
    button that triggered it showing. However, we don't want
    that parts of the picker become invisible because they
    overflow the screen.
*/
function pickerPositionFromButtonPosition(buttonPosition, 
// Padding between picker and button below (useful eg when there is a chat bubble inbetween)
bottomMargin) {
    if (bottomMargin === void 0) { bottomMargin = 0; }
    var centerX = buttonPosition.x + buttonPosition.width / 2;
    var width = styles_1.UnvalidatedStyles.pickerWidth;
    var defaultStyle = { position: 'absolute' };
    if (buttonPosition.y + bottomMargin > styles_1.UnvalidatedStyles.pickerHeight) {
        var bottom = dimensions_1.MBDimensions.height - buttonPosition.y + bottomMargin;
        // We don't want the reaction picker to be colliding with the status bar
        // (eg when reacting to a message that is very far up on the screen).
        if (bottom + styles_1.UnvalidatedStyles.pickerHeight > dimensions_1.MBDimensions.height) {
            defaultStyle.top = 0;
        }
        else {
            defaultStyle.bottom = bottom;
        }
    }
    else {
        // When we show reactions for a message that is extremely tall (eg multiple images),
        // the picker at the top won't be visible when eg viewing the bottom of the message.
        // This will cause buttonPosition.y to be large and negative, and the picker would
        // render way above the screen's extent. Instead, we show the picker in the middle
        // of the screen.
        defaultStyle.bottom = dimensions_1.MBDimensions.height / 2;
    }
    if (centerX - width / 2 < 0) {
        return __assign(__assign({}, defaultStyle), { left: 0 });
    }
    if (centerX + width / 2 > dimensions_1.MBDimensions.width) {
        return __assign(__assign({}, defaultStyle), { right: 0 });
    }
    return __assign(__assign({}, defaultStyle), { left: centerX - width / 2 });
}
exports.pickerPositionFromButtonPosition = pickerPositionFromButtonPosition;
