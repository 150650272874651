"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardInputBlockQuestionSingleSelect = void 0;
var react_1 = __importStar(require("react"));
var form_1 = require("@shared/components/blocks/contexts/form");
var components_1 = require("@shared/components");
var i18n_1 = require("@shared/util/i18n");
var tagList_1 = require("@shared/components/blocks/lib/tags/tagList");
var styles_1 = require("@shared/styles");
var modal_1 = require("@shared/components/multiSelect/modal");
var lodash_1 = require("lodash");
var tags_1 = require("@shared/components/blocks/lib/tags");
var hooks_1 = require("@shared/components/blocks/lib/tags/hooks");
var tag_1 = require("@shared/components/blocks/lib/tags/tag");
var field_1 = require("@shared/components/blocks/lib/field");
var MBCardInputBlockQuestionSingleSelect = function (_a) {
    var fieldName = _a.fieldName, label = _a.label, _b = _a.max, max = _b === void 0 ? 1 : _b, trigger = _a.trigger, disabled = _a.disabled, onChange = _a.onChange;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var values = (0, react_1.useContext)(form_1.CardFormContext).values;
    var value = values[fieldName];
    var tagValues = (0, react_1.useMemo)(function () { return value || []; }, [value]);
    var _c = (0, hooks_1.useTagOptions)(fieldName), options = _c.options, loading = _c.loading;
    var optionValues = (0, react_1.useMemo)(function () { return options.map(function (opt) { return opt.slug; }); }, [options]);
    var labelExtractor = (0, react_1.useCallback)(function (val) { var _a; return (_a = (0, tags_1.getTagOption)(val, options)) === null || _a === void 0 ? void 0 : _a.label; }, [options]);
    var match = (0, react_1.useCallback)(function (val, query, exact) {
        var extractedLabel = labelExtractor(val);
        if (exact) {
            return extractedLabel.toLowerCase() === query.toLowerCase();
        }
        return (0, lodash_1.includes)(extractedLabel.toLowerCase(), query.toLowerCase());
    }, [labelExtractor]);
    var renderItem = (0, react_1.useCallback)(function (val) {
        var option = (0, tags_1.getTagOption)(val, options);
        return (react_1.default.createElement(components_1.MBView, { row: true, paddingLeft: 4, centerVertically: true, flex: true },
            react_1.default.createElement(tag_1.Tag, { color: option.colorName }, (0, field_1.defaultFieldPresenter)(option.label, 'text', 'body1', colors.foregroundActive))));
    }, [colors, options]);
    var content = loading ? (react_1.default.createElement(components_1.MBLoadingIndicator, null)) : (Boolean(tagValues === null || tagValues === void 0 ? void 0 : tagValues.length) && (react_1.default.createElement(components_1.MBView, { row: true, wrap: true, style: { flexWrap: 'wrap', maxWidth: '100%' }, paddingBottom: 1 },
        react_1.default.createElement(tagList_1.TagList, { fieldName: fieldName, block: {
                names: [fieldName],
                type: 'tags',
            }, tagValues: tagValues, textColor: colors.foregroundActive }))));
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.MBView, { paddingBottom: 2 },
            react_1.default.createElement(components_1.MBTextBody1, { flex: true }, label)),
        react_1.default.createElement(components_1.MBView, { row: true, paddingBottom: 1 }, disabled ? (content) : (react_1.default.createElement(modal_1.MBMultiSelectModal, { label: (0, i18n_1.i18n)('card.blocks.question.answerButtonLabel'), onChange: onChange, max: max, renderItem: renderItem, values: optionValues, selectedValues: tagValues, labelExtractor: labelExtractor, match: match, archivedValues: [], itemLabel: (0, i18n_1.i18n)('card.blocks.tagsInput.itemLabel'), itemLabelPlural: (0, i18n_1.i18n)('card.blocks.tagsInput.itemLabelPlural'), errorLabel: (0, i18n_1.i18n)('errors.tag.enterName'), itemsEditable: false, trigger: trigger }, content)))));
};
exports.MBCardInputBlockQuestionSingleSelect = MBCardInputBlockQuestionSingleSelect;
exports.MBCardInputBlockQuestionSingleSelect.displayName = 'MBCardInputBlockQuestionSingleSelect';
