"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReservedIdFieldOption = void 0;
var ReservedIdFieldOption = /** @class */ (function () {
    function ReservedIdFieldOption(title, icon, component) {
        this.title = title;
        this.icon = icon;
        this.component = component;
    }
    ReservedIdFieldOption.prototype.matchesComponent = function (component, _reservedIds) {
        return this.component.id === component.id && this.component.type === component.type;
    };
    return ReservedIdFieldOption;
}());
exports.ReservedIdFieldOption = ReservedIdFieldOption;
