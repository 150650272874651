"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddSubformRow = exports.SubFormOptionsChildren = void 0;
// @ts-strict-ignore
var components_1 = require("@shared/components");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var styles_1 = require("@shared/styles");
var hooks_2 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var ChannelPermissionsContext_1 = require("@shared/util/permissions/ChannelPermissionsContext");
var react_1 = __importStar(require("react"));
var divider_1 = require("./divider");
var SubFormOptionsChildren = function (_a) {
    var _b;
    var component = _a.component;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var channelId = (0, react_1.useContext)(ChannelPermissionsContext_1.ChannelPermissionsContext).channelId;
    var channel = (0, hooks_2.useGetChannel)({ channelId: channelId });
    var navigator = (0, useNavigator_1.useNavigator)();
    var channelContext = (0, react_1.useContext)(channel_1.CardChannelContext);
    var onDeletePress = (0, react_1.useCallback)(function () {
        navigator.navigate('SubformsList', { channelSlug: channel === null || channel === void 0 ? void 0 : channel.slug });
    }, [channel, navigator]);
    var onRowPress = (0, react_1.useCallback)(function (subformWorkflowTemplateId, subformComponentId) {
        var _a;
        navigator.navigate('WorkflowEdit', {
            channelSlug: (_a = channelContext === null || channelContext === void 0 ? void 0 : channelContext.channel) === null || _a === void 0 ? void 0 : _a.slug,
            workflowId: subformWorkflowTemplateId,
            queryString: {
                originWorkflowTemplateId: channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId,
                originComponentId: subformComponentId,
            },
        });
    }, [navigator, (_b = channelContext === null || channelContext === void 0 ? void 0 : channelContext.channel) === null || _b === void 0 ? void 0 : _b.slug, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId]);
    var onAdd = (0, react_1.useCallback)(function () {
        var _a;
        navigator.navigate('SubformsList', {
            channelSlug: (_a = channelContext === null || channelContext === void 0 ? void 0 : channelContext.channel) === null || _a === void 0 ? void 0 : _a.slug,
        });
    }, [navigator, channelContext]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 1 },
            react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('workflowTemplate.subformSet'))),
        component.workflowTemplateIds.map(function (id) { return (react_1.default.createElement(react_1.default.Fragment, { key: id },
            react_1.default.createElement(SubFormOption, { subformWorkflowTemplateId: id, subformComponentId: component.id, onRowPress: onRowPress, onDeletePress: onDeletePress }))); }),
        react_1.default.createElement(exports.AddSubformRow, { onAdd: onAdd }),
        react_1.default.createElement(components_1.MBView, { paddingHorizontal: 2, paddingTop: 1 },
            react_1.default.createElement(divider_1.Divider, null))));
};
exports.SubFormOptionsChildren = SubFormOptionsChildren;
var SubFormOption = function (_a) {
    var subformWorkflowTemplateId = _a.subformWorkflowTemplateId, subformComponentId = _a.subformComponentId, onRowPress = _a.onRowPress, onDeletePress = _a.onDeletePress;
    var _b = (0, hooks_1.useWorkflowTemplate)(subformWorkflowTemplateId), workflowTemplate = _b.workflowTemplate, loading = _b.loading;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var name = (0, react_1.useMemo)(function () {
        return loading ? '' : workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.name;
    }, [workflowTemplate, loading]);
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: function () { return onRowPress(subformWorkflowTemplateId, subformComponentId); } },
        react_1.default.createElement(components_1.MBHover, { hoverStyle: { backgroundColor: colors.input.backgroundInactive }, centerVertically: true },
            react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, paddingVertical: 1, paddingHorizontal: 3 },
                react_1.default.createElement(components_1.MBIconV2, { name: "list", size: 22, color: colors.foregroundActive }),
                react_1.default.createElement(components_1.MBView, { fill: true, centerVertically: true, paddingLeft: 1 }, loading ? (react_1.default.createElement(components_1.MBLoadingIndicator, null)) : (react_1.default.createElement(components_1.MBTextBody1, { color: colors.foregroundActive }, name))),
                react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: function () {
                        onDeletePress();
                    } },
                    react_1.default.createElement(components_1.MBIconV2, { name: "exit-small", size: 22 }))))));
};
var AddSubformRow = function (_a) {
    var onAdd = _a.onAdd;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onAdd },
        react_1.default.createElement(components_1.MBHover, { hoverStyle: { backgroundColor: colors.input.backgroundInactive }, centerVertically: true },
            react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, paddingVertical: 1, paddingHorizontal: 3 },
                react_1.default.createElement(components_1.MBIconV2, { name: "plus", size: 22, color: colors.foregroundActive }),
                react_1.default.createElement(components_1.MBView, { fill: true, centerVertically: true, paddingLeft: 1 },
                    react_1.default.createElement(components_1.MBTextBody1, { color: colors.foregroundActive }, (0, i18n_1.i18n)('workflowTemplate.createNewSubform')))))));
};
exports.AddSubformRow = AddSubformRow;
