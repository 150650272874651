"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChatAttachmentFallbackSquare = exports.MBChatAttachmentFallback = void 0;
var React = __importStar(require("react"));
var _ = __importStar(require("lodash"));
var linking_1 = require("@shared/util/linking");
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var styles_2 = require("./styles");
/*
    Default attachment preview when in a chat thread. A thin list row containing
    a generic attachment icon and the attachment's name.
*/
var MBChatAttachmentFallback = /** @class */ (function (_super) {
    __extends(MBChatAttachmentFallback, _super);
    function MBChatAttachmentFallback() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onPress = function () {
            linking_1.MBLinking.open(_this.props.attachment.url);
        };
        return _this;
    }
    MBChatAttachmentFallback.prototype.render = function () {
        var _a = this.props, attachment = _a.attachment, progress = _a.progress, style = _a.style, isSending = _a.isSending;
        return (React.createElement(components_1.MBTouchableOpacity, { onPress: this.onPress, style: style },
            React.createElement(components_1.MBView, { row: true, padding: "small", centerVertically: true },
                React.createElement(components_1.MBIcon, { noPadding: true, size: "small", color: styles_1.Colors.v2.purple, source: require('@shared/assets/icons/v2/file.png') }),
                React.createElement(components_1.MBText, { numberOfLines: 1 }, attachment.name),
                isSending && !_.isUndefined(progress) && (React.createElement(components_1.MBCircularProgressBar, { size: "small", progress: progress })))));
    };
    return MBChatAttachmentFallback;
}(React.PureComponent));
exports.MBChatAttachmentFallback = MBChatAttachmentFallback;
/*
    Square version of the default attachment preview. Used eg in chat input attachment list.
*/
var MBChatAttachmentFallbackSquare = function (_a) {
    var attachment = _a.attachment, style = _a.style;
    return (React.createElement(components_1.MBView, { padding: "small", center: true, style: [style, styles_2.Styles.square] },
        React.createElement(components_1.MBIcon, { size: "large", noPadding: true, color: styles_1.Colors.v2.purple, source: require('@shared/assets/icons/v2/file.png') }),
        React.createElement(components_1.MBText, { center: true, style: styles_2.UnvalidatedStyles.fallbackFileNameText }, attachment.name)));
};
exports.MBChatAttachmentFallbackSquare = MBChatAttachmentFallbackSquare;
