"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateFileUploadProgress = exports.uploadFile = exports.signFileForUpload = exports.UPDATE_FILE_UPLOAD_PROGRESS = void 0;
var api_1 = require("@shared/api");
var store_1 = require("@shared/store");
var _ = __importStar(require("lodash"));
var ms = __importStar(require("milliseconds"));
var react_native_1 = require("react-native");
exports.UPDATE_FILE_UPLOAD_PROGRESS = 'UPLOAD:UPDATE_FILE_UPLOAD_PROGRESS';
var signFileForUpload = function (client, file) { return __awaiter(void 0, void 0, void 0, function () {
    var fileName, mimeType, _a, uploadUrl, fields;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                fileName = ((_b = file.name) === null || _b === void 0 ? void 0 : _b.replace(/[^a-z0-9._-]+/gi, '')) || 'file';
                mimeType = file.contentType || 'application/octet-stream';
                return [4 /*yield*/, client.mutate({
                        mutation: api_1.Mutations.business.createMedia,
                        variables: {
                            input: {
                                fileName: fileName,
                                mimeType: mimeType,
                            },
                        },
                    })];
            case 1:
                _a = (_c.sent()).data.createMedia.presignedPost, uploadUrl = _a.url, fields = _a.fields;
                return [2 /*return*/, {
                        uploadUrl: uploadUrl,
                        fields: fields,
                    }];
        }
    });
}); };
exports.signFileForUpload = signFileForUpload;
/*
    Upload a file (chat message attachment) through the API. Returns the full
    public url of the attachment.
*/
var uploadFile = function (client, file) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        // Make the file loading indicator show right away
        (0, exports.updateFileUploadProgress)(file.id, 0);
        return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
                var formData, policy, uploadUrl, fields, blob, xhr;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            formData = new FormData();
                            return [4 /*yield*/, (0, exports.signFileForUpload)(client, file).catch(function () { return null; })];
                        case 1:
                            policy = _a.sent();
                            if (!policy) {
                                return [2 /*return*/, reject()];
                            }
                            uploadUrl = policy.uploadUrl, fields = policy.fields;
                            Object.keys(fields).forEach(function (key) {
                                formData.append(key, fields[key]);
                            });
                            formData.append('Content-Type', file.contentType);
                            if (!(react_native_1.Platform.OS === 'web')) return [3 /*break*/, 3];
                            return [4 /*yield*/, fetch(file.url).then(function (res) { return res.blob(); })];
                        case 2:
                            blob = _a.sent();
                            formData.append('file', blob);
                            return [3 /*break*/, 4];
                        case 3:
                            // https://github.com/facebook/react-native/issues/2115
                            // @ts-ignore
                            formData.append('file', { uri: file.url, name: file.name, type: file.contentType });
                            _a.label = 4;
                        case 4:
                            xhr = new XMLHttpRequest();
                            // For reference:
                            // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Using_XMLHttpRequest
                            // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/upload
                            xhr.upload.addEventListener('progress', _.throttle(function (e) {
                                // Get upload progress (https://gist.github.com/domenic/2138293)
                                if (e.lengthComputable) {
                                    var progress = e.loaded / e.total;
                                    (0, exports.updateFileUploadProgress)(file.id, progress);
                                }
                            }, ms.seconds(0.2)));
                            xhr.onload = function () {
                                if (xhr.status !== 204) {
                                    reject();
                                }
                                resolve("".concat(uploadUrl).concat(fields['Key']));
                            };
                            xhr.addEventListener('error', reject);
                            xhr.addEventListener('abort', reject);
                            xhr.open('POST', uploadUrl, true);
                            xhr.send(formData);
                            return [2 /*return*/];
                    }
                });
            }); })];
    });
}); };
exports.uploadFile = uploadFile;
var updateFileUploadProgress = function (fileKey, progress) {
    return store_1.store.dispatch({ type: exports.UPDATE_FILE_UPLOAD_PROGRESS, value: { fileKey: fileKey, progress: progress } });
};
exports.updateFileUploadProgress = updateFileUploadProgress;
