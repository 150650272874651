"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkActionsMenu = void 0;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var menuRow_1 = require("../bulk/components/menuRow");
var i18n_1 = require("@shared/util/i18n");
var hooks_1 = require("./hooks");
function BulkActionsMenu(_a) {
    var workflowTemplateId = _a.workflowTemplateId, calendarDateComponentId = _a.calendarDateComponentId, nav = _a.nav;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var onPressDuplicate = (0, hooks_1.useOnPressDuplicate)({ workflowTemplateId: workflowTemplateId });
    var onPressDelete = (0, hooks_1.useOnPressDelete)({ workflowTemplateId: workflowTemplateId });
    var showDuplicateToMenuItem = !!calendarDateComponentId;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.MBView, { paddingVertical: 1 },
            react_1.default.createElement(menuRow_1.MBMenuRow, { image: react_1.default.createElement(components_1.MBIconV2, { name: "duplicate-squares", color: colors.foregroundActive, size: (0, components_1.grid)(4) }), title: (0, i18n_1.i18n)('card.menu.actions.duplicate'), onPress: onPressDuplicate, isHidingPressableIndicator: true }),
            showDuplicateToMenuItem && (react_1.default.createElement(menuRow_1.MBMenuRow, { image: react_1.default.createElement(components_1.MBIconV2, { name: "calendar", color: colors.foregroundActive, size: (0, components_1.grid)(4) }), title: (0, i18n_1.i18n)('card.actions.duplicateTo'), onPress: nav.showDuplicateToRoute, icon: react_1.default.createElement(components_1.MBIconV2, { name: "arrow-point-right-heavy", color: colors.foregroundInactive, size: (0, components_1.grid)(2), style: { marginLeft: (0, components_1.grid)(2) } }), skipWrappingIcon: true })),
            react_1.default.createElement(menuRow_1.MBMenuRow, { title: (0, i18n_1.i18n)('common.delete'), onPress: onPressDelete, image: react_1.default.createElement(components_1.MBIconV2, { name: "trash", color: colors.foregroundActive, size: (0, components_1.grid)(4) }), isHidingPressableIndicator: true }))));
}
exports.BulkActionsMenu = BulkActionsMenu;
