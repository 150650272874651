"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubformFieldOption = void 0;
var hooks_1 = require("../../hooks");
var SubformFieldOption = /** @class */ (function () {
    function SubformFieldOption(title, icon, component) {
        this.title = title;
        this.icon = icon;
        this.component = component;
    }
    SubformFieldOption.prototype.matchesComponent = function (component, _reservedIds) {
        return (0, hooks_1.isSubformComponent)(component);
    };
    return SubformFieldOption;
}());
exports.SubformFieldOption = SubformFieldOption;
