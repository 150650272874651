"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetPersonDataFromSelection = void 0;
var lodash_1 = require("lodash");
var hooks_1 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var useGetPersonDataFromSelection = function (selections) {
    var users = (0, hooks_1.useAllOrgUsers)();
    var operator = 'contains';
    var operatorLabel = (0, i18n_1.i18n)('card.actions.filters.fields.person.operator.contains');
    if ((0, lodash_1.isEmpty)(users)) {
        return {
            operator: operator,
            operatorLabel: operatorLabel,
            selections: [],
        };
    }
    if (selections === 'empty') {
        return {
            operator: 'empty',
            operatorLabel: (0, i18n_1.i18n)('card.actions.filters.fields.person.operator.empty'),
        };
    }
    if (selections === 'currentUser') {
        return {
            operator: 'currentUser',
            operatorLabel: (0, i18n_1.i18n)('card.actions.filters.fields.person.operator.currentUser'),
        };
    }
    var personSelections = selections.map(function (_a) {
        var value = _a.value, label = _a.label;
        if (value === 'empty') {
            operator = 'empty';
            operatorLabel = (0, i18n_1.i18n)('card.actions.filters.fields.person.operator.empty');
            return {
                value: value,
                label: label,
            };
        }
        if (value === 'currentUser') {
            operator = 'currentUser';
            operatorLabel = (0, i18n_1.i18n)('card.actions.filters.fields.person.operator.currentUser');
            return {
                value: value,
            };
        }
        var user = users === null || users === void 0 ? void 0 : users.find(function (u) { return u.id === value; });
        return {
            value: value,
            label: label || (user === null || user === void 0 ? void 0 : user.fullName) || '',
            user: user,
        };
    });
    return {
        operator: operator,
        operatorLabel: operatorLabel,
        selections: personSelections,
    };
};
exports.useGetPersonDataFromSelection = useGetPersonDataFromSelection;
