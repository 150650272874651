"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBSelectChannelsList = void 0;
var React = __importStar(require("react"));
var _ = __importStar(require("lodash"));
var components_1 = require("@shared/components");
var styles_1 = require("./styles");
var MBSelectableChannelRow = /** @class */ (function (_super) {
    __extends(MBSelectableChannelRow, _super);
    function MBSelectableChannelRow() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onPress = function () { return _this.props.onPress(_this.props.channel); };
        return _this;
    }
    MBSelectableChannelRow.prototype.render = function () {
        return (React.createElement(components_1.MBChannelRow, { channel: this.props.channel, onPress: this.onPress, icon: React.createElement(components_1.MBView, { fill: true, center: true },
                React.createElement(components_1.MBSelectableDot, { selected: this.props.selected })) }));
    };
    return MBSelectableChannelRow;
}(React.PureComponent));
/*
    TODO(fant): use MBFlatList
*/
var MBSelectChannelsList = /** @class */ (function (_super) {
    __extends(MBSelectChannelsList, _super);
    function MBSelectChannelsList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = { query: '' };
        _this.onSearch = function (query) { return _this.setState({ query: query }); };
        _this.onPressChannel = function (channel) {
            if (!_this.props.onSelect) {
                return;
            }
            // TODO(fant): implement multiselect logic (see MBSelectUsersList)
            _this.props.onSelect([channel]);
        };
        return _this;
    }
    MBSelectChannelsList.prototype.channels = function () {
        var query = this.state.query;
        var channels = this.props.channels;
        if (!query) {
            return channels;
        }
        return channels.filter(function (c) { return _.default(c.name.toLowerCase()).includes(query.toLowerCase()); });
    };
    MBSelectChannelsList.prototype.render = function () {
        var _this = this;
        var channels = this.channels();
        return (React.createElement(components_1.MBView, { fill: true },
            React.createElement(components_1.MBView, { padding: "small", style: styles_1.Styles.whiteBackground },
                React.createElement(components_1.MBSearchBar, { initialValue: this.state.query, onChangeText: this.onSearch })),
            React.createElement(components_1.MBScrollView, null, channels.map(function (channel) { return (React.createElement(MBSelectableChannelRow, { key: channel.id, channel: channel, onPress: _this.onPressChannel, selected: _.includes(_this.props.selectedSlugs, channel.slug) })); }))));
    };
    return MBSelectChannelsList;
}(React.PureComponent));
exports.MBSelectChannelsList = MBSelectChannelsList;
