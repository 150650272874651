"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBInCardsRollupCount = void 0;
// @ts-strict-ignore
var components_1 = require("@shared/components");
var hooks_1 = require("@shared/components/blocks/lib/cards/hooks");
var sheet_1 = require("@shared/components/blocks/lib/inCards/sheet");
var bottomSheet_1 = require("@shared/components/bottomSheet");
var modal_1 = require("@shared/components/multiSelect/modal");
var contentWrapper_1 = require("@shared/scenes/auth/contentWrapper");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var hooks_2 = require("@shared/util/permissions/hooks");
var react_1 = __importStar(require("react"));
var MBInCardsRollupCount = function (_a) {
    var entry = _a.entry, cardId = _a.cardId, channelId = _a.channelId;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var containerRef = (0, react_1.useRef)();
    var updatePermission = (0, hooks_2.usePermission)({ action: 'update', subject: 'Card', channelId: channelId });
    var _b = (0, react_1.useState)(false), showList = _b[0], setShowList = _b[1];
    var openLinkedCard = (0, hooks_1.useOpenLinkedCard)();
    var openNewRelationCard = (0, hooks_1.useOpenNewLinkedCard)({ cardId: cardId, externalChannelId: channelId });
    var closeSheet = (0, react_1.useCallback)(function () { return setShowList(false); }, [setShowList]);
    var onPressCard = (0, react_1.useCallback)(function (cards) {
        closeSheet();
        openLinkedCard(cards[0]);
    }, [openLinkedCard, closeSheet]);
    var onCreateFromSheet = (0, react_1.useCallback)(function (query) {
        closeSheet();
        openNewRelationCard({ name: query });
    }, [openNewRelationCard, closeSheet]);
    var onCreateFromAddButton = (0, react_1.useCallback)(function () {
        openNewRelationCard();
    }, [openNewRelationCard]);
    var fixedWidth = (0, contentWrapper_1.useIsDesktop)() ? modal_1.FIXED_WIDTH : null;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: function () { return setShowList(true); }, ref: containerRef, disabled: entry.cardCount === 0 },
            react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, style: { justifyContent: 'space-between' }, paddingVertical: 1 },
                react_1.default.createElement(components_1.MBChannelImage, { style: { width: (0, components_1.grid)(4), height: (0, components_1.grid)(4) }, channel: {
                        imageUrl: entry.channelImageUrl,
                        name: entry.channelName,
                        type: 'CARD_GENERIC',
                        users: undefined,
                        removedUsers: undefined,
                        isGeneralChannel: false,
                    } }),
                react_1.default.createElement(components_1.MBView, { paddingLeft: 1 },
                    react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, entry.channelName),
                    react_1.default.createElement(components_1.MBTextBody1, null, (0, i18n_1.i18n)('card.blocks.inCards.cards', { count: entry.cardCount }))),
                react_1.default.createElement(components_1.MBView, { flex: true }),
                updatePermission.enabled && (react_1.default.createElement(components_1.MBTouchableIcon, { onPress: onCreateFromAddButton, color: colors.foregroundActive, noPadding: true, source: require('@shared/assets/icons/v2/plus-circle.png') })))),
        showList && (react_1.default.createElement(bottomSheet_1.MBBottomSheet, { containerRef: containerRef, title: entry.channelName, layout: {
                offset: { x: (0, components_1.grid)(10), y: 0 },
                minWidth: fixedWidth === null || fixedWidth === void 0 ? void 0 : fixedWidth.width,
                minHeight: (0, components_1.grid)(48),
            }, onClose: function () { return setShowList(false); } },
            react_1.default.createElement(components_1.MBView, { style: fixedWidth, fill: true },
                react_1.default.createElement(sheet_1.InCardsSheet, { onPress: onPressCard, cardId: cardId, channelId: entry.channelId, channelName: entry.channelName, onAdd: onCreateFromSheet }))))));
};
exports.MBInCardsRollupCount = MBInCardsRollupCount;
