"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkActionsButton = void 0;
var i18n_1 = require("@shared/util/i18n");
var react_1 = __importStar(require("react"));
var hooks_1 = require("@shared/util/hooks");
var components_1 = require("@shared/components");
var titleBar_1 = require("@shared/components/bottomSheet/titleBar");
var styles_1 = require("@shared/styles");
var BulkActionsMenu_1 = require("./BulkActionsMenu");
var DuplicateToDateMenu_1 = require("./DuplicateToDateMenu");
var entitySelection_1 = require("@shared/api/providers/entitySelection");
var useBulkActionsNav_1 = require("./useBulkActionsNav");
function BulkActionsButton(_a) {
    var workflowTemplateId = _a.workflowTemplateId, calendarDateComponentId = _a.calendarDateComponentId;
    var nav = (0, useBulkActionsNav_1.useBulkActionsNav)();
    var _b = (0, hooks_1.useBoolean)(), isMenuOpen = _b[0], openMenu = _b[1], closeMenu = _b[2];
    var ref = react_1.default.useRef(null);
    var loading = (0, entitySelection_1.useEntitySelection)().loading;
    var renderTitleBar = (0, react_1.useCallback)(function () { return react_1.default.createElement(TitleBar, { nav: nav }); }, [nav]);
    return (react_1.default.createElement(components_1.MBView, null,
        react_1.default.createElement(components_1.MBButton, { disabled: loading, loading: loading, onPress: openMenu, rightIcon: "arrow-point-down-heavy", rightIconStyle: { paddingLeft: (0, components_1.grid)(1.5) }, rightIconSize: (0, components_1.grid)(2), theme: "normal", title: (0, i18n_1.i18n)('common.actions'), style: { paddingLeft: (0, components_1.grid)(3), paddingVertical: (0, components_1.grid)(2.5), borderRadius: (0, components_1.grid)(2.75) } }),
        react_1.default.createElement(components_1.MBView, { ref: ref }),
        isMenuOpen && !loading && (react_1.default.createElement(components_1.MBBottomSheet, { containerRef: ref, initialSize: "full", layout: {
                offset: { x: 0, y: (0, components_1.grid)(1) },
                minWidth: 280,
                maxWidth: 320,
            }, onClose: closeMenu, renderTitleBar: renderTitleBar },
            nav.isMainRoute && (react_1.default.createElement(BulkActionsMenu_1.BulkActionsMenu, { workflowTemplateId: workflowTemplateId, calendarDateComponentId: calendarDateComponentId, nav: nav })),
            nav.isDuplicateToRoute && calendarDateComponentId && (react_1.default.createElement(DuplicateToDateMenu_1.DuplicateToDateMenu, { workflowTemplateId: workflowTemplateId, calendarDateComponentId: calendarDateComponentId }))))));
}
exports.BulkActionsButton = BulkActionsButton;
function TitleBar(_a) {
    var nav = _a.nav;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    if (nav.isDuplicateToRoute) {
        return (react_1.default.createElement(components_1.MBView, null,
            react_1.default.createElement(titleBar_1.MBBottomSheetTitleBar, { title: (0, i18n_1.i18n)('card.actions.duplicateTo'), titleSize: (0, components_1.grid)(2), leftIcon: "arrow-point-left", leftIconSize: (0, components_1.grid)(4), onLeftIconPress: nav.showMainRoute, leftIconColor: colors.foregroundInactive })));
    }
    else {
        return null;
    }
}
