"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = void 0;
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
var webHeight = 78;
exports.Styles = react_native_1.StyleSheet.create({
    container: __assign({ backgroundColor: 'white', borderWidth: 1, borderColor: styles_1.Colors.gray.border }, react_native_1.Platform.select({ web: { height: webHeight } })),
    imageWeb: {
        width: webHeight - 2,
        height: webHeight - 2, // - 2 * border width
    },
    overflowHidden: { overflow: 'hidden' },
});
