"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLog = void 0;
var react_1 = require("react");
// Usage:
// useLog('myvalue', myValue);
function useLog(key, value) {
    (0, react_1.useEffect)(function () {
        console.log(key, value);
    }, [key, value]);
}
exports.useLog = useLog;
