"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBBulkActions = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var i18n_1 = require("@shared/util/i18n");
var bottomSheet_1 = require("@shared/components/bottomSheet");
var styles_1 = require("@shared/styles");
var duplicateOptions_1 = require("./duplicateOptions");
var titleBar_1 = require("@shared/components/bottomSheet/titleBar");
var menuRow_1 = require("./menuRow");
var useBulkActionsNav_1 = require("@shared/scenes/cards/entityBulkActions/useBulkActionsNav");
function MBBulkActions(props) {
    var showSheet = props.showSheet, deleteLoading = props.deleteLoading, duplicateLoading = props.duplicateLoading, setShowSheet = props.setShowSheet;
    var containerRef = (0, react_1.useRef)();
    var nav = (0, useBulkActionsNav_1.useBulkActionsNav)();
    var loading = deleteLoading || duplicateLoading;
    var renderTitleBar = (0, react_1.useCallback)(function () { return react_1.default.createElement(TitleBar, { nav: nav }); }, [nav]);
    return (react_1.default.createElement(react_native_1.View, null,
        react_1.default.createElement(components_1.MBButton, { disabled: loading, loading: loading, onPress: function () { return setShowSheet(true); }, rightIcon: "arrow-point-down-heavy", rightIconStyle: { paddingLeft: (0, components_1.grid)(1.5) }, rightIconSize: (0, components_1.grid)(2), theme: "normal", title: (0, i18n_1.i18n)('common.actions'), style: { paddingLeft: (0, components_1.grid)(3), paddingVertical: (0, components_1.grid)(2.5), borderRadius: (0, components_1.grid)(2.75) } }),
        react_1.default.createElement(react_native_1.View, { ref: containerRef }),
        showSheet && (react_1.default.createElement(bottomSheet_1.MBBottomSheet, { containerRef: containerRef, initialSize: "full", layout: {
                offset: { x: 0, y: (0, components_1.grid)(1) },
                minWidth: 320,
                maxWidth: 320,
            }, renderTitleBar: renderTitleBar, onClose: function () { return setShowSheet(false); } },
            nav.isMainRoute && react_1.default.createElement(MainSheetContent, __assign({}, props, { nav: nav })),
            nav.isDuplicateToRoute && react_1.default.createElement(DuplicateSheetContent, __assign({}, props))))));
}
exports.MBBulkActions = MBBulkActions;
function TitleBar(_a) {
    var nav = _a.nav;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    if (nav.isDuplicateToRoute) {
        return (react_1.default.createElement(components_1.MBView, null,
            react_1.default.createElement(titleBar_1.MBBottomSheetTitleBar, { title: (0, i18n_1.i18n)('card.actions.duplicateTo'), titleSize: (0, components_1.grid)(2), leftIcon: "arrow-point-left", leftIconSize: (0, components_1.grid)(4), onLeftIconPress: nav.showMainRoute, leftIconColor: colors.foregroundInactive })));
    }
    else {
        return null;
    }
}
function MainSheetContent(_a) {
    var selectedCount = _a.selectedCount, duplicateLoading = _a.duplicateLoading, onDuplicate = _a.onDuplicate, onDelete = _a.onDelete, onChangeDuplicateDate = _a.onChangeDuplicateDate, deleteLoading = _a.deleteLoading, nav = _a.nav;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var onClickDelete = function () {
        if (window.confirm((0, i18n_1.i18n)('card.actions.deleteConfirmationCount', { count: selectedCount }))) {
            onDelete();
        }
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_native_1.View, { style: { paddingTop: (0, components_1.grid)(2), paddingHorizontal: (0, components_1.grid)(3) } },
            react_1.default.createElement(components_1.MBTextOverline, null, (0, i18n_1.i18n)('card.menu.title'))),
        react_1.default.createElement(components_1.MBView, { paddingVertical: 1 },
            react_1.default.createElement(menuRow_1.MBMenuRow, { image: react_1.default.createElement(components_1.MBIconV2, { name: "duplicate-squares", color: colors.foregroundActive, size: (0, components_1.grid)(4) }), title: (0, i18n_1.i18n)('card.menu.actions.duplicate'), onPress: duplicateLoading ? undefined : function () { return onDuplicate(false); }, isHidingPressableIndicator: true }),
            !!onChangeDuplicateDate && (react_1.default.createElement(menuRow_1.MBMenuRow, { image: react_1.default.createElement(components_1.MBIconV2, { name: "duplicate-squares", color: colors.foregroundActive, size: (0, components_1.grid)(4) }), title: (0, i18n_1.i18n)('card.actions.duplicateTo'), onPress: duplicateLoading ? undefined : nav.showDuplicateToRoute, icon: react_1.default.createElement(components_1.MBIconV2, { name: "arrow-point-right-heavy", color: colors.foregroundInactive, size: (0, components_1.grid)(2) }), skipWrappingIcon: true })),
            react_1.default.createElement(menuRow_1.MBMenuRow, { title: (0, i18n_1.i18n)('common.delete'), onPress: !deleteLoading && onClickDelete, image: react_1.default.createElement(components_1.MBIconV2, { name: "trash", color: colors.foregroundActive, size: (0, components_1.grid)(4) }), isHidingPressableIndicator: true }))));
}
function DuplicateSheetContent(_a) {
    var selectedCount = _a.selectedCount, duplicateDate = _a.duplicateDate, onDuplicate = _a.onDuplicate, onChangeDuplicateDate = _a.onChangeDuplicateDate;
    return (react_1.default.createElement(duplicateOptions_1.MBDuplicateOptions, { selectedCount: selectedCount, selectedDate: duplicateDate, onDuplicate: function () { return onDuplicate(true); }, onSelectDate: onChangeDuplicateDate }));
}
