"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBFileDropListener = void 0;
// @ts-strict-ignore
var React = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var overlay_1 = require("./overlay");
var styles_2 = require("./styles");
/*
    This component listens to files being dragged over it. When a file is dragged over,
    we show an overlay. When the file is dropped we call this.props.onDrop to let the
    parent handle the file.

    Note: this has some hacky logic connected to this.dragEnterEventCount. For some
    reason, the form component behaves like this:

    onDragEnter
    onDragEnter
    onDragLeave

    when a file is dragged over it. This causes the isFileHoovering to flicked back and forth.
    Maybe because the state is being updated connected to the change? Anyway, we keep the
    counter to just change the hoover state when the file is actually not over the component
    anymore.
*/
var MBFileDropListener = exports.MBFileDropListener = /** @class */ (function (_super) {
    __extends(MBFileDropListener, _super);
    function MBFileDropListener() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = { isFileHoovering: false };
        _this.handleFileDragOver = function (event) { return event.preventDefault(); };
        _this.handleSubmit = function (event) { return event.preventDefault(); };
        _this.handleFileDragEnter = function (event) {
            event.preventDefault();
            if (!_this.state.isFileHoovering) {
                _this.dragEnterEventCount = 0;
                _this.setState({ isFileHoovering: true });
            }
            _this.dragEnterEventCount += 1;
        };
        _this.handleFileDragLeave = function (event) {
            event.preventDefault();
            _this.dragEnterEventCount -= 1;
            if (_this.dragEnterEventCount === 0) {
                _this.setState({ isFileHoovering: false });
            }
        };
        _this.handleFileDrop = function (event) {
            event.preventDefault();
            _this.setState({ isFileHoovering: false });
            var files = event.dataTransfer.files;
            if (files.length) {
                // files is of type FileList, which is a list but doesn't have
                // array functions (eg map). We convert it to an array to make
                // it more easily used
                _this.props.onFilesDropped(Array.from(files));
            }
        };
        return _this;
    }
    MBFileDropListener.prototype.render = function () {
        var _a = this.props, overlay = _a.overlay, restProps = __rest(_a, ["overlay"]);
        return (React.createElement("form", { style: styles_2.UnvalidatedStyles.form, onDragEnter: this.handleFileDragEnter, onDragLeave: this.handleFileDragLeave, onDragOver: this.handleFileDragOver, onDrop: this.handleFileDrop, onSubmit: this.handleSubmit },
            React.createElement(components_1.MBView, __assign({}, restProps)),
            this.state.isFileHoovering && (React.createElement(components_1.MBView, { style: styles_1.SharedStyles.absoluteCover, children: overlay }))));
    };
    MBFileDropListener.defaultProps = {
        overlay: React.createElement(overlay_1.MBFileDropListenerOverlay, null),
    };
    return MBFileDropListener;
}(React.PureComponent));
/*
Note(fant): this implementation would be cleaner. However, there is a
bug with this solution. When typing in the chat input after having
dragged files, the file selector might show.

export const MBFileDropListener: React.SFC<MBFileDropListenerProps> = ({
    children, autoFocus, acceptedContentTypes, acceptedExtensions, onFilesDropped, ...viewProps
}) => {
    const onDrop = React.useCallback(
        (files) => onFilesDropped(Array.from(files)),
        [onFilesDropped],
    );
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: acceptedContentTypes || acceptedExtensions,
    });

    React.useEffect(
        () => {
            const input = getInputProps() as any;
            if (autoFocus) {
                input.ref.current.click();
            }
        },
        [],
    );

    return (
        <MBView {...viewProps}>
            <div
                {...getRootProps() as any}
                style={{ flex: 1, alignSelf: 'stretch', display: 'flex', flexDirection: 'column' }}
            >
                <input {...getInputProps() as any} />
                {children}
                {isDragActive && (
                    <MBView
                        style={[this.props.style, SharedStyles.absoluteCover]}
                        children={<MBFileDropListenerOverlay />}
                    />
                )}
            </div>
        </MBView>
    );
};
*/
