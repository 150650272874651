"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COMPONENT_TYPE_ICON_MAP = exports.COMPONENT_TYPE_LABEL_MAP = exports.getIcon = exports.getLabel = void 0;
var i18n_1 = require("@shared/util/i18n");
var hooks_1 = require("./hooks");
var MULTI_LINE = 'multi-line';
function getLabel(component) {
    if ((0, hooks_1.isTagComponent)(component)) {
        return component.multiselect
            ? (0, i18n_1.i18n)('workflowTemplate.component.type.label.multiSelect')
            : (0, i18n_1.i18n)('workflowTemplate.component.type.label.singleSelect');
    }
    if ((0, hooks_1.isTextComponent)(component)) {
        return component.multiline
            ? (0, i18n_1.i18n)('workflowTemplate.component.type.label.longText')
            : (0, i18n_1.i18n)('workflowTemplate.component.type.label.shortText');
    }
    return exports.COMPONENT_TYPE_LABEL_MAP.get(component.type);
}
exports.getLabel = getLabel;
function getIcon(component) {
    if ((0, hooks_1.isTagComponent)(component)) {
        return component.multiselect ? 'list' : 'single-select-list';
    }
    if ((0, hooks_1.isTextComponent)(component)) {
        return component.multiline ? MULTI_LINE : 'title-t';
    }
    return exports.COMPONENT_TYPE_ICON_MAP.get(component.type);
}
exports.getIcon = getIcon;
exports.COMPONENT_TYPE_LABEL_MAP = new Map([
    ['ADDRESS', (0, i18n_1.i18n)('workflowTemplate.component.type.label.address')],
    ['AUDIT_CHECKBOX', (0, i18n_1.i18n)('workflowTemplate.component.type.label.auditCheckbox')],
    ['AUDIT_TAG', (0, i18n_1.i18n)('workflowTemplate.component.type.label.auditTag')],
    ['AUDIT_TEXT', (0, i18n_1.i18n)('workflowTemplate.component.type.label.auditText')],
    ['COMBINED_TAGS', (0, i18n_1.i18n)('workflowTemplate.component.type.label.combinedTags')],
    ['DATE', (0, i18n_1.i18n)('workflowTemplate.component.type.label.date')],
    ['EMAIL', (0, i18n_1.i18n)('workflowTemplate.component.type.label.email')],
    ['FILE', (0, i18n_1.i18n)('workflowTemplate.component.type.label.file')],
    ['GEOLOCATION', (0, i18n_1.i18n)('workflowTemplate.component.type.label.geolocation')],
    ['INPUT_BUTTON', (0, i18n_1.i18n)('workflowTemplate.component.type.label.button')],
    ['NUMBER', (0, i18n_1.i18n)('workflowTemplate.component.type.label.number')],
    ['PERSON', (0, i18n_1.i18n)('workflowTemplate.component.type.label.person')],
    ['RELATED_CARD', (0, i18n_1.i18n)('workflowTemplate.component.type.label.relatedCard')],
    ['RELATED_CARD_LOOKUP', (0, i18n_1.i18n)('workflowTemplate.component.type.label.relatedCardLookup')],
    ['SIGNATURE', (0, i18n_1.i18n)('workflowTemplate.component.type.label.signature')],
    ['STATIC_TEXT', (0, i18n_1.i18n)('workflowTemplate.component.type.label.staticText')],
    ['SUBFORM', (0, i18n_1.i18n)('workflowTemplate.component.type.label.subform')],
    ['TODO', (0, i18n_1.i18n)('workflowTemplate.component.type.label.todo')],
    ['TIME_TRACKER', (0, i18n_1.i18n)('workflowTemplate.component.type.label.timeTracker')],
    ['REFERENCED_IN', (0, i18n_1.i18n)('workflowTemplate.component.type.label.referencedIn')],
]);
exports.COMPONENT_TYPE_ICON_MAP = new Map([
    ['ADDRESS', 'pin-location'],
    ['AUDIT_CHECKBOX', 'check-task'],
    ['AUDIT_TAG', 'single-select-list'],
    ['AUDIT_TEXT', MULTI_LINE],
    ['DATE', 'calendar'],
    ['COMBINED_TAGS', 'tag'],
    ['EMAIL', 'envelope'],
    ['FILE', 'file'],
    ['GEOLOCATION', 'avatar-location'],
    ['INPUT_BUTTON', 'button-press'],
    ['NUMBER', 'hashtag'],
    ['PERSON', 'avatar'],
    ['RELATED_CARD', 'duplicate-squares'],
    ['RELATED_CARD_LOOKUP', 'search-look-up'],
    ['SIGNATURE', 'signature-ballpoint-pen'],
    ['STATIC_TEXT', MULTI_LINE],
    ['SUBFORM', 'list'],
    ['TODO', 'check-task'],
    ['TIME_TRACKER', 'time-stopwatch'],
    ['REFERENCED_IN', 'arrow-move-right'],
]);
