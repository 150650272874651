"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFilter = void 0;
var react_1 = require("react");
var ControlsProvider_1 = require("./ControlsProvider");
var useFilter = function () {
    var _a = (0, react_1.useContext)(ControlsProvider_1.ControlsContext), filters = _a.filters, setFilterOption = _a.setFilterOption, availableFilters = _a.availableFilters, selectedFilters = _a.selectedFilters, setSelectedFilters = _a.setSelectedFilters;
    return {
        availableFilters: availableFilters,
        selectedFilters: selectedFilters,
        setSelectedFilters: setSelectedFilters,
        selectedFilterOptions: filters,
        setFilterOption: setFilterOption,
    };
};
exports.useFilter = useFilter;
