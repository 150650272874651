"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChatActionButtons = void 0;
var container_1 = require("@shared/scenes/cards/persistentMenu/container");
var hooks_1 = require("@shared/util/hooks");
var hooks_2 = require("@shared/util/permissions/hooks");
var React = __importStar(require("react"));
var MBChatActionButtons = function (_a) {
    var channelSlug = _a.channelSlug, visible = _a.visible;
    var channel = (0, hooks_1.useChannel)(channelSlug);
    var createPermission = (0, hooks_2.usePermission)({
        action: 'create',
        subject: 'Card',
        channelId: channel.id,
    });
    // TODO(fant): we use the permission to create a card as
    // a proxy for if the persistent menu buttons are relevant.
    // Set this permission per button instead.
    if (createPermission.enabled) {
        return React.createElement(container_1.MBCardChatPersistentMenu, { visible: visible, channel: channel });
    }
    return null;
};
exports.MBChatActionButtons = MBChatActionButtons;
