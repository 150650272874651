"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useScreenSize = void 0;
var react_1 = require("react");
var react_native_1 = require("react-native");
function useScreenSize() {
    var width = (0, react_native_1.useWindowDimensions)().width;
    var screenSize = (0, react_1.useMemo)(function () {
        if (width <= 840) {
            return 'small';
        }
        else if (width <= 1126) {
            return 'medium';
        }
        return 'large';
    }, [width]);
    return screenSize;
}
exports.useScreenSize = useScreenSize;
