"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBSearchBar = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var bounceIn_1 = require("@shared/components/animation/bounceIn");
var styles_1 = require("@shared/styles");
var styles_2 = require("./styles");
var MBSearchBar = function (_a) {
    var forwardRef = _a.forwardRef, defaultValue = _a.value, style = _a.style, _b = _a.autoFocus, autoFocus = _b === void 0 ? false : _b, hideIcon = _a.hideIcon, keyboardType = _a.keyboardType, onChangeText = _a.onChangeText, onBlur = _a.onBlur, onFocus = _a.onFocus, onClear = _a.onClear, onSubmitEditing = _a.onSubmitEditing, _c = _a.placeholder, placeholder = _c === void 0 ? 'Search...' : _c, _d = _a.returnKeyType, returnKeyType = _d === void 0 ? 'search' : _d, otherProps = __rest(_a, ["forwardRef", "value", "style", "autoFocus", "hideIcon", "keyboardType", "onChangeText", "onBlur", "onFocus", "onClear", "onSubmitEditing", "placeholder", "returnKeyType"]);
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var textInput = (0, react_1.useRef)();
    var _e = (0, react_1.useState)(defaultValue || ''), value = _e[0], setValue = _e[1];
    var _f = (0, react_1.useState)(autoFocus), focused = _f[0], setFocused = _f[1];
    if (forwardRef) {
        forwardRef.current = textInput.current;
    }
    var handleBlur = (0, react_1.useCallback)(function () {
        setFocused(false);
        !!onBlur && onBlur();
    }, [setFocused, onBlur]);
    var handleFocus = (0, react_1.useCallback)(function () {
        setFocused(true);
        !!onFocus && onFocus();
    }, [setFocused, onFocus]);
    var handleChangeText = (0, react_1.useCallback)(function (updatedValue) {
        setValue(updatedValue);
        onChangeText(updatedValue);
    }, [setValue, onChangeText]);
    (0, react_1.useEffect)(function () { return setValue(defaultValue); }, [defaultValue]);
    // Make the text input focused whenever we click the search bar. Even if we click
    // on the icon or other non-textinput area, the keyboard should appear.
    var handlePress = (0, react_1.useCallback)(function () { return textInput.current.focus(); }, [textInput]);
    var handleClear = (0, react_1.useCallback)(function () {
        textInput.current.focus();
        handleChangeText('');
        !!onClear && onClear();
    }, [textInput, handleChangeText, onClear]);
    return (react_1.default.createElement(react_native_1.TouchableOpacity, { activeOpacity: 1, onPress: handlePress },
        react_1.default.createElement(components_1.MBView, __assign({}, otherProps, { center: true, row: true, style: [
                styles_2.Styles.container,
                {
                    backgroundColor: focused
                        ? colors.input.backgroundActive
                        : colors.input.backgroundInactive,
                },
                style,
            ] }),
            !hideIcon && (react_1.default.createElement(components_1.MBIcon, { source: require('@shared/assets/icons/v2/magnifying-glass.png'), color: colors.input.label })),
            !!hideIcon && react_1.default.createElement(components_1.MBView, { paddingLeft: "normal" }),
            react_1.default.createElement(components_1.MBView, { flex: true },
                react_1.default.createElement(react_native_1.TextInput, { ref: textInput, value: value, autoFocus: autoFocus, style: [
                        components_1.MBTypographyStylesRaw.body1,
                        styles_2.Styles.textInput,
                        { color: colors.input.foregroundActive },
                    ], onBlur: handleBlur, onFocus: handleFocus, onChangeText: handleChangeText, onSubmitEditing: onSubmitEditing, placeholder: placeholder, placeholderTextColor: colors.input.label, underlineColorAndroid: "transparent", returnKeyType: returnKeyType, keyboardType: keyboardType })),
            react_1.default.createElement(bounceIn_1.MBAnimationBounceIn, { disabled: !value },
                react_1.default.createElement(react_native_1.TouchableOpacity, { onPress: handleClear },
                    react_1.default.createElement(components_1.MBIcon, { color: colors.input.foregroundInactive, source: require('@shared/assets/icons/clear.png') }))))));
};
exports.MBSearchBar = MBSearchBar;
