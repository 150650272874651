"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowTemplateProvider = exports.WorkflowTemplateDispatchProvider = exports.useWorkflowDispatch = exports.WorkflowTemplateStateProvider = exports.useWorkflowTemplateState = void 0;
// @ts-strict-ignore
var context_1 = require("@shared/util/context");
var react_1 = __importStar(require("react"));
var reducer_1 = require("./reducer");
exports.useWorkflowTemplateState = (_a = (0, context_1.createCtx)(), _a[0]), exports.WorkflowTemplateStateProvider = _a[1];
exports.useWorkflowDispatch = (_b = (0, context_1.createCtx)(), _b[0]), exports.WorkflowTemplateDispatchProvider = _b[1];
function WorkflowTemplateProvider(_a) {
    var initialWorkflowTemplate = _a.initialWorkflowTemplate, onUpdate = _a.onUpdate, children = _a.children;
    var _b = (0, react_1.useReducer)(reducer_1.reducer, { workflowTemplate: initialWorkflowTemplate }), state = _b[0], dispatch = _b[1];
    (0, react_1.useEffect)(function () {
        onUpdate(state === null || state === void 0 ? void 0 : state.workflowTemplate);
    }, [onUpdate, state]);
    return (react_1.default.createElement(exports.WorkflowTemplateStateProvider, { value: state },
        react_1.default.createElement(exports.WorkflowTemplateDispatchProvider, { value: dispatch }, children)));
}
exports.WorkflowTemplateProvider = WorkflowTemplateProvider;
