"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultSubformSelection = void 0;
var components_1 = require("@shared/components");
var context_1 = require("@shared/scenes/workflows/context/context");
var useSubforms_1 = require("@shared/scenes/workflows/subforms/useSubforms");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var DefaultSubformSelection = function (_a) {
    var workflowTemplateId = _a.workflowTemplateId, component = _a.component;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _b = (0, useSubforms_1.useSubforms)({ workflowTemplateId: workflowTemplateId }), subforms = _b.subforms, loading = _b.loading;
    var dispatch = (0, context_1.useWorkflowDispatch)();
    var onPress = function (newWorkflowTemplateId) {
        var _a;
        var newDefault = ((_a = component.default) === null || _a === void 0 ? void 0 : _a.workflowTemplateId) === newWorkflowTemplateId ? '' : newWorkflowTemplateId;
        dispatch === null || dispatch === void 0 ? void 0 : dispatch({
            type: 'updateComponent',
            args: __assign(__assign({}, component), { default: (0, lodash_1.isEmpty)(newDefault) ? null : { workflowTemplateId: newDefault } }),
        });
    };
    return (react_1.default.createElement(components_1.MBView, { paddingBottom: 2 },
        react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 2 },
            react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('workflowTemplate.responseOptions'))),
        loading && react_1.default.createElement(components_1.MBLoadingIndicator, null),
        !loading &&
            (subforms === null || subforms === void 0 ? void 0 : subforms.map(function (form) {
                var _a, _b, _c, _d;
                return (react_1.default.createElement(DefaultSubformSelectionRow, { key: (_a = form.workflowTemplate) === null || _a === void 0 ? void 0 : _a.id, name: (_b = form.workflowTemplate) === null || _b === void 0 ? void 0 : _b.name, isSelected: ((_c = form.workflowTemplate) === null || _c === void 0 ? void 0 : _c.id) === ((_d = component.default) === null || _d === void 0 ? void 0 : _d.workflowTemplateId), onPress: function () { var _a; return onPress((_a = form.workflowTemplate) === null || _a === void 0 ? void 0 : _a.id); } }));
            }))));
};
exports.DefaultSubformSelection = DefaultSubformSelection;
var DefaultSubformSelectionRow = function (_a) {
    var name = _a.name, isSelected = _a.isSelected, onPress = _a.onPress;
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onPress },
        react_1.default.createElement(components_1.MBView, { flex: true, row: true, centerVertically: true, paddingVertical: 0.25, paddingHorizontal: 3 },
            react_1.default.createElement(components_1.MBView, { style: { alignContent: 'flex-start', flexWrap: 'wrap', flex: 4 } },
                react_1.default.createElement(components_1.MBTextBody1, null, name)),
            react_1.default.createElement(components_1.MBView, { style: {
                    alignItems: 'flex-end',
                    flex: 1,
                } },
                react_1.default.createElement(components_1.MBSelectableDot, { selected: isSelected, radio: true })))));
};
