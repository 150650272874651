"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCardSelection = void 0;
// @ts-strict-ignore
var react_1 = require("react");
var _1 = require(".");
var hooks_1 = require("@shared/scenes/cards/hooks");
var useCardSelection = function (cardId) {
    var _a = (0, _1.useCardSelectionActions)(), addCard = _a.addCard, removeCardById = _a.removeCardById, setActive = _a.setActive, setLoadingCard = _a.setLoadingCard, loadingCards = _a.loadingCards;
    var selectionState = (0, _1.useCardSelectionState)();
    var getCard = (0, hooks_1.useGetCardLazyQuery)();
    var _b = (0, react_1.useMemo)(function () {
        return selectionState || { active: false, selectedCards: [] };
    }, [selectionState]), selectionActive = _b.active, selectedCards = _b.selectedCards;
    var isCardSelected = selectedCards.some(function (card) { return card.id === cardId; });
    var fetchCard = (0, react_1.useCallback)(function () { return __awaiter(void 0, void 0, void 0, function () {
        var card, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoadingCard(cardId, true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, getCard(cardId)];
                case 2:
                    card = _a.sent();
                    addCard(card);
                    return [3 /*break*/, 5];
                case 3:
                    err_1 = _a.sent();
                    console.error(err_1);
                    return [3 /*break*/, 5];
                case 4:
                    setLoadingCard(cardId, false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [cardId, getCard, addCard, setLoadingCard]);
    var toggleSelectedCard = (0, react_1.useCallback)(function () { return __awaiter(void 0, void 0, void 0, function () {
        var card, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isCardSelected) return [3 /*break*/, 1];
                    removeCardById(cardId);
                    if (selectedCards.length === 1) {
                        setActive(false);
                    }
                    return [3 /*break*/, 6];
                case 1:
                    setLoadingCard(cardId, true);
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, 5, 6]);
                    return [4 /*yield*/, getCard(cardId)];
                case 3:
                    card = _a.sent();
                    addCard(card);
                    return [3 /*break*/, 6];
                case 4:
                    err_2 = _a.sent();
                    console.error(err_2);
                    return [3 /*break*/, 6];
                case 5:
                    setLoadingCard(cardId, false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); }, [
        isCardSelected,
        selectedCards,
        setActive,
        addCard,
        cardId,
        removeCardById,
        getCard,
        setLoadingCard,
    ]);
    return (0, react_1.useMemo)(function () { return ({
        selectionState: selectionState,
        toggleSelectedCard: toggleSelectedCard,
        isCardSelected: isCardSelected,
        selectionActive: selectionActive,
        setActive: setActive,
        loadingCards: loadingCards,
        setLoadingCard: setLoadingCard,
        addCard: addCard,
        fetchCard: fetchCard,
    }); }, [
        selectionState,
        toggleSelectedCard,
        isCardSelected,
        selectionActive,
        setActive,
        loadingCards,
        setLoadingCard,
        addCard,
        fetchCard,
    ]);
};
exports.useCardSelection = useCardSelection;
