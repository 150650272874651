"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardCreateRoute = void 0;
// @ts-strict-ignore
var components_1 = require("@shared/components");
var scroll_1 = require("@shared/components/blocks/contexts/scroll");
var hooks_1 = require("@shared/scenes/cards/hooks");
var dotsMenu_1 = require("@shared/scenes/workflows/edit/components/dotsMenu");
var constants_1 = require("@shared/styles/constants");
var hooks_2 = require("@shared/util/hooks");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var react_1 = __importStar(require("react"));
var container_1 = require("./container");
var hooks_3 = require("./hooks");
var CardCreateRoute = function (_a) {
    var params = _a.params, location = _a.location;
    var channel = (0, hooks_2.useChannel)(params.channelSlug);
    var cardDefinition = (0, hooks_1.useCardDefinition)(channel === null || channel === void 0 ? void 0 : channel.id, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId, params.cardDefinitionId).cardDefinition;
    var title = (0, hooks_3.useFormTitle)(params.channelSlug, params.templateSlug, cardDefinition);
    var _b = (0, react_1.useState)(), onSubmit = _b[0], setOnSubmit = _b[1];
    // Note(fant): to store a fn in state, we need to wrap it in a fn:
    // https://stackoverflow.com/a/55621325/9117995
    var handleSubmitFunctionChanged = (0, react_1.useCallback)(function (fn) { return setOnSubmit(function () { return fn; }); }, []);
    var submitButtonTitle = (0, hooks_3.useFormSubmitButtonTitle)(params.channelSlug, params.templateSlug, params.cardDefinitionId);
    var goBack = (0, useNavigator_1.useNavigator)().goBack;
    var fieldOverrides = (0, react_1.useMemo)(function () {
        if (!location.query.fields) {
            return undefined;
        }
        try {
            return JSON.parse(location.query.fields);
        }
        catch (error) {
            return undefined;
        }
    }, [location.query.fields]);
    return (react_1.default.createElement(components_1.MBModalOrMobile, { desktopModalWidth: constants_1.Constants.pageModalWidth, desktopModalHeight: 'calc(95vh - 76px)' /* height of bottom button */, title: title, onBack: goBack, paddingOnWeb: "none", desktopModalRightButton: react_1.default.createElement(components_1.MBView, { row: true },
            react_1.default.createElement(dotsMenu_1.MBWorkflowDotsMenu, { channelSlug: params.channelSlug, testID: "workflow-dots-menu-button" }),
            react_1.default.createElement(components_1.MBHeaderButton, { title: submitButtonTitle, onPress: onSubmit, testID: "create-card-submit-button" })), headerOnMobile: react_1.default.createElement(components_1.MBWebNavigationHeader, { title: title, rightButton: react_1.default.createElement(components_1.MBHeaderButton, { title: submitButtonTitle, onPress: onSubmit }), onPressLeft: goBack }) },
        react_1.default.createElement(scroll_1.CardBlockScrollProvider, null,
            react_1.default.createElement(container_1.MBCardCreateForm, { channelSlug: params.channelSlug, templateSlug: params.templateSlug, cardDefinitionId: cardDefinition === null || cardDefinition === void 0 ? void 0 : cardDefinition.id, workflowTemplateId: channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId, fieldOverrides: fieldOverrides, onSubmitFormButtonCallback: handleSubmitFunctionChanged, restoreSavedData: !!(location === null || location === void 0 ? void 0 : location.query.restoreSavedData) }))));
};
exports.CardCreateRoute = CardCreateRoute;
exports.CardCreateRoute.displayName = 'CardCreateRoute';
