"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardActionsFilterByCardSheet = exports.isCardFilter = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var provider_1 = require("@shared/scenes/cards/actions/provider");
var client_1 = require("@apollo/client");
var api_1 = require("@shared/api");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var select_1 = require("@shared/components/blocks/lib/inputCards/sheets/select");
var isCardFilter = function (fieldName) {
    return function (filter) {
        var _a;
        return filter.type === 'card' &&
            !!((_a = filter.mustHaveAny) === null || _a === void 0 ? void 0 : _a.some(function (cardFilterOption) { return cardFilterOption.fieldName === fieldName; }));
    };
};
exports.isCardFilter = isCardFilter;
var MBCardActionsFilterByCardSheet = function (_a) {
    var name = _a.name;
    var channel = (0, react_1.useContext)(channel_1.CardChannelContext).channel;
    var _b = (0, client_1.useQuery)(api_1.Queries.channel.getRelatedChannelAssociations, {
        variables: { id: channel.id },
    }), data = _b.data, loading = _b.loading;
    var fromChannelId = (0, react_1.useMemo)(function () {
        var _a, _b, _c;
        return (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.getChannel) === null || _a === void 0 ? void 0 : _a.relatedChannelAssociations) === null || _b === void 0 ? void 0 : _b.find(function (rel) { return rel.field === name; })) === null || _c === void 0 ? void 0 : _c.relatedChannelId;
    }, [data, name]);
    var _c = (0, react_1.useContext)(provider_1.CardFilterStateContext), appliedFilters = _c.appliedFilters, setAppliedFilters = _c.setAppliedFilters;
    var isCurrentFilter = (0, react_1.useMemo)(function () { return (0, exports.isCardFilter)(name); }, [name]);
    var handleChange = (0, react_1.useCallback)(function (cards) {
        var _a;
        var otherFilters = appliedFilters.filter(function (filter) { return !isCurrentFilter(filter); });
        var changedFilter = {
            type: 'card',
            mustHaveAny: cards.map(function (lc) { return ({ fieldName: name, cardId: lc.cardId }); }),
        };
        if (!!((_a = changedFilter.mustHaveAny) === null || _a === void 0 ? void 0 : _a.length)) {
            var filters = __spreadArray(__spreadArray([], otherFilters, true), [changedFilter], false);
            setAppliedFilters(filters);
        }
        else {
            setAppliedFilters(otherFilters);
        }
    }, [appliedFilters, isCurrentFilter, name, setAppliedFilters]);
    var selectedValues = (0, react_1.useMemo)(function () {
        var _a, _b;
        return (_b = (_a = appliedFilters.find(isCurrentFilter)) === null || _a === void 0 ? void 0 : _a.mustHaveAny.map(function (cardFilter) {
            return ({
                cardId: cardFilter.cardId,
            });
        })) !== null && _b !== void 0 ? _b : [];
    }, [appliedFilters, isCurrentFilter]);
    if (loading) {
        return null;
    }
    return (react_1.default.createElement(select_1.SelectCards, { fromChannelId: fromChannelId, max: undefined, onChange: handleChange, values: selectedValues, quickCreate: false }));
};
exports.MBCardActionsFilterByCardSheet = MBCardActionsFilterByCardSheet;
